import React,{Component} from "react";
import {Card,Row, Col,Space} from "antd";
import dayjs from "dayjs"
import ProTable from '@ant-design/pro-table';
import { CloseOutlined } from '@ant-design/icons';
import "./index.less";


const columns = [
  {
    title: '项目',
    dataIndex: 'app',
  },
  {
    title: '实时总数',
    dataIndex: 'current',
  },
  {
    title: '昨日',
    dataIndex: 'yesterday',
  },
  {
    title: '今日新增',
    dataIndex: 'today',
  },
  {
    title: '增长率',
    dataIndex: 'percent',
  },
];
class SystemInfo extends Component{
  render() {
    return (
      <div className="system-info-page">
        <Space direction="vertical" size={16} style={{width: '100%'}}>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Card title="我的个人信息" extra={<CloseOutlined />} style={{ width: '100%' }}>
                <div className="basic-info">
                  <h1>欢迎使用！</h1>
                  <p>本次访问时间：{dayjs().format('YYYY-MM-DD hh:mm:ss')}</p>
                  <p>本次访问IP：10.0.9.1</p>
                </div>
              </Card>
            </Col>
            <Col className="gutter-row" span={12}>
              <Card title="我的个人信息" extra={<CloseOutlined />} style={{ width: '100%' }}>
                <div className="basic-info">
                  <p>See Me开发团队</p>
                </div>
              </Card>
            </Col>
          </Row>
          {/*<Row gutter={16}>*/}
          {/*  <Col className="gutter-row" span={24}>*/}
          {/*    <Card title="数据展示" style={{ width: '100%' }}>*/}
          {/*      <ProTable*/}
          {/*        columns={columns}*/}
          {/*        request={(params, sorter, filter) => {*/}
          {/*          return Promise.resolve({*/}
          {/*            data: [*/}
          {/*              {*/}
          {/*                id: 1,*/}
          {/*                app: "支付宝授权数",*/}
          {/*                current: "100",*/}
          {/*                yesterday: "5552",*/}
          {/*                today: "1313",*/}
          {/*                percent: "-52.%"*/}
          {/*              }*/}
          {/*            ],*/}
          {/*            success: true,*/}
          {/*          });*/}
          {/*        }}*/}
          {/*        rowKey="id"*/}
          {/*        pagination={{*/}
          {/*          showQuickJumper: true,*/}
          {/*        }}*/}
          {/*        search={false}*/}
          {/*        dateFormatter="string"*/}
          {/*        headerTitle="表格标题"*/}
          {/*        toolBarRender={false}*/}
          {/*      />*/}
          {/*    </Card>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Space>
      </div>
    )
  }

}

export default SystemInfo