import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Space, Form,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {
  ProFormTextArea,
  ProFormText,
  ProFormRadio,
  ProFormDateTimePicker,
  ProFormDependency
} from "@ant-design/pro-form";
import {withRouter} from "react-router-dom";
import UEditor from "../../../components/Editor/Editor";
import {ADD_MESSAGE,GET_MESSAGE_LIST} from "../../../service";
import "./index.less"


class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '标题',
          dataIndex: 'title',
          hideInSearch: true,
        },
        {
          title: '标题',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: "标题关键字"
          }
        },
        {
          title: '消息类型',
          hideInSearch: true,
          dataIndex: 'messageType',
          render: (text, record) => {
            return record.messageType === 1 ? '系统公告': "消息"
          }
        },
        {
          title: '目标对象',
          dataIndex: 'receiveObject',
          hideInSearch: true,
          render: (text, record) => {
            switch (record.receiveObject) {
              case 1:
                return "求职端"
              case 2:
                return "企业端"
              case 3:
                return "求职者及企业"
              default:
                return ""
            }
          }
        },
        {
          title: '发送类型',
          hideInSearch: true,
          dataIndex: 'sendType',
          render: (text, record) => {
            return record.sendType?.code === 1 ? '及时发送': "定时发送"
          }
        },
        {
          width: 200,
          title: '发送时间',
          dataIndex: 'sendTime',
          valueType: 'dateTimeRange',
          render: (text, record) => {
            return record.sendTime
          }
        },
        {
          width: 200,
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true
        },
      ]
    }
  }

  formRef = React.createRef()
  modalFormRef = React.createRef()

  handleEditor = record => {
    if(record.isSend === 1) {
      this.props.history.push("/messageView?id="+ record.id)
    }else {
      this.setState({
        eType: 2,
        eId: record.id,
        modalVisible: true
      })
    }
  }
  handleDelete = record => {
    console.log(record)
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      eId: "",
      modalVisible: true
    }, () => {
      this.modalFormRef.current.setFieldsValue({
        title: "",
        describe: '',
        note: "",
        target: 1,
        sendType: 1,
        sendTime: undefined,
        content: ""
      })
    })
  }

  handleOk = (values) =>{
    React.fetch({
      url: ADD_MESSAGE,
      method: "post",
      data: {
        title: values.title,
        note: values.describe,
        receiveObject: values.target,
        sendType: values.sendType,
        sendTime: values.sendTime,
        content: values.content
      }
    }).then(res => {
      if(res.code === 200) {
        message.success("消息新增成功！");
        this.formRef.current.reload();
        this.setState({
          modalVisible: false
        })
      }
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    const {columns, modalVisible} = this.state
    return (
      <div className="message-list-page">
        <ProTable
          actionRef={this.formRef}
          columns={columns}
          request={async (params = {}) => {
            const res = await React.fetch({
              url: GET_MESSAGE_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                keyword: params.keyword,
                sendTimeEnd: params.sendTime&&params.sendTime[0],
                sendTimeStart: params.sendTime&&params.sendTime[1]
              }
            })
            return {
              data: res.data,
              success: true,
              total: 100
            }
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                新增系统消息
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          width={600}
          title="添加/编辑提示说明"
          footer={null}
          visible={modalVisible}
          onCancel={() => {
            this.setState({
              modalVisible: false
            })
          }}
          onOk={this.handleOk}
        >
          <ProForm
              formRef={this.modalFormRef}
            labelCol={{span: 3}}
            wrapperCol={{span: 21}}
            layout='horizontal'
            submitter={{
              render: (props, dom) => {
                return <div className="form-actions">
                  <Space>
                    <Button type="primary" key="submit" onClick={() => props.form.submit()}>保存</Button>
                    <Button type="default" key="rest" onClick={() => props.form.resetFields()}>重置</Button>
                  </Space>
                </div>
              }
            }}
            onFinish={this.handleOk}
          >
            <ProFormText name="title" label="消息标题" placeholder="请输入标题"/>
            <ProFormTextArea name="describe" label="消息简介" placeholder="请输入简介"/>
            <ProFormRadio.Group
              name="target"
              label="接收对象"
              options={[
                {
                  label: "求职端",
                  value: 1,
                },
                {
                  label: '企业',
                  value: 2,
                },
                {
                  label: '求职端和企业',
                  value: 3,
                },
              ]}
            />
            <ProFormRadio.Group
              name="sendType"
              label="发送时间"
              options={[
                {
                  label: '及时发送',
                  value: 1,
                },
                {
                  label: '定时发送',
                  value: 2,
                },
              ]}
            />
            <ProFormDependency name={['sendType']}>
              {
                ({sendType}) => {
                  if (sendType === 2) {
                    return <ProFormDateTimePicker name="sendTime" label="发送时间"/>
                  }
                  return null
                }
              }
            </ProFormDependency>
            {/*<ProFormTextArea name="content" label="消息内容" placeholder="请输入消息内容"/>*/}
            <Form.Item name="content" label="消息内容">
              <UEditor/>
            </Form.Item>
          </ProForm>
        </Modal>
      </div>
    )
  }
}

export default withRouter(MessageList)