import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {Button, message, Modal,} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, { ProFormText} from "@ant-design/pro-form";
import {GET_CONTACT_LIST,UPDATE_CONTACTOR_INFO} from "../../../../service";

class ContactsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      page: 1,
      eId: "",
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 48,
          hideInSearch: true,
        },
        {
          title: '',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: "企业名或手机号"
          }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          hideInSearch: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contactPhone',
          hideInSearch: true,
        },
        {
          title: '所属公司',
          dataIndex: 'companyName',
          ellipsis: true,
          hideInSearch: true,
        },
        {
          title: '类型',
          dataIndex: 'type',
          initialValue: 0,
          valueType: 'select',
          fieldProps: {
            options: [
              {label: '全部', value: 0},
              {label: '个人雇主', value: 1},
              {label: '企业HR', value: 2}
            ]
          }
        },
        {
          title: '招聘中职位',
          dataIndex: 'handing',
          hideInSearch: true,
        },
        {
          title: '已结算职位',
          dataIndex: 'ending',
          hideInSearch: true,
        },
        {
          title: '会员等级',
          dataIndex: 'level',
          hideInSearch: true,
        },
        {
          title: '注册时间',
          width: 180,
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '账号设置',
          width: 100,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record, _, action) => (
            <Button type="primary" onClick={() => this.handleEditor(record)}>账号编辑</Button>
          ),
        },
      ]
    }
  }

  formRef = React.createRef()
  tableRef = React.createRef()


  handleEditor = record => {
    this.setState({
      eId: record.id,
      modalVisible: true
    }, () => {
      this.formRef.current.setFieldsValue({
        name: record.name,
        mobile: record.contactPhone
      })
    })
  }
  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="pro-table">
        <ProTable
          actionRef={this.tableRef}
          columns={this.state.columns}
          request={async (params, sorter, filter) => {
            const res = await React.fetch({
              url: GET_CONTACT_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                keyword: params.keyword
              }
            })
            return {
              data: res.data,
              success: true
            }
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          dateFormatter="string"
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title="编辑联系人信息"
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout='horizontal'
            onFinish={async (values) => {
              React.fetch({
                url:UPDATE_CONTACTOR_INFO,
                method: "post",
                data: {
                  id: this.state.eId,
                  contactMan: values.name,
                  contactPhone: values.mobile
                }
              }).then(res =>{
                if (res.code === 200) {
                  message.success("账号信息修改成功！");
                  this.formRef.current.resetFields();
                  this.modalHide();
                  this.tableRef.current.reload()
                }
              })
            }}
            submitter={{
              render: (props, doms) => {
                return <div style={{textAlign: "right"}}>
                  {[...doms,]}
                </div>;
              },
            }}
          >
            <ProFormText
              name="name"
              label="联系人姓名"
              placeholder="请输入姓名"/>
            <ProFormText
              name="mobile"
              label="手机号码"
              placeholder="请输入手机号码"/>
          </ProForm>
        </Modal>
      </div>
    )
  }
}

export default withRouter(ContactsTable)