import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Modal,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {GET_EVALUATE_LIST} from "../../../service";
import "./index.less"
import {getQueryVariable} from "../../../utils";

class AuthList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '职位名称',
          dataIndex: 'jobTitle',
          hideInSearch: true,
        },
        {
          title: '评价等级',
          dataIndex: 'experienceState',
          hideInSearch: true,
          render: (text) => {
            return text === 0 ? "体验差" : text === 1 ? "体验一般" : text === 2 ? "体验好" : "体验一般"}
        },
        {
          title: '评价内容',
          dataIndex: 'remark',
          hideInSearch: true,
        },
        {
          title: '候选人',
          dataIndex: 'applicantName',
          hideInSearch: true,
        },
        {
          title: '评价时间',
          width: 200,
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
      ]
    }
  }

  formRef = React.createRef()
  ref = React.createRef()
  title = decodeURIComponent(getQueryVariable(this.props.location.search, "jobName") || "");
  jobId = getQueryVariable(this.props.location.search, "id")

  componentDidMount() {
    console.log(this.props)
  }

  render() {
    return (
      <div className="user-auth-page">
        <ProTable
            headerTitle={this.title + ' 评价列表'}
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_EVALUATE_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                jobId: this.jobId,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          pagination={false}
          search={false}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          params={{
            page: this.state.page,
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
      </div>
    );
  }
}

export default AuthList;