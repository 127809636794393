import React, {Component} from "react";
import {Button, Space, Modal, Image, Descriptions} from 'antd';
import Field from '@ant-design/pro-field';
import ProTable from '@ant-design/pro-table';
import {ICON_HISTORY_LIST, GET_ICON_HISTORY_DETAIL} from "../../../service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./style.less";

const bannerTypeOptions = {'0': '不跳转', '1': '支付宝小程序', '2': '直播', '3': '外部链接'}
const bannerSateOptions = {'0': '不显示', '1': '显示'}

class HomeIconHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      info: {},
      columns: [
        {
          dataIndex: "id",
          title: "ID",
          width: 48,
        },
        {
          title: "图片",
          width: 160,
          dataIndex: "src",
          render: (text, record) => {
            return <Image width={144} src={record.url}/>;
          },
        },
        {
          title: "ICON名称",
          ellipsis: true,
          width: 150,

          dataIndex: "name",
        },
        {
          title: "显示状态",
          width: 80,
          dataIndex: "state",
          render: (text, record) => {
            return bannerSateOptions[record.state];
          },
        },
        {
          title: "跳转类型",
          width: 100,
          dataIndex: "bannerType",
          render: (text, record) => {
            return bannerTypeOptions[record.type];
          },
        },
        {
          title: "跳转地址",
          width: 180,
          ellipsis: true,
          dataIndex: "targetUrl",
        },
        {
          title: "平台",
          width: 100,
          ellipsis: true,
          dataIndex: "platform",
        },
        {
          title: "创建人",
          width: 80,
          dataIndex: "createdBy",
        },
        {
          title: "操作人",
          width: 80,
          dataIndex: "updatedBy",
        },
        {
          title: "创建时间",
          width: 180,
          dataIndex: "createdTime",
        },
        {
          title: "操作时间",
          width: 180,
          dataIndex: "updatedTime",
        },
        {
          title: "备注",
          width: 60,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 200,
          valueType: "option",
          fixed: 'right',
          align: "center",
          render: (text, record) => (
            <Space>
              <Button type="primary" onClick={() => this.handleDetail(record)}>
                查看详情
              </Button>
            </Space>
          ),
        },
      ],
    };
  }

  tableRef = React.createRef()

  componentDidMount() {
  }


  handleDetail = (data) => {
    this.setState({
      info: {}
    })
    React.fetch({
      url: GET_ICON_HISTORY_DETAIL,
      method: "post",
      data: {
        id: data.id
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          modalVisible: true
        }, () => {
          this.setState({
           info:{
             bannerName: res.data.name,
             bannerUrl: res.data.url,
             bannerType: res.data.type.toString(),
             platform: res.data.platform,
             targetUrl: res.data.targetUrl,
             remark: res.data.remark,
             state: res.data.state.toString()
           }
          })
        })
      }
    })
  }


  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }


  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }


  render() {
    const {info} = this.state
    return (
      <div className="banner-list-page">
        <ProTable
          actionRef={this.tableRef}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: ICON_HISTORY_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={false}
          pagination={false}
          params={{
            page: this.state.page
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title="详情"
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <Descriptions column={1}>
            <Descriptions.Item label="ICON名称">
              <Field text={info.bannerName} mode="read"/>
            </Descriptions.Item>
            <Descriptions.Item label="展示平台">
              <Field text={info.platform} mode="read" valueType="radio" valueEnum={{
                ALIPAY_MINI: "ALIPAY_MINI",
                WEB_PC: "WEB_PC",
                WEB_H5: "WEB_H5",
                WECHAT_MINI: 'WECHAT_MINI'
              }}/>
            </Descriptions.Item>
            <Descriptions.Item label="ICON" contentStyle={{width: '200px'}}>
              <Field
                text={info.bannerUrl}
                mode="read"
                valueType="image"
                fieldProps={{
                  width: 200
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="显示状态">
              <Field text={info.state} mode="read" valueType="radio" valueEnum={bannerSateOptions}/>
            </Descriptions.Item>
            <Descriptions.Item label="跳转类型">
              <Field text={info.bannerType} mode="read" valueType="radio" valueEnum={bannerTypeOptions}/>
            </Descriptions.Item>
            {
              info.bannerType !== '0'?<Descriptions.Item label="跳转地址">
                <Field text={info.targetUrl} mode="read"/>
              </Descriptions.Item>:null
            }
            <Descriptions.Item label="备注">
              <Field text={info.remark} mode="read"/>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  token: state.login.token
})

export default connect(mapStateToProps, null)(withRouter(HomeIconHistory))