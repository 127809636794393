import React,{Component} from "react";
import { Editor } from '@tinymce/tinymce-react';
import {connect} from "react-redux";

class UEditor extends Component{
  constructor(props) {
    super(props);
    this.state={};
  }
  editorRef = React.createRef();
  componentDidMount() {
    //console.log(this.props)
  }

  handleEditorChange = (text) => {
    this.props.onChange(text)
  }

  example_image_upload_handler = (blobInfo, success, failure, progress) => {
    let xhr, formData;
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', React.baseUrl + "/web/boss/upload/img");
    xhr.setRequestHeader('x-zp-ats-auth-token', this.props.token);
    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };

    xhr.onload = function() {
      let json;
      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.data.fileUrl != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }

      success(json.data.fileUrl);
    };

    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  }


  render() {
    return <Editor
      apiKey="riun77o9x062ffvbpwp4g2gqf1tzrgszs2k9582gpeokctko"
      onInit={(evt, editor) => this.editorRef.current = editor}
      value={this.props.value}
      init={{
        menubar: false,
        language: 'zh_CN',
        height: 300,
        toolbar:
          'undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
      }}

     onEditorChange={this.handleEditorChange}
    />
  }
}
const mapStateToProps = state => {
  return {
    token: state.login.token
  }
}
export default connect(mapStateToProps, null)(UEditor)