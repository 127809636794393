import React, {Component} from "react";
import {GET_JOB_CATEGORY_INDUSTRY, GET_JOB_CATEGORY_JOB} from "../../../service";
import moment from 'moment';
import {DatePicker, Checkbox, Space} from 'antd';
// 引入 ECharts 主模块
import * as echarts from 'echarts/lib/echarts'
// 引入饼状图
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import "./index.less";
import mapBack from "../../../assets/image/map_back.png";

const CheckboxGroup = Checkbox.Group;

class PublishJobCategory extends Component {
  echartContainer = null;
  echartContainer2 = null;
  industryId = "";

  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      date: [],
      jobType: [],
      echartOptions: {
        title: {
          text: '发布岗位行业前10名分布图',
          left: 'center'
        },
        tooltip: {
          show: false
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      echartDeepOptions: {
        title: {
          text: '“1级行业名称”前10名职位图',
          left: 'center'
        },
        tooltip: {
          show: false
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      showDeepData: false
    };
  }

  componentDidMount = async () => {
    await this.getData();
  }
  getData = async () => {
    return await React.fetch({
      url: GET_JOB_CATEGORY_INDUSTRY,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    }).then(res => {
      if (res) {
        let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
        echartOptions.series[0].data = res.data.map(item => ({
          name: item.categoryName || '',
          value: item.jobCount,
          id: item.jobCategory
        }));
        this.setState({
          echartOptions: echartOptions
        }, () => {
          if (this.echartContainer2) {
            this.echartContainer2.dispose()
          }
          let myChart = echarts.init(document.getElementById('echart-container'));
          myChart.setOption(this.state.echartOptions);
          this.echartContainer = myChart;

          this.echartContainer.on('click', async (params) => {
            let echartOptions = JSON.parse(JSON.stringify(this.state.echartDeepOptions));
            echartOptions.title.text = params.data.name + "前10名职位图";
            this.setState({
              showDeepData: true,
              echartDeepOptions: echartOptions
            });
            this.echartContainer.dispose();
            this.industryId = params.data.id;
            await this.getDeepData(params.data.id)

          });
        })
      }
    });
  }

  getDeepData = async (id) => {
    return await React.fetch({
      url: GET_JOB_CATEGORY_JOB,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType,
        jobCategoryIndustry: id
      },
    }).then(res => {
      if (res) {
        let echartOptions = JSON.parse(JSON.stringify(this.state.echartDeepOptions));
        echartOptions.series[0].data = res.data.map(item => ({
          name: item.categoryName || '',
          value: item.jobCount,
        }));
        this.setState({
          echartDeepOptions: echartOptions
        }, () => {
          if (this.echartContainer) {
            this.echartContainer.dispose()
          }
          let myChart = echarts.init(document.getElementById('echart-container'));
          myChart.setOption(this.state.echartDeepOptions);
          this.echartContainer = myChart;
        })
      }
    });
  }

  setDate = async (val) => {
    this.setState({
      date: val,
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      if(this.state.showDeepData){
        this.getDeepData(this.industryId);
      }else{
        this.getData();
      }
    })
  }

  render() {
    const {jobType, showDeepData} = this.state;
    return (
      <div className="publish-job-category">
        <div className="search">
          <Space>
            <DatePicker.RangePicker inputReadOnly={true}
                                    allowClear
                                    onChange={(val) => this.setDate(val)}
                                    value={this.state.date}/>

            <CheckboxGroup options={[
              {label: "全职", value: 1},
              // {label: "线上兼职", value: 2},
              // {label: "线下兼职", value: 3}
            ]} value={jobType} onChange={(val) => {
              this.setState({
                jobType: val
              }, () => {
                if(this.state.showDeepData){
                  this.getDeepData(this.industryId);
                }else{
                  this.getData();
                }
              })
            }}/>
          </Space>
        </div>
        {
          showDeepData ? <div onClick={() => {
            this.setState({
              showDeepData: false
            }, () => this.getData())
          }} className="map-back">
            <div className="back">
              <img src={mapBack} alt="返回上级"/>
              <span>返回上级</span>
            </div>
          </div> : null
        }
        <div id="echart-container"/>
      </div>
    );
  }
}

export default PublishJobCategory;
