import React,{Component} from "react";
import {Tabs} from "antd";
import OnlineJobTable from "./components/OnlineJobTable";
import OfflineJobTable from "./components/OfflineJobTable";
import UncheckJobTable from "./components/UncheckJobTable";
import CheckedTable from "./components/CheckedTable";
import "./index.less"
const { TabPane } = Tabs;

class JobManage extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
    }
  }

  handleTabChange = activeKeys => {
    this.setState({
      activeKey: activeKeys
    })
  }

  render() {
    const {activeKey} = this.state
    return (
      <div className="job-manage-page">
        <div className="card-container">
          <Tabs type="card" onChange={this.handleTabChange}>
            <TabPane tab="上线职位" key="1">
              {activeKey === '1' && <OnlineJobTable />}
            </TabPane>
            <TabPane tab="下线职位" key="2">
              {activeKey === '2' && <OfflineJobTable />}
            </TabPane>
            <TabPane tab="待审核职位" key="3">
              {activeKey === '3' && <UncheckJobTable />}
            </TabPane>
            <TabPane tab="已审核职位" key="4">
              {activeKey === '4' && <CheckedTable />}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }

}

export default JobManage