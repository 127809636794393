import React,{Component} from "react";
import CompanyPlatformTable from "./components/CompanyPlatformTable";
import "./index.less";
class TagStore extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
    }
  }
  render() {
    return (
      <div className="tag-store-page">
        <div className="card-container">
          <CompanyPlatformTable />
        </div>
      </div>
    )
  }
}

export default TagStore