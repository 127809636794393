import './index.less'
import React, {useState, useRef} from "react";
import ProTable from '@ant-design/pro-table';
import {GET_COLLECTJOBVERIFY_LIST, AGREE_COLLECTJOB} from "../../../service";
import {Button, Form, Input, Select, Checkbox, Radio, message} from 'antd';

const {Option} = Select;


function CollectJobVerifyList(props) {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true
    },
    {
      title: '职位名称',
      dataIndex: 'title',
      ellipsis: true
    },
    {
      title: '招聘类型',
      ellipsis: true,
      render: (_, record) => {
        return record.recruitingType.desc;
      }
    },
    {
      title: '企业名称',
      ellipsis: true,
      render: (_, record) => {
        return record.recruitingType.code === 0 ? record.companyName : record.recCompanyName;
      }
    },
    {
      title: '学历要求',
      ellipsis: true,
      render: (_, record) => {
        return record.requireEducation ? record.requireEducation.desc : '';
      }
    },
    {
      title: '薪资',
      ellipsis: true,
      render: (_, record) => {
        return record.salary + '元/' + record.salaryUnit.desc;
      }
    },
    {
      title: '职位类型',
      ellipsis: true,
      render: (_, record) => {
        return record.categoryName
      }
    },
    {
      title: '职位类别',
      ellipsis: true,
      render: (_, record) => {
        return record.jobType.desc
      }
    },
    {
      title: '编辑时间',
      dataIndex: 'updatedTime',
      ellipsis: true,
    },
    {
      title: '提交审核人',
      dataIndex: 'createdBy',
      ellipsis: true
    },
    {
      title: '操作',
      ellipsis: true,
      width: 190,
      render: (text, record) => (
        <div className="operate" onClick={e => e.stopPropagation()}>
          <Button onClick={() => handleEditor(record)}>编辑</Button>
          <Button type="primary" onClick={() => passCollectJob(record)}>通过</Button>
        </div>
      ),
    },


  ]
  const columnsDone = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true
    },
    {
      title: '职位名称',
      dataIndex: 'title',
      ellipsis: true
    },
    {
      title: '企业名称',
      ellipsis: true,
      render: (_, record) => {
        return record.recruitingType.code === 0 ? record.companyName : record.recCompanyName;
      }
    },

    {
      title: '职位类型',
      ellipsis: true,
      render: (_, record) => {
        return record.categoryName
      }
    },
    {
      title: '职位类别',
      ellipsis: true,
      render: (_, record) => {
        return record.jobType.desc
      }
    },
    {
      title: '审核状态',
      ellipsis: true,
      render: (_, record) => {
        return record.auditState ? record.auditState.desc : '';
      }
    },
    {
      title: '操作人',
      dataIndex: 'updatedBy',
      ellipsis: true
    },

    {
      title: '审核时间',
      dataIndex: 'updatedTime',
      ellipsis: true,
    },

  ]
  const [lookUpdate, setLookUpdate] = useState('0');
  const [auditState, setAuditState] = useState('0');
  const [title, setTitle] = useState('');
  const [requireEducation, setRequireEducation] = useState('');
  const [jobType, setJobType] = useState('');
  const [page, setPage] = useState(1)

  const handleEditor = (record) => {
    props.history.push("/collectJobEdite?&id=" + record.id)
  }
  const passCollectJob = (record) => {
    React.fetch({
      url: AGREE_COLLECTJOB,
      method: "post",
      data: {
        id: record.id
      },
    }).then(res => {
      tableRef.current.reload();
      message.success('岗位已通过')
    })
  }


  function handlePrev() {
    let current = page
    setPage(current < 0 ? 0 : --current)
  }

  function handleNext() {
    let current = page
    setPage(++current)
  }

  const tableRef = useRef()
  return (<div className="collect-job-verify-list">
    <div className="verift-state">
      <Radio.Group onChange={e => {
        setAuditState(e.target.value);
        setPage(1);
        tableRef.current.reload()
      }} defaultValue="0">
        <Radio.Button value="0">待审核职位</Radio.Button>
        <Radio.Button value="1">已审核职位</Radio.Button>
      </Radio.Group>
    </div>
    <div className="filter-box">
      <Form layout='inline'>
        <Form.Item label="查询：">
          <Input placeholder="职位查询" onChange={(e) => {
            setTitle(e.target.value)
          }}/>
        </Form.Item>
        <Form.Item label="学历要求：">
          <Select defaultActiveFirstOption defaultValue={'0'} style={{width: 120}}
                  onChange={val => setRequireEducation(val)}>
            <Option value="">全部</Option>
            <Option value="0">其他</Option>
            <Option value="1">专科</Option>
            <Option value="2">本科</Option>
            <Option value="3">硕士</Option>
            <Option value="4">博士</Option>
            <Option value="5">高中/职高/技校</Option>
          </Select>
        </Form.Item>
        <Form.Item label="职位类别：">
          <Select defaultActiveFirstOption defaultValue={''} style={{width: 120}} onChange={val => setJobType(val)}>
            <Option value="">全部</Option>
            <Option value="1">全职</Option>
            <Option value="2">线上兼职</Option>
            <Option value="3">线下兼职</Option>
            <Option value="4">任务</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={() => tableRef.current.reload()}>搜索</Button>
        </Form.Item>
      </Form>
      <div><Checkbox onChange={(e) => {
        e.target.checked ? setLookUpdate(1) : setLookUpdate(0)
      }}>只看自己编辑过的职位</Checkbox></div>
    </div>
    <ProTable
      actionRef={tableRef}
      columns={auditState === '0' ? columns : columnsDone}
      request={async (params = {}, sort, filter) => {
        const res = await React.fetch({
          url: GET_COLLECTJOBVERIFY_LIST,
          method: "post",
          data: {
            page: params.page,
            size: 20,
            lookUpdate: lookUpdate,
            title: title,
            auditState: auditState,
            requireEducation: requireEducation,
            jobType: jobType
          }
        })
        return {
          data: res.data,
          success: true,
        }
      }}
      rowKey="id"
      search={false}
      pagination={false}
      params={{
        page: page
      }}
    />
    <div className="page">
      <Button type="link" className="prev" disabled={page === 1} onClick={handlePrev}>上一页</Button>
      <Button type="link" className="next" onClick={handleNext}>下一页</Button>
    </div>
  </div>)
}

export default CollectJobVerifyList;