import React, {Component} from "react";
import {Tabs} from 'antd';
import {withRouter} from "react-router-dom";

class BICompanyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {

  }
  handleTabChange=(activeKeys)=>{
    this.props.history.push(activeKeys+'')
  }

  render() {
    return (<div className="BI-company-container">
        <Tabs type="card" activeKey={this.props.location.pathname} onChange={this.handleTabChange}>
              <Tabs.TabPane tab="发布岗位数" key="/BI-company/publishJobMount" />
              <Tabs.TabPane tab="岗位分布区域" key="/BI-company/jobCityInfo" />
              <Tabs.TabPane tab="发布岗位分类" key="/BI-company/publishJobCategory" />
            </Tabs>
        {this.props.children}
    </div>);
  }
}


export default withRouter(BICompanyContainer)