import React, {Component} from "react";
import {GET_RESUME_DELIVERY_MOUNT} from "../../../service";
import moment from 'moment';
import {DatePicker, Card, Table, Space} from 'antd';
import "./index.less";

class ResumeDeliveryMount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      data: [],
      echartOptions: {},
      echartContainer: '',
      resumeNum: '',
      userNum: '',
      summaryColumn: [
        {
          title: '投递职位类型',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: '投递简历次数',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: '投递简历次数占比',
          dataIndex: 'resumePercent',
          key: 'resumePercent',
        },
        {
          title: '投递用户数',
          dataIndex: 'user',
          key: 'user',
        },
        {
          title: '投递用户数占比',
          dataIndex: 'userPercent',
          key: 'userPercent',
        }
      ],
      summaryTableData: [],
      channelColumn: [
        {
          title: '渠道',
          dataIndex: 'channel',
          key: 'channel',
        },
        {
          title: '投递简历次数',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: '投递简历次数占比',
          dataIndex: 'resumePercent',
          key: 'resumePercent',
        },
        {
          title: '投递用户数',
          dataIndex: 'user',
          key: 'user',
        },
        {
          title: '投递用户数占比',
          dataIndex: 'userPercent',
          key: 'userPercent',
        }
      ],
      channelTableData: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let res = await React.fetch({
      url: GET_RESUME_DELIVERY_MOUNT,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
      },
    });
    if (res.code === 200) {
      const {data} = res;
      this.setState({
        resumeNum: data.applyCount,
        userNum: data.applyUserCount,
        summaryTableData: [
          {
            id: 1,
            type: "全职",
            count: data.fullJobResumeCount || 0,
            resumePercent: (data.fullJobResumePercent * 100).toFixed(0) + '%',
            user: data.fullJobUserCount || 0,
            userPercent: (data.fullJobUserPercent * 100).toFixed(0) + '%',
          },
          // {
          //   id: 2,
          //   type: "线上兼职",
          //   count: data.onlineJobResumeCount || 0,
          //   resumePercent: (data.onlineJobResumePercent* 100).toFixed(0)  + '%',
          //   user: data.onlineJobUserCount || 0,
          //   userPercent: (data.onlineJobUserPercent * 100).toFixed(0) + '%',
          // },
          // {
          //   id: 3,
          //   type: "线下兼职",
          //   count: data.offlineJobResumeCount || 0,
          //   resumePercent: (data.offlineJobResumePercent * 100).toFixed(0) + '%',
          //   user: data.offlineJobUserCount || 0,
          //   userPercent: (data.offlineJobUserPercent * 100).toFixed(0) + '%',
          // }
        ],
        channelTableData: [
          // {
          //   id: 1,
          //   channel: "支付宝",
          //   count: data.alipayResumeCount || 0,
          //   resumePercent: (data.alipayResumePercent * 100 ).toFixed(0)+ '%',
          //   user: data.alipayUserCount || 0,
          //   userPercent: (data.alipayUserPercent * 100).toFixed(0) + '%',
          // },
          {
            id: 2,
            channel: "微信",
            count: data.weixinResumeCount || 0,
            resumePercent: (data.weixinResumePercent * 100).toFixed(0) + '%',
            user: data.weixinUserCount || 0,
            userPercent: (data.weixinUserPercent * 100).toFixed(0) + '%',
          }
        ],
      });
    }

    return res;
  }

  setDate = async (val) => {
    this.setState({
      date: val,
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      this.getData()
    })
  }

  render() {
    const {date, summaryColumn, summaryTableData, channelColumn, channelTableData} = this.state;
    return (
      <div className="resume-delivery-mount">
        <DatePicker.RangePicker inputReadOnly={true} allowClear onChange={val => this.setDate(val)} value={date}/>
        <div className="content">
          <div className="content">投递简历次数总数：{this.state.resumeNum}</div>
          <div className="content">投递用户总数：{this.state.userNum}</div>

          <div className="table">
            <Space direction="vertical">
              <Card>
                <Table columns={summaryColumn} rowKey="id" dataSource={summaryTableData} pagination={false} bordered/>
              </Card>
              <Card>
                <Table columns={channelColumn} rowKey="id" dataSource={channelTableData} pagination={false} bordered/>
              </Card>
            </Space>
          </div>
        </div>

      </div>
    );
  }
}

export default ResumeDeliveryMount;
