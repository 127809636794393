export const menuList = [
  {
    id: 1,
    name: "用户列表"
  },
  {
    id: 2,
    name: '招聘列表'
  }
];
export const breadcrumb = {
  "/systemInfo": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "系统信息",path: ""},
  ],
  "/userAuth": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "用户权限",path: ""},
  ],
  "/tagsStore": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "标签库",path: ""},
  ],
  "/logs": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "操作日志",path: ""},
  ],
  "/companyManage":[
    {id: 1,name: "企业端管理",path: ""},
    {id: 2,name: "企业管理",path: ""},
  ],
  "/infoSetting":[
    {id: 1,name: "企业端管理",path: ""},
    {id: 2,name: "企业管理",path: "/companyManage"},
    {id: 3,name: "信息修改",path: ""},
  ],
  "/jobManage":[
    {id: 1,name: "企业端管理",path: ""},
    {id: 2,name: "职位管理",path: ""},
  ],
  "/jobSetting": [
    {id: 1,name: "企业端管理",path: ""},
    {id: 2,name: "职位管理",path: "/jobManage"},
    {id: 3,name: "职位编辑",path: ""},
  ],
  "/employeeManage":[
    {id: 1,name: "求职端管理",path: ""},
    {id: 2,name: "求职者管理",path: ""},
  ],
  "/resumeManage":[
    {id: 1,name: "求职端管理",path: ""},
    {id: 2,name: "简历管理",path: ""},
  ],
  "/resumeDetail":[
    {id: 1,name: "求职端管理",path: ""},
    {id: 2,name: "简历管理",path: "/resumeManage"},
    {id: 3,name: "简历详情",path: ""},
  ],
  "/sendRecord": [
    {id: 1,name: "求职端管理",path: ""},
    {id: 2,name: "投递记录",path: ""},
  ],
  "/orderDetails": [
    {id: 1,name: "订单管理",path: ""},
    {id: 2,name: "红包明细",path: ""},
  ],
  "/messageList": [
    {id: 1,name: "消息管理",path: ""},
    {id: 2,name: "系统消息列表",path: ""},
  ],
  "/messageView": [
    {id: 1,name: "消息管理",path: ""},
    {id: 2,name: "系统消息列表",path: "/messageList"},
    {id: 3,name: "消息预览",path: ""},
  ],
  "/adPositionList": [
    {id: 1,name: "广告管理",path: ""},
    {id: 2,name: "广告位置列表",path: ""},
  ],
  "/bannerManage": [
    {id: 1,name: "广告管理",path: ""},
    {id: 2,name: "轮播列表",path: ""},
  ],
  "/collectJobList": [
    {id: 1,name: "职位采集管理",path: ""},
    {id: 2,name: "采集职位列表",path: ""},
  ],
  "/placeholder":[
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "搜索文字配置",path: ""},
  ],  
  "/BI-job/authorizationAccount": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "注册用户数",path: ""},
  ],
  "/BI-job/resumeComplete": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "简历完成数",path: ""},
  ],
  "/BI-job/resumeDeliveryMount": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "简历投递数量",path: ""},
  ],

  "/BI-job/deliveryCity": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "投递城市",path: ""},
  ],
  "/BI-job/deliveryJobCategory": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "投递职位分类",path: ""},
  ],
  "/BI-job/rankingJob": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "求职端",path: ""},
    {id: 2,name: "职位投递数排序",path: ""},
  ],

  "/BI-company/publishJobMount": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "企业端",path: ""},
    {id: 2,name: "发布岗位数",path: ""},
  ],
  "/BI-company/jobCityInfo": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "企业端",path: ""},
    {id: 2,name: "岗位分布区域",path: ""},
  ],
  "/BI-company/publishJobCategory": [
    {id: 1,name: "BI统计",path: ""},
    {id: 3,name: "企业端",path: ""},
    {id: 2,name: "发布岗位分类",path: ""},
  ],

  "/attachmentManage": [
    {id: 1,name: "求职者管理",path: ""},
    {id: 2,name: "附件简历",path: ""},
  ],
  "/cityService": [
    {id: 1,name: "第三方职位管理",path: ""},
    {id: 2,name: "职位列表",path: ""},
  ],
  "/sensitiveWords": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "敏感词管理",path: ""},
  ],
  "/authList": [
    {id: 1,name: "系统管理",path: ""},
    {id: 2,name: "权限管理",path: ""},
  ],
  "/operationLogs": [
    {id: 1,name: "企业端管理",path: ""},
    {id: 2,name: "操作记录",path: ""},
  ],
  "/intentionDetail": [
    {id: 1,name: "求职者管理",path: ""},
    {id: 2,name: "求职意向详情",path: ""},
  ],
  "/intentionList": [
    {id: 1,name: "求职意向",path: ""},
    {id: 2,name: "求职意向列表",path: ""},
  ],
  "/homeImageConfig": [
    {id: 1,name: "广告管理",path: ""},
    {id: 2,name: "首页图片位配置",path: ""},
  ],
  "/homeIconConfig": [
    {id: 1,name: "广告管理",path: ""},
    {id: 2,name: "首页ICON配置",path: ""},
  ],
  "/homeIconHistory": [
    {id: 1,name: "广告管理",path: ""},
    {id: 2,name: "首页ICON配置历史记录",path: ""},
  ]
}
export const particlesConfig = {
  "particles": {
    "number": {
      "value": 100,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffeba7"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.5,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 5,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#ffeba7",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 6,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "repulse"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true,
  "config_demo": {
    "hide_card": false,
    "background_color": "#b61924",
    "background_image": "",
    "background_position": "50% 50%",
    "background_repeat": "no-repeat",
    "background_size": "cover"
  }
}
