import React, {Component} from "react";
import {CloudDownloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import ProTable from '@ant-design/pro-table';

class OutputTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
          fieldProps: {
            placeholder: "请输入姓名"
          }
        },
        {
          title: '操作类型',
          hideInSearch: true,
          dataIndex: 'action',
        },
        {
          title: '获得红包',
          hideInSearch: true,
          dataIndex: 'money',
        },
        {
          title: '行为场景',
          dataIndex: 'scene',
          hideInSearch: true,
        },
        {
          title: '记录时间',
          hideInSearch: true,
          dataIndex: 'record',
        },
        {
          title: 'IP端口',
          dataIndex: 'port',
          hideInSearch: true,
        },
        {
          title: '',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          fieldProps: {
            placeholder: ["开始时间","结束时间"]
          }
        },
      ]
    }
  }
  componentDidMount() {
  }
  handleExport = () => {

  }

  render() {
    return (
      <div className="user-auth-page">
        <ProTable
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            return {
              data: [
                {id: 1, account: "hello",name: "小张", mobile: "17621478502"}
              ],
              success: true,
              total: 100
            }
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={{
            pageSize: 20,
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<CloudDownloadOutlined/>} type="primary" onClick={this.handleExport}>
                导出记录
              </Button>
            ]
          }}
        />
      </div>
    )
  }
}

export default OutputTable