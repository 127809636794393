import React, {Component} from "react";
import "./index.less"
import ProTable from "@ant-design/pro-table";
import {
  JOB_ONLINE_LIST,
  GET_PUSH_JOB_LIST,
  PUSH_JOB,
  PUSH_JOB_STATE,
  DELETE_UNABLE_JOB
} from "../../../service";
import {Button, message, Modal, Space, Table, Checkbox, Popconfirm, Tag} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class CityService extends Component {
  applicantAccountId = "";
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isModalVisible: false,
      selectedRowKeys: [],
      selectedTableRowKeys: [],
      columns: [
        {
          fixed: "left",
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '职位名称',
          dataIndex: 'jobTitle',
          ellipsis: true,
          hideInSearch: true,
          width: 200,
        },
        {
          title: '招聘方式',
          dataIndex: 'recruitingType',
          hideInSearch: true,
          render: ((text, record) => record?.recruitingType?.desc),
          width: 80,
        },
        {
          title: '推送渠道',
          dataIndex: 'pushDest',
          valueType: "select",
          fieldProps: {
            placeholder: "推送渠道",
            options: [{value: "", label: "全部"}, {value: 1, label: "城服"}]
          },
          width: 120,
          render: ((text, record) => record?.pushDest),
        },
        {
          title: '企业名称',
          dataIndex: 'companyName',
          hideInSearch: true,
          ellipsis: true,
          width: 200,
        },
        {
          title: '职位类型',
          dataIndex: 'jobType',
          hideInSearch: true,
          width: 120,
          render: ((text, record) => record?.jobType?.desc),
        },
        {
          title: '当前状态',
          dataIndex: 'pushState',
          valueType: "select",
          fieldProps: {
            placeholder: "推送状态",
            options: [{value: 0, label: "推送中"}, {value: 1, label: "取消推送"}, {value: 2, label: "推送异常"}]
          },
          width: 120,
          render: ((text, record) => <Tag
            color={record?.pushState?.code === 0 ? "success" : "error"}>{record?.pushState?.desc}</Tag>),
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          hideInSearch: true,
          width: 200
        },
        {
          title: '推送状态',
          dataIndex: 'pushResult',
          hideInSearch: true,
          width: 120,
        },
        {
          title: 'outId',
          dataIndex: 'outId',
          ellipsis: true,
          hideInSearch: true,
          width: 200
        },
        {
          title: '操作',
          width: 200,
          fixed: "right",
          valueType: 'option',
          align: 'center',
          render: (text, record) => {
            return record.jobState.code === 0 ? <div>
              <p>职位已下架</p>
              <Popconfirm
                title="请确认是否移除该岗位"
                onConfirm={() => this.handleRemove(record)}
                okText="确认"
                cancelText="取消"
              >
                <Button type="primary" danger>移除</Button>
              </Popconfirm>

            </div> : record?.pushState?.code === 0 ?
              <Popconfirm
                title="请确认是否取消推送该岗位"
                onConfirm={() => this.handleChangeState(record)}
                okText="确认"
                cancelText="取消"
              >
                <Button type="primary" danger>取消推送</Button>
              </Popconfirm>
              : (record?.pushState?.code === 1 || record?.pushState?.code === 2)? <Popconfirm
                title="请确认是否重新推送该岗位"
                onConfirm={() => this.handleChangeState(record)}
                okText="确认"
                cancelText="取消"
              >
                <Button type="primary">重新推送</Button>
              </Popconfirm> : "-"
          },
        },
        {
          title: '',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: "请输入职位名或企业名",
          },
        },
      ],
      modalColumn: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 58,
          hideInSearch: true
        },
        {
          title: '职位名称',
          ellipsis: true,
          width: 200,
          dataIndex: 'title',
        },
        {
          title: '企业名称',
          width: 150,
          ellipsis: true,
          dataIndex: 'companyName',
        },
        {
          title: '学历要求',
          width: 80,
          ellipsis: true,
          hideInSearch: true,
          dataIndex: 'requireEducation',
          valueType: 'select',
          render: (text, record) => {
            return record.requireEducation?.desc
          },
          fieldProps: {
            placeholder: "选择学历",
            options: this.props.educationOptions
          }
        },
        {
          title: '是否认证',
          width: 100,
          hideInTable: true,
          dataIndex: 'isCompanyCert',
          valueType: 'select',
          render: (text, record) => {
            return record.isCompanyCert?.desc
          },
          fieldProps: {
            placeholder: "是否认证",
            options: [
              {label: "未认证", value: 0},
              {label: "已认证", value: 1},
            ]
          }
        },
        {
          title: '薪资',
          width: 150,
          ellipsis: true,
          dataIndex: 'salary',
          hideInSearch: true,
          render: (text, record) => {
            let salary = 0;
            if(record.salaryMin === record.salaryMax) {
              salary = record.salaryMin
            }else if(!record.salaryMax){
              salary = record.salaryMin
            }else{
              salary = record.salaryMin + '-' + record.salaryMax
            }
            return salary + "/" + record.salaryUnit?.desc
          }
        },
        {
          title: '职位类型',
          width: 80,
          dataIndex: 'jobType',
          valueType: 'select',
          fieldProps: {
            placeholder: "岗位类型",
            options: this.props.jobTypeOptions
          },
          render: (text, record) => {
            return record.jobType?.desc
          },
        },
        {
          title: '发布时间',
          width: 180,
          hideInSearch: true,
          dataIndex: 'publishTime',
        },
        {
          title: '刷新时间',
          width: 180,
          hideInSearch: true,
          dataIndex: 'refreshTime',
        },
      ],
      pushChannel: [],
      batchPushDisabled: false,
      batchCancelDisabled: false,
      cancelRowLength: 0,
      pushRowLength: 0
    }
  }

  handleChangeState = data => {
    React.fetch({
      url: PUSH_JOB_STATE,
      method: "post",
      data: {
        idList: [data.id],
        pushState: data.pushState?.code === 0 ? 1 : 0
      }
    }).then(res => {
      if (res.code === 200) {
        message.success(data.pushState?.code === 0 ? '您已取消推送刚岗位' : '您已重新推送改岗位')
        this.ref.current.reload();
      }
    })
  }

  handleBatchChangeState = status => {
    React.fetch({
      url: PUSH_JOB_STATE,
      method: "post",
      data: {
        idList: this.state.selectedTableRowKeys,
        pushState: status === "push" ? 0 : 1
      }
    }).then(res => {
      if (res.code === 200) {
        message.success(status === "push" ? '您已重新推送所选岗位' : '您已取消推送所选岗位')
        this.ref.current.reload();
        this.ref.current.clearSelected();
      }
    })
  }

  handleRemove = record => {
    React.fetch({
      url: DELETE_UNABLE_JOB,
      method: "post",
      data: {
        id: record.id
      }
    }).then(res => {
      if (res.code === 200) {
        message.success("已移除该岗位");
        this.ref.current.reload();
      }
    })
  }

  showAllJobList = () => {
    this.modalVisible(true);
  }
  handleOk = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.error("请选择推送岗位！");
      return false
    }
    if (this.state.pushChannel.length === 0) {
      message.error("请选择推送渠道！");
      return false
    }
    React.fetch({
      url: PUSH_JOB,
      method: "post",
      data: {
        pushDestList: this.state.pushChannel,
        jobIdList: this.state.selectedRowKeys
      }
    }).then(res => {
      if (res.code === 200) {
        message.success("岗位推送添加成功");
        this.setState({selectedRowKeys: [], pushChannel: []});
        this.ref.current.reload();
      }
      this.modalVisible(false);
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({selectedRowKeys});
  }
  onTableSelectChange = (selectedTableRowKeys, selectedRows) => {
    if (selectedRows.length > 0) {
      const rowsStateArr = selectedRows.map(item => item.pushState.code);
      const cancelRowLength = rowsStateArr.filter(item => item === 1).length;
      const pushRowLength = rowsStateArr.filter(item => item === 0).length;
      if (cancelRowLength > 0 && pushRowLength > 0) {
        Modal.warning({
          title: '请注意！',
          content: '当前选中多种不同状态的推荐职位，无法进行批量操作。请选中状态相同的职位进行批量操作。',
        });
        this.setState({
          batchPushDisabled: true,
          batchCancelDisabled: true,
          cancelRowLength,
          pushRowLength
        })
      } else {
        this.setState({
          batchPushDisabled: false,
          batchCancelDisabled: false,
          cancelRowLength,
          pushRowLength
        })
      }
    }

    this.setState({selectedTableRowKeys});
  }
  onCheckboxChange = (checked) => {
    this.setState({
      pushChannel: checked
    })
  }

  modalVisible = status => {
    this.setState({
      isModalVisible: status
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    const {batchPushDisabled, batchCancelDisabled, cancelRowLength, pushRowLength} = this.state
    return (
      <div className="city-service-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          rowSelection={{
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
            selectedRowKeys: this.state.selectedTableRowKeys,
            onChange: this.onTableSelectChange
          }}
          tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}) => (
            <div style={{"display": 'flex', "justify-content": "space-between", "align-items": "center"}}>
              <span style={{"marginRight": "20px"}}>已选 <i
                style={{"color": "#0170fe", "fontStyle": "normal"}}>{selectedRowKeys.length}</i> 项</span>
              <Space>
                {
                  cancelRowLength > 0 ? <Popconfirm
                    title={() => <p>{`您已选中${selectedRowKeys.length}个岗位`}，<br/>请确认是否重新推送这些岗位</p>}
                    onConfirm={() => this.handleBatchChangeState("push")}
                    okText="确认"
                    cancelText="取消"
                    disabled={batchPushDisabled}
                  >
                    <Button type="primary" disabled={batchPushDisabled}>批量重新推送</Button>
                  </Popconfirm> : null
                }
                {
                  pushRowLength > 0? <Popconfirm
                    title={() => <p>{`您已选中${selectedRowKeys.length}个岗位`}，<br/>请确认是否取消推送这些岗位</p>}
                    onConfirm={() => this.handleBatchChangeState("cancel")}
                    okText="确认"
                    cancelText="取消"
                    disabled={batchCancelDisabled}
                  >
                    <Button type="primary" danger disabled={batchCancelDisabled}>批量取消推送</Button>
                  </Popconfirm> : null
                }
              </Space>
            </div>
          )}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_PUSH_JOB_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                keyword: params.keyword,
                pushDest: params.pushDest,
                pushState: params.pushState
              }
            })

            return Promise.resolve({
              data: res.data,
              success: true,
            })
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={(action) => {
            return <Button type="primary" onClick={() => {
              this.showAllJobList()
            }}>添加推送岗位</Button>
          }}
        />

        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        {
          this.state.isModalVisible &&
          <Modal width={1200} title="推送岗位" visible={this.state.isModalVisible} onOk={this.handleOk}
                 onCancel={() => this.modalVisible(false)}>
            <ProTable
              columns={this.state.modalColumn}
              rowSelection={{
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                selectedRowKeys: this.state.selectedRowKeys,
                onChange: this.onSelectChange
              }}
              tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}) => (
                <div>
                  <span style={{"marginRight": "20px"}}>已选 <i
                    style={{"color": "#0170fe", "fontStyle": "normal"}}>{selectedRowKeys.length}</i> 项</span>
                  <span>请选择推送渠道： <Checkbox.Group options={[{label: "城服", value: 1}, {label: "芝麻工作证", value: 0}]}
                                                 onChange={this.onCheckboxChange}/></span>
                </div>
              )}
              search={{
                labelWidth: "auto"
              }}
              onSubmit={() => {
                this.setState({
                  page: 1
                })
              }}
              pagination={{
                pageSize: 10
              }}
              rowKey="id"
              toolBarRender={false}
              request={async (params) => {
                const res = await React.fetch({
                  url: JOB_ONLINE_LIST,
                  method: "post",
                  data: {
                    page: params.current,
                    size: params.pageSize,
                    title: params.title,
                    jobType: params.jobType,
                    companyName: params.companyName,
                    zhimaJobType: params.zhimaJobType,
                    isCompanyCert: params.isCompanyCert
                  }
                })

                return Promise.resolve({
                  data: res.data,
                  success: true,
                  total: 3000
                })
              }}
            />
          </Modal>
        }
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    educationOptions: state.global.educationOptions,
    identityOptions: state.global.identityOptions,
    jobTypeOptions: state.global.jobTypeOptions
  }
}

export default connect(mapStateToProps, null)(withRouter(CityService))
