import React, {Component} from "react";
import {Button, Space, Popconfirm,message, Modal} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {
  ProFormRate,
  ProFormRadio,
  ProFormTextArea
} from '@ant-design/pro-form';
import {GET_APPLY_RECORD, AGREE_APPLY, REJECT_APPLY, POST_EVALUATE} from "../../../service";
import {withRouter} from "react-router-dom";
import ConfirmTitle from "../../../components/ConfirmTitle/ConfirmTitle";
import {getQueryVariable} from "../../../utils";
import "./index.less"

class SendRecord extends Component {
  ref= React.createRef();
  formRef = React.createRef()
  reason = "";
  keyword = getQueryVariable(this.props.history.location.search, "name") || "";
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isCommitModalVisible: false,
      info: {},
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          dataIndex: 'keyword',
          title: "",
          hideInTable: true,
          fieldProps: {
            placeholder: "姓名或手机号"
          }
        },
        {
          title: '岗位名',
          width: 250,
          dataIndex: 'title',
          hideInSearch: true,
          render: ((text, record, _, action) => {
            return (
              <div className="info">
                <h2>{record.title}</h2>
                <p>{record.company}</p>
                <p>tel：{record.contactPhone}</p>
              </div>
            )
          })
        },
        {
          title: '职位来源',
          width: 120,
          dataIndex: 'spiderPlatform',
          render: ((text, record, _) => {
            return record.spiderPlatform?.desc || "-"
          }),
          valueType: 'select',
          fieldProps: {
            placeholder: "选择职位来源",
            options: [
              {label: '采集', value: 0},
              {label: '真实', value: 1}
            ]
          }
        },
        {
          title: '应聘者',
          dataIndex: 'sender',
          width: 250,
          hideInSearch: true,
          render: ((text, record, _, action) => {
            return (
              <div className="sender">
                <p>姓名：{record.name}</p>
                <p>电话：{record.mobile}</p>
                <p>邮箱：{record.email}</p>
                <p>学校：{record.collegeName}</p>
              </div>
            )
          })
        },
        {
          title: '类型',
          width: 100,
          dataIndex: 'type',
          hideInSearch: true,
          render: (text, record) => {
            return record.jobType?.desc
          }
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status',
          valueType: 'select',
          render: (text, record) => {
            return record.applyState?.desc
          },
          fieldProps: {
            placeholder: "选择状态",
            options: [
              {label: '报名成功', value: 1000},
              {label: '取消报名', value: 1200},
              {label: '已录用', value: 1400},
              {label: '不合适', value: 1500},
              {label: '取消录用', value: 1600}
            ]
          }
        },
        {
          title: '投递时间',
          width: 160,
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '查看时间',
          width: 160,
          hideInSearch: true,
          dataIndex: 'lookOverTime',
        },
        {
          title: '处理时间',
          width: 160,
          hideInSearch: true,
          dataIndex: 'handleTime',
        },
        {
          title: '操作',
          width: 320,
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space wrap>
              <Button type="default" onClick={() => this.handleView(record)}>查看</Button>
              <Popconfirm disabled={!(record.applyState?.code === 1000)} title={<ConfirmTitle title="请填写拒绝原因" getReasonText={this.getReasonText}/>} okText="确定" cancelText="取消" onConfirm={() => this.handleRefuse(record)}>
                <Button disabled={!(record.applyState?.code === 1000)} type="primary" danger>拒绝</Button>
              </Popconfirm>

              <Popconfirm disabled={!(record.applyState?.code === 1000)} title="确认录取该求职者吗？" okText="确定" cancelText="取消" onConfirm={() => this.handlePass(record)}>
                <Button disabled={!(record.applyState?.code === 1000)} type="primary">录取</Button>
              </Popconfirm>
              <Button type="dashed" danger onClick={() => this.handleCommit(record)}>评价</Button>
            </Space>
          ),
        },
      ]
    }
  }

  componentDidMount() {
  }

  handleCommit = (record) => {
    this.setState({
      isCommitModalVisible: true,
      info: record
    }, () => {
      this.formRef.current?.resetFields()
    })
  }

  handleCommitFinish = (values) => {
    this.formRef.current?.resetFields()
    React.fetch({
      url: POST_EVALUATE,
      method: 'post',
      data: {
        id: this.state.info.id,
        attendState: values.isInterview,  // 面试状态
        behaviorScore: values.behavior, // 行为举止
        expressionScore: values.express, // 表达能力
        professionalScore: values.major,  //专业程度
        thinkingScore: values.thinking,    //思维能力
        remark: values.remark  // 评语
      }
    }).then(() => {
      message.success("提交成功");
      this.setState({
        isCommitModalVisible: false
      })
    })

  }


  getReasonText = (text) => {
    this.reason=text;
  }

  handleCancel = () => {
    this.setState({
      isCommitModalVisible: false,
    })
  }


  handleView = record => {
    this.props.history.push("/resumeDetail?resume=" + record.resumeId);
  }
  handleRefuse = record => {
    React.fetch({
      url: REJECT_APPLY,
      method: "post",
      data: {
        applyId: record.id,
        remark: this.reason
      }
    }).then(res => {
      if(res.code === 200) {
        message.success("已拒用该应聘者");
        this.ref.current.reload();
      }
    })
  }
  handlePass = record => {
    React.fetch({
      url: AGREE_APPLY,
      method: "post",
      data: {
        applyId: record.id,
        remark: "符合"
      }
    }).then(res => {
      if(res.code === 200) {
        message.success("已录用该应聘者");
        this.ref.current.reload();
      }
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="send-record-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_APPLY_RECORD,
              method: "post",
              data:{
                page: params.page,
                size: 20,
                keyword: params.keyword || this.keyword,
                applyState: params.status,
                postSource: params.spiderPlatform
              }
            })
            return {
              data: res.code === 200 ? res.data : [],
              success: true,
              total: 100
            }
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={false}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>

        <Modal width={500} title={`应聘职位：${this.state.info.title}`} visible={this.state.isCommitModalVisible}
               footer={null}
               onCancel={this.handleCancel}>
          <ProForm formRef={this.formRef} layout="horizontal"
                   initialValues={{isInterview: 1, behavior: 3, express: 3, major: 3, thinking: 3}}
                   onFinish={(values) => {
                     this.handleCommitFinish(values)
                   }}>
            <ProFormRadio.Group
                name="isInterview"
                label="是否面试"
                options={[
                  {
                    label: '是',
                    value: 1,
                  },
                  {
                    label: '否',
                    value: 0,
                  }
                ]}
            />
            {/*表达能力 */}
            <ProFormRate name="behavior" label="行为举止"/>
            <ProFormRate name="express" label="表达能力"/>
            <ProFormRate name="major" label="专业程度"/>
            <ProFormRate name="thinking" label="思维能力"/>
            <ProFormTextArea
                rules={[{required: true, message:'请输入面试评语'}]}
                name="remark"
                label="面试评语"
                placeholder="请输入面试评语"
            />
          </ProForm>
        </Modal>
      </div>
    )
  }
}

export default withRouter(SendRecord)