import './index.less';
import React, {useState, useEffect} from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Checkbox,
  Popconfirm,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Radio,
  Tooltip,
  Cascader
} from 'antd';
import {getQueryVariable} from "../../../utils";
import AddressCascader from "../../../components/Address/Address";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {
  EDITE_AGREEMENT,
  GET_COLLECTJOB_DETAIL,
  GET_JOB_CATEGORY_ALL,
  GET_ALLOWN_COMPANYLIST,
  REJECT_COLLECTJOB,
  GET_REQUIRE_OPTION,
  GET_WELFARE_OPTION
} from "../../../service";

const {Option} = Select;


function SwitchCollectJob(props) {
  const recordId = getQueryVariable(props.location.search, "id");
  const [jobInfo, setJobInfo] = useState({});

  const [categoryOptions, setCategoryOptions] = useState([]);

  const educationOptions = [
    {label: "专科", value: '1'},
    {label: "本科", value: '2'},
    {label: "硕士", value: '3'},
    {label: "博士", value: '4'},
    {label: "高中/职高/技校", value: '5'},
    {label: "其他", value: '0'},
  ];

  const [requireOptions, setRequireOptions] = useState([]); //岗位要求

  const [welfareOptions, setWelfareOptions] = useState([]); //福利待遇

  const [collectJobInfo, setCollectJobInfo] = useState({});

  const [colletDom, setColletDom] = useState();

  const [address, setAddress] = useState("");
  const [areaId, setAreaId] = useState("");
  const [cityId, setCityId] = useState("");


  const [companyId, setCompanyId] = useState(""); //直招公司index
  const [contactMan, setContactMan] = useState("李先生"); //联系人
  const [contactPhone, setContactPhone] = useState("021-55381603-8029"); //联系人手机号
  const [locationCode, setLocationCode] = useState(""); //省市区code
  const [provinceId, setProvinceId] = useState("");
  const [recruitingEnterprise, setRecruitingEnterprise] = useState(""); // 代招企业名称
  const [recruitingType, setRecruitingType] = useState('1'); //直招  代招
  const [requireEducation, setRequireEducation] = useState("");
  const [requireSpecialty, setRequireSpecialty] = useState(""); //专业要求
  const [requireWorkTime, setRequireWorkTime] = useState(""); //工作周期
  const [requireWorkWeek, setRequireWorkWeek] = useState(""); //每周工作天数
  const [salaryType, setSalaryType] = useState("2"); //发薪方式
  const [settlementType, setSettlementType] = useState(""); //结算周期
  const [sex] = useState("");
  const [spiderPlatform, setSpiderPlatform] = useState("");
  const [welfareTags, setWelfareTags] = useState([]);//福利待遇
  const [requireTags, setRequireTags] = useState([]);//岗位要求
  const [zhimaJobType, setZhimaJobType] = useState("1");
  const [jobCategory, setJobCategory] = useState("");

  const [jobType, setJobType] = useState(""); //职位类别
  const [title, setTitle] = useState(""); //职位名称
  const [content, setContent] = useState(""); //工作内容
  const [needCount, setNeedCount] = useState(""); //工作内容
  const [salary, setSalary] = useState(""); //薪资
  const [salaryUnit, setSalaryUnit] = useState(""); //薪资单位
  const [workType, setWorkType] = useState("0"); //长招短招

  const [companyList, setCompanyList] = useState([]); //公司列表

  const [timeRange, setTimeRange] = useState([[]]); //时间段

  const [dateRange, setDateRange] = useState([""]); //日期


  const [addressInfo, setAddressInfo] = useState([]);//默认选中地区信息

  const addTimeRange = () => {
    let arr = timeRange.concat([]);
    if (arr.length === 5) {
      message.error("最多添加五个时间段");
      return;
    }
    arr.push([]);
    setTimeRange(arr);
  };
  const addTimeRangeData = (val, index) => {
    let arr = timeRange.concat([]);
    arr[index] = val;
    setTimeRange(arr);
  };

  const addDateRange = () => {
    let arr = dateRange.concat([]);
    if (arr.length === 5) {
      message.error("最多添加五个日期");
      return;
    }
    arr.push([]);
    setDateRange(arr);
  };
  const removeDateRange = (index) => {
    let arr = dateRange.concat([]);
    arr.splice(index, 1);
    setDateRange(arr);
  }
  const removeTimeRange = (index) => {
    let arr = timeRange.concat([]);
    arr.splice(index, 1);
    setTimeRange(arr);
  }

  const adDateRangeData = (val, index) => {
    let arr = dateRange.concat([]);
    arr[index] = val;
    setDateRange(arr);
  };


  const addressChange = (arr) => {
    if (arr.length === 0) return;
    if (arr.length === 3) {
      setProvinceId(arr[0].value);
      setCityId(arr[1].value);
      setAreaId(arr[2].value);
      setLocationCode(arr[2].code);
    } else {
      setProvinceId(arr[0].value);
      setCityId(arr[0].value);
      setAreaId(arr[1].value);
      setLocationCode(arr[1].code);
    }
  };
  const getCategoryOption = (value) => {
    //获取岗位分类备选项
    React.fetch({
      url: GET_JOB_CATEGORY_ALL,
      method: "post",
      data:{
        jobType:value
      }
    }).then((res) => {
      setCategoryOptions(res.data);
    });
  };

  //获取公司列表
  useEffect(() => {
    const getAllCompanyList = () => {
      React.fetch({
        url: GET_ALLOWN_COMPANYLIST,
        method: "get",
      }).then((res) => {
        if (res) {
          setCompanyList(res.data);
        }
      });
    };
    getAllCompanyList();
  }, []);

  useEffect(() => {
    const getCollectJobDetail = () => {
      React.fetch({
        url: GET_COLLECTJOB_DETAIL,
        method: "post",
        data: {
          id: recordId,
        },
      }).then((res) => {
        setJobInfo(res.data);
      });
    };
    getCollectJobDetail();
  }, [recordId]);
  useEffect(() => {
    function RenderCollectJobDetail() {
      const {
        id = "",
        companyName = "",
        companyContent = "",
        companyScale = "",
        companyTrade = "",
        companyNature = "",
        companySite = "",
        jobType = {},
        //职位类型
        title = "",
        content = "",
        needCount = "",
        //联系人
        //联系人邮箱
        //联系人方式
        basePay = "",
        clearingForm = "",
        address = "",
        //学历要求
        //专业要求
        //性别要求
        //年龄要求

        jobCategory = {},
        //短招
        //**长招 */
        timeRecruiting = "",
        requireWorkWeek = "",
        workingTime = "",
        spiderPlatform = {},
        salaryUnit = {},
        provinceId = '',
        cityId = '',
        areaId = '',
      } = jobInfo;
      setCollectJobInfo(jobInfo);
      //合并相同字段的内容
      setSpiderPlatform(spiderPlatform.code);
      if (jobType.code) {
        setJobType(jobType.code.toString());
      }
      if (jobCategory.code) {
        setWorkType(jobCategory.code.toString());
      }
      if (salaryUnit.code) {
        setSalaryUnit(salaryUnit.code.toString());
      }
      setTitle(title);
      setContent(content);
      setNeedCount(needCount);
      setSalary(parseInt(basePay).toString());

      //位置相关信息
      setAddress(address);
      setProvinceId(provinceId);
      setCityId(cityId);
      setAreaId(areaId);
      setLocationCode(areaId);
      //   默认选中对应的省市区
      if (provinceId && cityId && areaId) {
        setAddressInfo([provinceId, cityId, areaId])
      }

      return (
        <div className="job-detail collect-job">
          <div className="company-info">
            <h1>采集信息</h1>
            <Form>
              <Form.Item label="公司名称">{companyName}</Form.Item>
              <Form.Item label="公司描述">{companyContent}</Form.Item>
              <Form.Item label="公司规模">{companyScale}</Form.Item>
              <Form.Item label="公司行业">{companyTrade}</Form.Item>
              <Form.Item label="公司性质">{companyNature}</Form.Item>
              <Form.Item label="公司地址">{companySite}</Form.Item>
            </Form>
          </div>
          <div className="job-info">
            <h1>职位信息</h1>
            <Form>
              <Form.Item label="职位类别">{jobType.desc}</Form.Item>
              <Form.Item label="职位类型"/>
              <Form.Item label="职位名称">{title}</Form.Item>
              <Form.Item label="芝麻工作证"/>
              <Form.Item label="工作描述">
                <Tooltip
                  overlayInnerStyle={{maxWidth: "400px", width: "400px"}}
                  arrowPointAtCenter={true}
                  title={content}
                >
                  <p>{content}</p>
                </Tooltip>
              </Form.Item>
              <Form.Item label="招聘人数">{needCount}</Form.Item>
              <Form.Item label="联系人"/>
              <Form.Item label="联系方式"/>
              <Form.Item label="薪资">{basePay}</Form.Item>
              <Form.Item label="结算周期">{clearingForm}</Form.Item>
              <Form.Item label="工作地址">{address}</Form.Item>
              <Form.Item label="学历要求"/>
              <Form.Item label="专业要求"/>
              <Form.Item label="身高要求"/>
              <Form.Item label="工作种类">
                {{0: "无需要", 1: "长招", 2: "短招"}[jobCategory.code]}
              </Form.Item>

              {jobCategory.code === 1 ? (
                <Form.Item label="时间要求">{timeRecruiting}</Form.Item>
              ) : (
                ""
              )}
              {jobCategory.code === 1 ? (
                <Form.Item label="每周至少">{requireWorkWeek}</Form.Item>
              ) : (
                ""
              )}
              {jobCategory.code === 1 ? (
                <Form.Item label="上班时段">{workingTime}</Form.Item>
              ) : (
                ""
              )}
            </Form>
          </div>
          <Popconfirm
            title="你确定要该数据无效吗?"
            onConfirm={() => rejectCollectJob(id)}
            okText="确认"
            cancelText="取消"
          >
            <Button type="primary" danger>
              无效职位
            </Button>
          </Popconfirm>
        </div>
      );
    }

    if (jobInfo) {
      setColletDom(RenderCollectJobDetail());
    }
  });
  const rejectCollectJob = (id) => {
    React.fetch({
      url: REJECT_COLLECTJOB,
      method: "post",
      data: {
        id: id,
      },
    }).then((res) => {
      props.history.push("collectJobList");
    });
  };
  useEffect(() => {
    if (jobType) {
      getCategoryOption(jobType);
    }
  }, [jobType]);

  function hangdleTimeRange() {
    let arr = [];
    timeRange.forEach((item) => {
      if (item.length > 0) {
        arr.push(item[0].format("HH:mm") + "~" + item[1].format("HH:mm"));
      }
    });
    return arr.length > 0 ? arr.join(",") : "";
  }

  function hangdleDateRange() {
    let arr = [];
    dateRange.forEach((item) => {
      if (item) {
        arr.push(item.format("YYYY-MM-DD HH:mm:ss"));
      }
    });
    return arr.length > 0 ? arr.join(",") : "";
  }

  useEffect(() => {
    const getRequireOptions = () => {
      React.fetch({
        url: GET_REQUIRE_OPTION,
        method: "post",
        data: {
          jobType: jobType,
        },
      }).then((res) => {
        setRequireOptions(res.data);
      });
    };

    const getWelfareOptions = () => {
      React.fetch({
        url: GET_WELFARE_OPTION,
        method: "post",
        data: {
          jobType: jobType
        }
      }).then(res => {
        setWelfareOptions(res.data)
      })
    }
    if (jobType) {
      getRequireOptions();
      getWelfareOptions();
    }

  }, [jobType])
  const submitJobDetai = () => {

    if (!companyId) {
      message.error("请选择企业名称");
      return;
    }
    if (!settlementType) {
      message.error("请选择结算周期");
      return;
    }
    if ((!areaId || !provinceId || !cityId) && jobType !== '2') {
      message.error("企业地址填写不完整，请填写完整后提交");
      return;
    }
    React.fetch({
      url: EDITE_AGREEMENT,
      method: "post",
      data: {
        id: recordId,
        address: address,
        areaId: areaId,
        cityId: cityId,
        companyAccountId: companyList[companyId].accountId,
        companyId: companyList[companyId].id,
        contactMan: contactMan,
        contactPhone: contactPhone,
        content: content,
        jobCategory: jobCategory[1],
        jobType: jobType,
        locationCode: locationCode,
        needCount: needCount,
        provinceId: provinceId,
        recruitingEnterprise: recruitingEnterprise, //代招企业名称
        recruitingType: recruitingType,
        requireEducation: requireEducation,
        requireSpecialty: requireSpecialty,
        requireTags: requireTags.join(','),
        requireTimeInterval: hangdleTimeRange(),
        requireWorkDate: hangdleDateRange(),
        requireWorkTime: requireWorkTime,
        requireWorkWeek: requireWorkWeek,
        salary: salary,
        salaryType: salaryType,
        salaryUnit: salaryUnit,
        settlementType: settlementType,
        sex: sex,
        spiderPlatform: spiderPlatform,
        title: title,
        welfareTags: welfareTags.join(','),
        workType: workType,
        zhimaJobType: zhimaJobType,
      },
    }).then((res) => {
      if (res) props.history.push("/collectJobList");
    });
  };

  return (
    <div className="switch-collect-job">
      <Row>
        <Col span={11}>{colletDom}</Col>
        <Col span={2}>
          <div className="button-box">
            <Form>
              <Form.Item>
                <Button
                  onClick={() => {
                    setJobType(collectJobInfo.jobType?.code?.toString());
                  }}
                >
                  采集
                </Button>
              </Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setTitle(collectJobInfo.title);
                  }}
                >
                  采集
                </Button>
              </Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>
                <div className="job-dec">
                  <Button
                    onClick={() => {
                      setContent(collectJobInfo.content);
                    }}
                  >
                    采集
                  </Button>
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setNeedCount(collectJobInfo.needCount);
                  }}
                >
                  采集
                </Button>
              </Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
              <Form.Item>{/* <Button>采集</Button> */}</Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={11}>
          <div className="job-detail correct-job">
            <div className="company-info">
              <h1>保存信息</h1>
              <Form>
                <Form.Item label="请选择" required>
                  <Radio.Group
                    value={recruitingType}
                    onChange={(e) => {
                      setRecruitingType(e.target.value);
                    }}
                  >
                    <Radio value="0">直招</Radio>
                    <Radio value="1">代招</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="企业名称" required>
                  {/* {JSON.stringify(companyList)} */}
                  <Select
                    style={{width: 120}}
                    onChange={(val) => {
                      setCompanyId(val);
                    }}
                  >
                    {companyList.map((item, index) => {
                      return (
                        <Option key={item.id + index} value={index.toString()}>
                          {item.companyName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {recruitingType === "1" ? (
                  <Form.Item
                    label="代招企业名称"
                    onChange={(e) => {
                      setRecruitingEnterprise(e.target.value);
                    }}
                    required
                  >
                    <Input/>
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form>
            </div>

            <div className="job-info">
              <h1>职位信息</h1>
              <Form>
                <Form.Item label="职位类别" required>
                  <Select
                    value={jobType}
                    onChange={(val) => {
                      setJobType(val);
                    }}
                  >
                    <Option value="1">全职</Option>
                    <Option value="2">线上兼职</Option>
                    <Option value="3">线下兼职</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="职位类型" required>
                  <Cascader
                    onChange={(val) => {
                      setJobCategory(val);
                    }}
                    options={categoryOptions}
                    fieldNames={{
                      label: "postName",
                      value: "postCode",
                      children: "childDtoList",
                    }}
                    placeholder="选择职位"
                  />
                </Form.Item>
                <Form.Item label="职位名称" required>
                  <Input
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="芝麻工作证" required>
                  <Select
                    value={zhimaJobType}
                    onChange={(val) => {
                      setZhimaJobType(val);
                    }}
                  >
                    <Option value="0">芝麻优先</Option>
                    <Option value="1">无需认证</Option>
                    <Option value="2">需要认证</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="工作描述" required>
                  <div>
                    <Input.TextArea
                      value={content}
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item label="招聘人数" required>
                  <Input
                    value={needCount}
                    onChange={(e) => {
                      setNeedCount(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="联系人" required>
                  <Input
                    value={contactMan}
                    onChange={(e) => {
                      setContactMan(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="联系方式"
                  required
                >
                  <Input value={contactPhone}
                         onChange={(e) => {
                           setContactPhone(e.target.value);
                         }}/>
                </Form.Item>
                <Form.Item label="薪资" required>
                  <Input
                    value={salary}
                    style={{width: "45%", marginRight: "2%"}}
                    onChange={(e) => {
                      setSalary(e.target.value);
                    }}
                  />
                  <Select
                    style={{width: "48%"}}
                    placeholder="请选择"
                    value={salaryUnit}
                    onChange={(val) => {
                      setSalaryUnit(val);
                    }}
                  >
                    <Option value="1">元/小时</Option>
                    <Option value="2">元/天</Option>
                    <Option value="3">元/周</Option>
                    <Option value="4">元/月</Option>
                    <Option value="5">元/次</Option>
                    <Option value="6">其他</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="结算周期" required>
                  <Select
                    placeholder="请选择"
                    onChange={(val) => {
                      setSettlementType(val);
                    }}
                  >
                    <Option value='1'>日结</Option>
                    <Option value='2'>周结</Option>
                    <Option value='3'>月结</Option>
                    <Option value='4'>完结算</Option>
                    <Option value='5'>其他</Option>
                    <Option value='-1'>不限</Option>
                  </Select>
                </Form.Item>
                {jobType === "2" || jobType === "3" ? (
                  <Form.Item label="发薪方式" required>
                    <Radio.Group
                      value={salaryType}
                      onChange={(e) => {
                        setSalaryType(e.target.value);
                      }}
                    >
                      <Radio value="1">代发</Radio>
                      <Radio value="2">自主</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : (
                  ""
                )}

                {jobType !== '2' ? <Form.Item label="工作地址" required={jobType === '1'}>
                  <span
                    style={{
                      width: "50%",
                      marginRight: "10px",
                      display: "inline-block",
                    }}
                  >
                    <AddressCascader defaultAdress={addressInfo} onChange={addressChange}/>
                  </span>

                  <Input
                    style={{width: "45%"}}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </Form.Item> : ''}

                <Form.Item label="学历要求">
                  <Select
                    placeholder="请选择"
                    onChange={(val) => {
                      setRequireEducation(val);
                    }}
                  >
                    {educationOptions.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="专业要求">
                  <Input
                    onChange={(e) => {
                      setRequireSpecialty(e.target.value);
                    }}
                  />
                </Form.Item>
                {jobType !== "1" ? (
                  <Form.Item label="工作种类" required>
                    <Radio.Group
                      value={workType}
                      onChange={(e) => {
                        setWorkType(e.target.value);
                      }}
                    >
                      <Radio value="0">无需要</Radio>
                      <Radio value="1">长招</Radio>
                      <Radio value="2">短招</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : (
                  ""
                )}

                {/* 长招 */}
                {jobType !== "1" && workType === '1' ? (
                  <Form.Item label="时间要求" required>
                    <Select
                      placeholder="请选择"
                      onChange={(val) => {
                        setRequireWorkTime(val);
                      }}
                    >
                      <Option value={6}>能工作六个月以上</Option>
                      <Option value={5}>能工作五个月以上</Option>
                      <Option value={4}>能工作四个月以上</Option>
                      <Option value={3}>能工作三个月以上</Option>
                      <Option value={2}>能工作二个月以上</Option>
                      <Option value={1}>能工作一个月以上</Option>
                    </Select>
                  </Form.Item>
                ) : (
                  ""
                )}

                {jobType !== "1" && workType === "1" ? (
                  <Form.Item label="每周至少" required>
                    <Select
                      placeholder="请选择"
                      onChange={(val) => {
                        setRequireWorkWeek(val);
                      }}
                    >
                      <Option value={1}>一天</Option>
                      <Option value={2}>两天</Option>
                      <Option value={3}>三天</Option>
                      <Option value={4}>四天</Option>
                      <Option value={5}>五天</Option>
                      <Option value={6}>六天</Option>
                      <Option value={7}>七天</Option>
                    </Select>
                  </Form.Item>
                ) : (
                  ""
                )}

                {/* 短招 */}
                {jobType !== "1" && workType === "2" ? (
                  <Form.Item label="上班日期" required>
                    {dateRange.map((item, index) => {
                      return (
                        <div>
                          <DatePicker
                            key={index + 'riqi'}
                            onChange={(val) => {
                              adDateRangeData(val, index);
                            }}
                            style={{width: "50%", marginBottom: "20px"}}
                          />
                          <MinusCircleOutlined
                            style={{
                              display: index === 0 ? "none" : "inlineBlock",
                            }}
                            onClick={() => removeDateRange(index)}
                          />
                        </div>
                      );
                    })}

                    <Button
                      type="dashed"
                      onClick={addDateRange}
                      block
                      icon={<PlusOutlined/>}
                    >
                      添加工作日期
                    </Button>
                  </Form.Item>
                ) : (
                  ""
                )}

                {jobType !== "1" && (workType === "1" || workType === "2") ? (
                  <Form.Item label="上班时段" required>
                    {timeRange.map((item, index) => {
                      return (
                        <div>
                          <TimePicker.RangePicker
                            key={index + 'shijanduan'}
                            onChange={(val) => {
                              addTimeRangeData(val, index);
                            }}
                            setRequireWorkTime
                            format="HH:mm"
                            style={{
                              width: "50%",
                              marginBottom: "20px",
                              marginRight: "18px",
                            }}
                          />
                          <MinusCircleOutlined
                            style={{
                              display: index === 0 ? "none" : "inlineBlock",
                            }}
                            onClick={() => removeTimeRange(index)}
                          />
                        </div>
                      );
                    })}

                    <Button
                      type="dashed"
                      onClick={addTimeRange}
                      block
                      icon={<PlusOutlined/>}
                    >
                      添加工作时段
                    </Button>
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form>
            </div>

            <div className="other-info">
              <h1>其他信息</h1>
              <Form>
                <Form.Item label="上岗要求">
                  <Checkbox.Group
                    style={{width: "100%"}}
                    onChange={(val) => setWelfareTags(val)}
                  >
                    {requireOptions.map((item) => {
                      return <Checkbox key={item.id} value={item.id}>
                        {item.name}
                      </Checkbox>;
                    })}
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item label="福利待遇">
                  <Checkbox.Group
                    style={{width: "100%"}}
                    onChange={(val) => setRequireTags(val)}
                  >
                    {welfareOptions.map((item) => {
                      return <Checkbox key={item.id} value={item.id}>
                        {item.name}
                      </Checkbox>;
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </Form>
            </div>
            <Button type="primary" onClick={submitJobDetai}>
              提交审核
            </Button>
            <Button
              onClick={() => {
                props.history.push("collectJobList");
              }}
            >
              取消
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SwitchCollectJob;