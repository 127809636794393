import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {PlusOutlined} from '@ant-design/icons';
import {Button, message, Modal, Space} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormTextArea, ProFormRadio, ProFormSelect} from "@ant-design/pro-form";
import {CREAT_VIDEO_TIP,DELETE_VIDEO_TIP, UPDATE_VIDEO_TIP, GET_VIDEO_TIP_LIST} from "../../../../service";

class NoticeBarTipTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            page: 1,
            eId: "",
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 48,
                    hideInSearch: true,
                },
                {
                    title: '小贴士内容',
                    dataIndex: 'tip',
                    hideInSearch: true,
                },
                {
                    title: '创建人',
                    dataIndex: 'createdBy',
                    hideInSearch: true,
                },
                {
                    title: '内容类型',
                    dataIndex: 'tipType',
                    hideInTable: true,
                    initialValue: 0,
                    valueType: 'radio',
                    fieldProps: {
                        options: [
                            {label: '企业端', value: 1},
                            {label: '求职端', value: 2}
                        ]
                    }
                },
                {
                    title: '标签适用范围',
                    dataIndex: 'tipType',
                    hideInSearch: true,
                    initialValue: 0,
                    valueType: 'radio',
                    fieldProps: {
                        options: [
                            {label: '企业端', value: 1},
                            {label: '求职端', value: 2}
                        ]
                    },
                    render: (text, record) => {
                        return record.tipType?.desc
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdTime',
                    hideInSearch: true,
                },
                {
                    title: '当前状态',
                    dataIndex: 'tipEnable',
                    hideInSearch: true,
                    render: (text, record) => {
                        return record.tipEnable?.desc
                    }
                },
                {
                    title: '排序',
                    dataIndex: 'tipSort',
                    hideInSearch: true,
                    render: (text, record) => {
                        return record.tipSort
                    }
                },
                {
                    title: '操作',
                    width: 150,
                    key: 'option',
                    align: "center",
                    hideInSearch: true,
                    render: (text, record, _, action) => <Space>
                        <Button type="primary" onClick={() => this.handleEditor(record)}>编辑</Button>
                        <Button type="danger" onClick={() => this.handleDelete(record)}>删除</Button>
                    </Space>,
                },
            ]
        }
    }

    formRef = React.createRef()
    tableRef = React.createRef()

    handleEditor = record => {
        this.setState({
            eId: record.id,
            modalVisible: true
        }, () => {
            this.formRef.current.setFieldsValue({
                tip: record.tip,
                plateform: record.tipType?.code,
                status: record.tipEnable?.code,
                sort: record.tipSort
            })
        })
    }

    handleAdd = () => {
        this.setState({
            modalVisible: true
        })
    }

    handleDelete = record => {
        Modal.confirm({
            title: '提示',
            content: '确定删除该条数据吗？',
            onOk: async () => {
                const res = await React.fetch({
                    url: DELETE_VIDEO_TIP,
                    method: "post",
                    data: {
                        id: record.id
                    }
                })
                if (res.code === 200) {
                    message.success("删除成功")
                    this.tableRef.current.reload()
                } else {
                    message.error("删除失败")
                }
            }
        })
    }

    modalHide = () => {
        this.setState({
            modalVisible: false,
            eId: '',
        })
    }

    handlePrev = () => {
        let page = this.state.page;
        this.setState({
            page: page < 0 ? 0 : --page
        })
    }
    handleNext = () => {
        let page = this.state.page;
        this.setState({
            page: ++page
        })
    }

    render() {
        return (
            <div className="pro-table">
                <ProTable
                    actionRef={this.tableRef}
                    columns={this.state.columns}
                    request={async (params, sorter, filter) => {
                        const res = await React.fetch({
                            url: GET_VIDEO_TIP_LIST,
                            method: "post",
                            data: {
                                page: params.page,
                                size: 20,
                                tipType: params.tipType
                            }
                        })
                        return {
                            data: res.data || [],
                            success: true
                        }
                    }}
                    rowKey="id"
                    search={{
                        labelWidth: 'auto',
                    }}
                    onSubmit={() => {
                        this.setState({
                            page: 1
                        })
                    }}
                    pagination={false}
                    params={{
                        page: this.state.page
                    }}
                    dateFormatter="string"
                    toolBarRender={(action) => {
                        return [
                            <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                                添加小贴士
                            </Button>
                        ]
                    }}
                />
                <div className="page">
                    <Button type="link" className="prev" disabled={this.state.page === 1}
                            onClick={this.handlePrev}>上一页</Button>
                    <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
                </div>
                <Modal
                    title="添加/编辑小贴士"
                    footer={null}
                    visible={this.state.modalVisible}
                    onCancel={this.modalHide}
                >
                    <ProForm
                        formRef={this.formRef}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        layout='horizontal'
                        onFinish={async (values) => {
                            React.fetch({
                                url: this.state.eId ? UPDATE_VIDEO_TIP : CREAT_VIDEO_TIP,
                                method: "post",
                                data: {
                                    tip: values.tip,
                                    tipEnable: values.status,
                                    tipType: values.plateform,
                                    tipSort: values.sort,
                                    ...(this.state.eId ? {id: this.state.eId} : {})
                                }
                            }).then(res => {
                                if (res.code === 200) {
                                    message.success( this.state.eId ? "修改成功！": "添加成功");
                                    this.tableRef.current.reload()
                                }
                            }).finally(() => {
                                this.formRef.current.resetFields();
                                this.modalHide();
                            })
                        }}
                        submitter={{
                            render: (props, doms) => {
                                return <div style={{display: 'flex', gap: '8px', justifyContent: 'center'}}>
                                    {[...doms,]}
                                </div>;
                            },
                        }}
                    >
                        <ProFormTextArea name="tip" label="小贴士内容" placeholder="请输入小贴士内容"/>
                        <ProFormRadio.Group
                            name="plateform"
                            label="适用范围"
                            options={[
                                {
                                    label: "企业端",
                                    value: 1,
                                },
                                {
                                    label: '求职端',
                                    value: 2,
                                }
                            ]}
                        />
                        <ProFormRadio.Group
                            name="status"
                            label="有效状态"
                            options={[
                                {
                                    label: "显示",
                                    value: 1,
                                },
                                {
                                    label: '不显示',
                                    value: 0,
                                }
                            ]}
                        />
                        <ProFormSelect
                            name="sort"
                            label="排序"
                            options={[
                                {
                                    label: "1",
                                    value: 1,
                                },
                                {
                                    label: '2',
                                    value: 2,
                                },
                                {
                                    label: '3',
                                    value: 3,
                                },
                                {
                                    label: '4',
                                    value: 4,
                                },
                                {
                                    label: '5',
                                    value: 5,
                                }
                            ]}
                        />
                    </ProForm>
                </Modal>
            </div>
        )
    }
}

export default withRouter(NoticeBarTipTable)