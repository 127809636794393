import React, {Component} from "react";
import moment from 'moment';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Descriptions, message, Modal} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import Field from '@ant-design/pro-field';
import ProForm, {ProFormDateTimeRangePicker, ProFormDigit, ProFormSelect, ProFormTextArea} from '@ant-design/pro-form';
import {
  ADD_SIGN_COMPANY,
  GET_COMPANY_LIST_ALL,
  GET_COMPANY_ROLE_LIST_ALL,
  GET_COMPANY_SIGN_INFO,
  GET_SIGN_COMPANY_LIST,
  UPDATE_SIGN_COMPANY
} from "../../../service";
import "./index.less";

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

class CompanySign extends Component {
  roleList = [];
  companyList = [];
  originInfo = {};

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      roleList: [],
      companyList: [],
      originInfo: {
        name: "",
        identity: "",
        time: ["", ""],
        number: "",
        payType: "",
        remark: ""
      },
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '企业名称',
          dataIndex: 'companyName',
          hideInSearch: true,
          ellipsis: true,
        },
        {
          title: '',
          dataIndex: 'query',
          hideInTable: true,
          fieldProps: {
            placeholder: "企业或对接人"
          }
        },
        {
          title: '签约身份',
          dataIndex: 'roleName',
          valueType: 'select',
          fieldProps: {
            options: []
          },
        },
        {
          title: '结算方式',
          dataIndex: 'premiumType',
          valueType: 'select',
          fieldProps: {
            options: [
              {value: 1, label: "线上结算"},
              {value: 2, label: "线下结算"},
            ]
          },
          render: (text, record) => {
            return record.premiumType?.desc
          }
        },
        {
          title: '查看简历次数',
          dataIndex: 'remark',
          hideInSearch: true,
          render: (text, record) => {
            return (record.remainingNumber || 0) + ' / ' + (record.number || 0)
          }
        },
        {
          title: '签约状态',
          dataIndex: 'premiumUserType',
          valueType: 'select',
          fieldProps: {
            options: [
              {value: -2, label: "未开始"},
              {value: -1, label: "已失效"},
              {value: 1, label: "履约中"},
            ]
          },
          render: (text, record) => {
            return record.premiumUserType?.desc
          }
        },
        {
          title: '签约时间',
          dataIndex: 'remark',
          hideInSearch: true,
          width: 360,
          ellipsis: true,
          render: (text, record) => {
            return record.premiumBeginTime + ' - ' + record.premiumEndTime
          }
        },
        {
          title: '对接人',
          dataIndex: 'accountName',
          hideInSearch: true,
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          width: 200,
          render: (text, record) => (
            <Button type="primary" onClick={() => this.handleEditor(record)}>修改</Button>
          ),
        },
      ]
    }
  }

  formRef = React.createRef()
  ref = React.createRef()

  async componentDidMount() {
    this.getCompanyList();
    const result = await this.getAllRoleList();
    const {columns} = this.state;
    columns[3].fieldProps.options = result;
    this.setState({
      columns
    })
  }

  getAllRoleList = () => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_COMPANY_ROLE_LIST_ALL
      }).then(res => {
        if (res.code === 200) {
          const result = res.data.map(item => ({
            value: item.id,
            label: item.roleName,
            disabled: item.isEnable === 0
          }))
          resolve(result)
          this.setState({
            roleList: result
          })
          this.roleList = result;
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }

  getCompanyList = () => {
    return new Promise((resolve, reject) => {
      React.fetch({
          url: GET_COMPANY_LIST_ALL
        }
      ).then(res => {
        if (res.code === 200) {
          const result = res.data.map(item => ({
            value: item.id,
            label: item.companyName
          }))
          resolve(result)
          this.companyList = result;
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }
  getSignInfo = (id) => {
    return new Promise((resolve, reject) => {
      React.fetch({
          url: GET_COMPANY_SIGN_INFO,
          method: "post",
          data: {id}
        }
      ).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }


  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  findCompanyId = (name) => {
    return this.companyList?.find(item => item.label === name)?.value || ""
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    }, () => {
      this.formRef.current.resetFields();
    })
  }

  handleEditor = data => {
    this.setState({
      eType: 2,
      eId: data.id,
      modalVisible: true
    }, async () => {
      const result = await this.getSignInfo(data.id);
      const settingData = {
        name: this.findCompanyId(result.companyName),
        identity: result.roleId,
        time: [result.premiumBeginTime, result.premiumEndTime],
        number: result.remainingNumber,
        payType: result.premiumType?.code,
        remark: result.remark
      }

      this.formRef.current.setFieldsValue(settingData);
      this.originInfo = settingData;
      this.setState({
        originInfo: {
          number: result.originalNumber,
          time: [result.originalPremiumBeginTime, result.originalPremiumEndTime],
          identity: result.originalRoleId,
          payType: result.originalPremiumType?.code
        }
      })
    })
  }


  submit = (values) => {
    if(new Date(values.time[0]).getTime() >=new Date(values.time[1]).getTime()){
      message.error("签约截止时间需大于开始时间")
      return;
    }
    React.fetch({
        url: ADD_SIGN_COMPANY,
        method: "post",
        data: {
          companyId: values.name,
          roleId: values.identity,
          premiumType: values.payType,
          premiumBeginTime: values.time[0],
          premiumEndTime: values.time[1],
          number: values.number,
          remark: values.remark
        }
      }
    ).then(res => {
      if (res) {
        message.success("添加成功！");
        this.ref.current.reload();
        this.formRef.current.resetFields();
        this.modalHide();
      }
    })
  }
  edite = (values) => {

    if(new Date(values.time[0]).getTime() >=new Date(values.time[1]).getTime()){
      message.error("签约截止时间需大于开始时间")
      return;
    }

    React.fetch({
        url: UPDATE_SIGN_COMPANY,
        method: "post",
        data: {
          id: this.state.eId,
          companyId: values.name,
          roleId: values.identity,
          premiumType: values.payType,
          premiumBeginTime: values.time[0],
          premiumEndTime: values.time[1],
          number: values.number,
          remark: values.remark,
          originalRoleId: this.originInfo.identity,
          originalPremiumType: this.originInfo.payType,
          originalPremiumBeginTime: this.originInfo.time[0],
          originalPremiumEndTime: this.originInfo.time[1],
          originalNumber: this.originInfo.number,
        }
      }
    ).then(res => {
      if (res.code) {
        message.success("修改成功！");
        this.ref.current.reload();
        this.formRef.current.resetFields();
        this.modalHide();
      }
    })
  }


  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    const {eType, columns, page, modalVisible} = this.state;
    return (
      <div className="company-sign-page">
        <ProTable
          actionRef={this.ref}
          columns={columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_SIGN_COMPANY_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                roleId: params.roleName,
                premiumUserType: params.premiumUserType,
                premiumType: params.premiumType,
                keyword: params.query,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          pagination={false}
          search={{
            labelWidth: 'auto'
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          params={{
            page: page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<PlusOutlined/>}
                type="primary"
                onClick={this.handleAdd}
              >
                添加签约企业
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          width={eType === 1 ? 600 : 780}
          title={eType === 1 ? "添加" : "编辑"}
          footer={null}
          visible={modalVisible}
          onCancel={this.modalHide}
        >
          <ProCard split="vertical">
            <ProCard title="" colSpan={eType === 1 ? '100%' : '60%'}>
              <ProForm
                formRef={this.formRef}
                labelCol={{span: 5}}
                wrapperCol={{span: 19}}
                layout="horizontal"
                onFinish={(values) => {
                  eType === 1 ? this.submit(values) : this.edite(values);
                }}
                submitter={{
                  render: (props, doms) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "8px",
                        }}
                      >
                        {[...doms]}
                      </div>
                    );
                  },
                }}
              >
                <ProFormSelect
                  name="name"
                  label="签约企业"
                  disabled={eType === 2}
                  fieldProps={{
                    showSearch: true,
                    optionFilterProp: "label",
                    options: this.companyList,
                    filterOption: (input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  }}
                  placeholder="企业名称"
                  rules={[{required: true, message: '请输入企业名称!'}]}
                />

                <ProFormSelect
                  name="identity"
                  label="签约身份"
                  options={this.state.roleList}
                  placeholder="请选择签约身份"
                  rules={[{required: true, message: '请选择签约身份！'}]}
                />
                <ProFormDateTimeRangePicker
                  name="time"
                  label="签约时间"
                  placeholder={["开始时间", "结束时间"]}
                  showTime={{
                    hideDisabledOptions: false,
                    defaultValue: [null, moment('00:00:00', 'HH:mm:ss')],
                  }}
                  rules={[{required: true, message: '请选择签约时间！'}]}
                  fieldProps={{
                    disabledTime: (_, type) => {
                      if (type === 'end') {
                        return {
                          disabledHours: () => range(1, 24),
                          disabledMinutes: () => range(1, 60),
                          disabledSeconds: () => range(1, 60),
                        };
                      }
                    },
                    onCalendarChange: (dates, dateStrings, info) => {}
                  }}

                />
                <ProFormSelect
                  name="payType"
                  label="结算方式"
                  options={[
                    {label: '线上结算', value: 1},
                    {label: '线下结算', value: 2}
                  ]}
                  placeholder="请选择结算方式"
                  rules={[{required: true, message: '请选择结算方式！'}]}
                />
                <ProFormDigit
                  label="查看次数"
                  name="number"
                  placeholder="请选择查看次数"
                  rules={[{required: true, message: '请选择查看次数！'}, {type: "number", message: '请输入数字类型'}]}
                />
                <ProFormTextArea
                  label="备注说明"
                  name="remark"
                  placeholder="请选择备注说明"
                  rules={[{required: true, message: '请选择备注说明！'}]}
                />
              </ProForm>
            </ProCard>
            {
              eType === 2 ? <ProCard title="原签约信息" headerBordered>
                <Descriptions title="" column={1}>
                  <Descriptions.Item label="原签约身份">
                    <Field
                      text={this.state.originInfo.identity}
                      mode="read"
                      request={
                        async () => {
                          return await this.getAllRoleList()
                        }
                      }
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="原签约时间">
                    {
                      this.state.originInfo?.time && this.state.originInfo?.time[1] ? <Field
                        text={[
                          moment(this.state.originInfo?.time[0])?.valueOf(),
                          moment(this.state.originInfo?.time[1])?.valueOf(),
                        ]}
                        valueType="dateTimeRange"
                      /> : <Field
                        text="-"
                        mode="read"
                      />
                    }
                  </Descriptions.Item>

                  <Descriptions.Item label="原结算方式">
                    {
                      this.state.originInfo.payType ? <Field
                        text={this.state.originInfo.payType + ""}
                        mode="read"
                        valueEnum={{
                          1: {text: '线上结算'},
                          2: {text: '线下结算'},
                        }}
                      /> : <Field
                        text="-"
                        mode="read"
                      />
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="总次数">
                    <Field text={this.state.originInfo.number} valueType="text"/>
                  </Descriptions.Item>
                </Descriptions>
              </ProCard> : null
            }
          </ProCard>
        </Modal>
      </div>
    );
  }
}

export default CompanySign;