import React from "react";
import {Layout} from 'antd';

import "./header.less"

const {Header} = Layout;

function IHeader(props) {
  return (
    <Header className="site-layout-background" style={{padding: '0 20px'}}>
      <div className="view-layout">
        {props.children}
      </div>

    </Header>
  )
}

export default IHeader