import React, {Component} from "react";
import {connect} from "react-redux";
import {LoginForm, ProFormText, ProFormCaptcha} from '@ant-design/pro-form';
import {
  UserOutlined,
  MobileOutlined,
  LockOutlined,
  PoweroffOutlined
} from '@ant-design/icons';
import {message, Tabs} from 'antd';

import {withRouter} from "react-router-dom";
import {ADD_TOKEN} from "../../store/actionTypes";
import {ACCOUNT_LOGIN, MOBILE_LOGIN, CREAT_CAPTCHA_IMG ,SEND_SMS_CODE} from "../../service";
import {renderParticles} from "../../assets/js/particlesJS";
import {uuid} from "../../utils";
import "./login.less";

const _uuid = uuid(32, 16);


class Login extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      account: "",   //登录账号
      password: "",      //登录密码
      loading: false,
      loginType: "account",
      captchaImg: ""
    }
  }

  componentDidMount() {
    renderParticles();
    this.createCaptchaCode();
    window.addEventListener("resize", renderParticles)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", renderParticles);
  }

  setLoginType = (activeKey) => {
    this.setState({
      loginType: activeKey
    })
  }

  createCaptchaCode = () => {
    React.fetch({
      url: CREAT_CAPTCHA_IMG,
      method: "post",
      data: {
        uuid: _uuid
      }
    }).then(res => {
      const myBlob = new window.Blob([res], {type: 'image/png'});
      this.setState({
        captchaImg: window.URL.createObjectURL(myBlob)
      })
    })
  }

  getSmsCode = (phone) => {
    return new Promise((resolve, reject) => {
      const captchaCode = this.formRef.current.getFieldValue("captcha");
      if(!captchaCode){
        reject("请填写图形验证码")
        return false;
      }
      React.fetch({
        url: SEND_SMS_CODE,
        method: "post",
        data: {
          account: phone,
          captchaCode,
          uuid: _uuid
        }
      }).then(res => {
        if(res.code === 200) {
          resolve(res.data);
        }else{
          this.createCaptchaCode();
          reject();
        }
      }).catch(() => {
        this.createCaptchaCode();
        reject();
      })
    })
  }

  handleLogin = values => {
    if (this.state.loginType === "account") {
      React.fetch({
        url: ACCOUNT_LOGIN,
        method: "post",
        data: {
          account: values.account,
          password: values.password,
          captchaCode: values.captcha,
          uuid: _uuid
        }
      }).then(res => {
        if (res.code === 200) {
          this.props.seToken(res.data);
          setTimeout(() => {
            this.props.history.push("/systemInfo");
          }, 300)
        } else {
          this.createCaptchaCode();
        }
      }).catch(() => {
        this.createCaptchaCode();
      })
    } else {
      React.fetch({
        url: MOBILE_LOGIN,
        method: "post",
        data: {
          account: values.account,
          smsCode: values.smsCode
        }
      }).then(res => {
        if (res.code === 200) {
          this.props.seToken(res.data);
          setTimeout(() => {
            this.props.history.push("/systemInfo");
          }, 300)
        } else {
          this.createCaptchaCode();
        }
      }).catch(() => {
        this.createCaptchaCode();
      })
    }
  }


  render() {
    const {loginType} = this.state;
    return (
      <div className="login-page">
        <canvas className="canvas" id="canvas"/>

        <div className="login-form">
          <LoginForm
            formRef={this.formRef}
            title="See Me招聘管理系统"
            subTitle=""
            onFinish={(values) => {
              this.handleLogin(values);
            }}
          >
            <Tabs activeKey={loginType} onChange={(activeKey) => this.setLoginType(activeKey)}>
              <Tabs.TabPane key={'account'} tab={'账号密码登录'}/>
              <Tabs.TabPane key={'phone'} tab={'验证码登录'}/>
            </Tabs>
            {loginType === 'account' && (
              <>
                <ProFormText
                  name="account"
                  fieldProps={{size: 'large', prefix: <UserOutlined className={'prefixIcon'}/>}}
                  placeholder={'账号'}
                  rules={[{required: true, message: '请输入账号!'}]}
                />
                <ProFormText.Password
                  name="password"
                  fieldProps={{size: 'large', prefix: <LockOutlined className={'prefixIcon'}/>}}
                  placeholder={'密码'}
                  rules={[{required: true, message: '请输入密码！'}]}
                />
                <ProFormText
                  name="captcha"
                  fieldProps={{
                    size: 'large',
                    prefix: <PoweroffOutlined className={'prefixIcon'}/>,
                    suffix: <div className="captcha-image" onClick={this.createCaptchaCode}>
                      <img src={this.state.captchaImg} alt="图形验证码"/></div>
                  }}
                  placeholder={'图形验证码'}
                  rules={[{required: true, message: '请输入图形验证码！'}]}
                />
              </>
            )}
            {loginType === 'phone' && (
              <>
                <ProFormText
                  fieldProps={{
                    size: 'large',
                    prefix: <MobileOutlined className={'prefixIcon'}/>,
                  }}
                  name="account"
                  placeholder={'账号'}
                  rules={[{required: true, message: '请输入账号！'}]}
                />
                <ProFormText
                  name="captcha"
                  fieldProps={{
                    size: 'large',
                    prefix: <PoweroffOutlined className={'prefixIcon'}/>,
                    suffix: <div className="captcha-image" onClick={this.createCaptchaCode}>
                      <img src={this.state.captchaImg} alt="图形验证码"/></div>
                  }}
                  placeholder={'图形验证码'}
                />
                <ProFormCaptcha
                  fieldProps={{size: 'large', prefix: <PoweroffOutlined className={'prefixIcon'}/>}}
                  captchaProps={{size: 'large'}}
                  placeholder={'请输入验证码'}
                  captchaTextRender={(timing, count) => {
                    if (timing) {
                      return `${count} ${'获取验证码'}`;
                    }
                    return '获取验证码';
                  }}
                  name="smsCode"
                  phoneName="account"
                  rules={[{required: true, message: '请输入短信验证码！',}]}
                  onGetCaptcha={async (account) => {
                    try{
                      const result = await this.getSmsCode(account);
                      message.success(`已向${result}的发送验证码，请注意查收！`);
                    }catch(e){
                      throw new Error(e);
                    }
                  }}
                />
              </>
            )}
          </LoginForm>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    token: state.login.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    seToken: token => dispatch({type: ADD_TOKEN, token}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))