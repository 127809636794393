import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {Button, Image,Popconfirm} from 'antd';
import ProTable from '@ant-design/pro-table';


class CooperationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      page: 1,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 48,
          hideInSearch: true,
        },
        {
          title: ' 企业名称',
          dataIndex: 'title',
        },
        {
          title: '企业logo',
          width: 120,
          dataIndex: 'logo',
          hideInSearch: true,
          render: ((text, record, _, action) => {
            return <Image
              width={100}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          })
        },
        {
          title: '登录账号',
          dataIndex: 'account',
          hideInSearch: true,
        },
        {
          title: '是否认证',
          dataIndex: 'authentication',
          initialValue: 0,
          valueType: 'select',
          fieldProps: {
            options: [
              {label: '全部', value: 0},
              {label: '已认证', value: 1},
              {label: '未认证', value: 2}
            ]
          }
        },
        {
          title: '排序',
          dataIndex: 'sort',
          hideInSearch: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          hideInSearch: true,
        },
        {
          title: '签约状态',
          dataIndex: 'status',
          initialValue: 0,
          valueType: 'select',
          render: ((text, record, _, action) => {
            if(record.status === 1) {
              return <Button type="link">已签约</Button>
            }else{
              return <Button type="link" danger>未签约</Button>
            }
          }),
          fieldProps: {
            options: [
              {label: '全部', value: 0},
              {label: '已签约', value: 1},
              {label: '未签约', value: 2}
            ]
          }
        },
        {
          title: '登记时间',
          dataIndex: 'sign_time',
          hideInSearch: true,
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
          hideInSearch: true,
        },
        {
          title: '操作',
          width: 150,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record, _, action) => {
            return (
              <Popconfirm title={record.status === 1?'确定取消签约吗？':'确定签约吗？'} okText="Yes" cancelText="No">
                <Button type="primary" danger={record.status === 1} onClick={() =>this.handleSign(record)}>{record.status === 1?'取消签约':'签约'}</Button>
              </Popconfirm>
            )
          },
        },
      ]
    }
  }
  handleSign = (record) => {
    console.log(record)
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="pro-table">
        <ProTable
          columns={this.state.columns}
          request={async (params, sorter, filter) => {
            // 表单搜索项会从 params 传入，传递给后端接口。
            return Promise.resolve({
              data: [{id: 1, status: 1},{id:2, status: 2}],
              success: true,
              total: 500,
            });
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          dateFormatter="string"
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
      </div>
    )
  }
}

export default withRouter(CooperationTable)