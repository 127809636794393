let initialState = {
  fetchStart: false,
  fetchEnd: false,
  activeRouter: "",
  educationOptions: [
    {label: "专科", value: 1},
    {label: "本科", value: 2},
    {label: "硕士", value: 3},
    {label: "博士", value: 4},
    {label: "高中/职高/技校", value: 5},
    {label: "其他", value: 0},
  ],
  identityOptions: [
    {label: "学生", value: 1},
    {label: "上班族", value: 2},
    {label: "自由职业", value: 3},
  ],
  salaryOptions: [
    {label: "代发", value: 1},
    {label: "自主发薪", value: 2},
  ],
  sexOptions: [
    {label: "不限", value: -1},
    {label: "女", value: 0},
    {label: "男", value: 1},
  ],
  jobTypeOptions: [
    {label: "全职", value: 1},
    // {label: "线上兼职", value: 2},
    // {label: "线下兼职", value: 3},
    // {label: "任务", value: 4, disabled: true},
  ],
  tagTypeOptions: [
    {label: '福利待遇', value: 1},
    {label: '上岗要求', value: 2}
  ],
  jobTagTypeOptions: [
    {label: '性格标签', value: 3},
    {label: '技能标签', value: 4},
    {label: '形象标签', value: 5},
  ],
  applicationOptions: [
    {label: '求职端', value: 1},
    {label: '企业端', value: 2}
  ]
}

export const globalReducer = (state = initialState, action) => state