import React, {Component} from "react";
import {Button, Space, Modal, Table, message, Tag} from "antd";
import {withRouter} from "react-router-dom";
import ProTable from "@ant-design/pro-table";
import {
  GET_ATTACHMENT_LIST,
  SET_ATTACHMENT_STATUS,
  PREVIEW_ATTACHMENT,
  JOB_LIST,
  RECOMMEND_JOB
} from "../../../service";
import {connect} from "react-redux";
import "./index.less";

class AttachmentManage extends Component {
  applicantAccountId = "";
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isModalVisible: false,
      selectedRowKeys: [],
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '附件简历名称',
          dataIndex: 'fileName',
          hideInSearch: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '联系方式',
          dataIndex: 'mobile',
          hideInSearch: true
        },
        {
          title: '是否默认投递',
          dataIndex: 'isDefault',
          hideInSearch: true,
          render: (text, record) => {
            return record.isDefault === 1 ? <Tag color="success">是</Tag> : <Tag color="error">否</Tag>
          }
        },
        {
          title: '有效状态',
          dataIndex: 'isEnable',
          hideInSearch: true,
          render: (text, record) => {
            return record.isEnable === 1 ? <Tag color="success">有效</Tag> : <Tag color="error">无效</Tag>
          }
        },
        {
          title: '创建时间',
          hideInSearch: true,
          dataIndex: 'createdTime',
        },
        {
          title: '更新时间',
          hideInSearch: true,
          dataIndex: 'updatedTime',
        },
        {
          title: '操作',
          width: 220,
          valueType: 'option',
          align: 'center',
          render: (text, record) => (
            <Space>
              <Button type="primary" onClick={() => this.handleView(record)}>详情</Button>
              <Button type="primary" onClick={() => this.handleRecommend(record)} danger>推荐</Button>
              {
                record.isEnable === 1 ?
                  <Button type="default" onClick={() => this.handleEnable(record)} danger>禁用</Button> :
                  <Button type="default" onClick={() => this.handleEnable(record)}>启用</Button>
              }

            </Space>
          ),
        },
      ],
      modalColumn: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '职位名称',
          dataIndex: 'title'
        },
        {
          title: '企业名称',
          dataIndex: 'companyName',
          hideInSearch: true
        },
        {
          title: '企业账号ID',
          dataIndex: 'companyAccountId',
          hideInTable: true
        },
        {
          title: '学历要求',
          dataIndex: 'requireEducation',
          valueType: 'select',
          render: (text, record) => {
            return record.requireEducation?.desc
          },
          fieldProps: {
            placeholder: "选择学历",
            options: this.props.educationOptions
          }
        },
        {
          title: '薪资',
          dataIndex: 'salary',
          hideInSearch: true,
          render: (text, record) => {
            return record.salary + "/" + record.salaryUnit?.desc
          }
        },
        {
          title: '职位类型',
          dataIndex: 'type',
          valueType: 'select',
          fieldProps: {
            placeholder: "岗位类型",
            options: this.props.jobTypeOptions
          },
          render: (text, record) => {
            return record.jobType?.desc
          },
        },
        {
          title: '发布时间',
          hideInSearch: true,
          dataIndex: 'publishTime',
        },
        {
          title: '刷新时间',
          hideInSearch: true,
          dataIndex: 'refreshTime',
        },
      ],
      preViewShow: false,
      href: ""
    }
  }

  handleView = data => {
    this.setState({
      preViewShow: true,
    })

    React.fetch({
      url: PREVIEW_ATTACHMENT,
      method: "post",
      data: {
        applicantAccountId: data.applicantAccountId,
      }
    }).then(res => {
      let href = URL.createObjectURL(new Blob([res], {type: 'application/pdf;charset-UtF-8'}))
      this.setState({
        href
      })
    })
  }

  handleEnable = data => {
    React.fetch({
      url: SET_ATTACHMENT_STATUS,
      method: "post",
      data: {
        applicantAccountId: data.applicantAccountId,
        id: data.id,
        isEnable: data.isEnable === 1 ? 0 : 1
      }
    }).then(res => {
      if (res.code === 200) {
        message.success(data.isEnable === 1 ? '简历已禁用' : '简历已启用');
        this.ref.current.reload()
      }
      this.modalVisible(false);
    })
  }

  handleRecommend = data => {
    this.modalVisible(true);
    this.applicantAccountId = data.applicantAccountId
  }
  handleOk = () => {
    this.modalVisible(false);
    React.fetch({
      url: RECOMMEND_JOB,
      method: "post",
      data: {
        applicantAccountId: this.applicantAccountId,
        jobIdList: this.state.selectedRowKeys
      }
    }).then(res => {
      if (res.code === 200) {
        message.success("岗位推荐成功");
        this.setState({selectedRowKeys: []});
      }
      this.modalVisible(false);
    })
  }
  handleCancel = () => {
    this.modalVisible(false);
  }

  onSelectChange = selectedRowKeys => {
    this.setState({selectedRowKeys});
  }

  modalVisible = status => {
    this.setState({
      isModalVisible: status,
    })
  }
  handleHide = () => {
    this.setState({
      preViewShow: false,
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="resume-manage-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_ATTACHMENT_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
              }
            })
            return Promise.resolve({
              data: res.data,
              success: true,
            })
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={false}
        />

        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        {
          this.state.isModalVisible &&
          <Modal width={1200} title="推荐岗位" visible={this.state.isModalVisible} onOk={this.handleOk}
                 onCancel={this.handleCancel}>
            <ProTable
              columns={this.state.modalColumn}
              rowSelection={{
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                selectedRowKeys: this.state.selectedRowKeys,
                onChange: this.onSelectChange
              }}
              tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}) => (
                <span>
                已选 {selectedRowKeys.length} 项
                <Button type="link" style={{marginLeft: 8}} onClick={onCleanSelected}>
                  取消选择
                </Button>
              </span>
              )}
              search={{
                labelWidth: "auto"
              }}
              onSubmit={()=>{
                this.setState({
                  page: 1
                })
              }}
              pagination={{
                pageSize: 10
              }}
              rowKey="id"
              toolBarRender={false}
              request={async (params) => {
                const res = await React.fetch({
                  url: JOB_LIST,
                  method: "post",
                  data: {
                    page: params.current,
                    size: params.pageSize,
                    title: params.title,
                    jobType: params.jobType,
                    companyAccountId: params.companyAccountId
                  }
                })

                return Promise.resolve({
                  data: res.data,
                  success: true,
                  total: 1000
                })
              }}
            />
          </Modal>
        }
        <Modal width={1200} title="预览" visible={this.state.preViewShow} footer={null}
               onCancel={this.handleHide}>
          <iframe src={this.state.href} width="100%" height="600" title="预览附件简历"/>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    educationOptions: state.global.educationOptions,
    identityOptions: state.global.identityOptions,
    jobTypeOptions: state.global.jobTypeOptions
  }
}

export default connect(mapStateToProps, null)(withRouter(AttachmentManage))