import React,{Component} from "react";
import {Tabs} from "antd";
import ModalTipTable from "./components/ModalTipTable";
import NoticeBarTipTable from "./components/NoticeBarTipTable";

import "./index.less"
const { TabPane } = Tabs;

class CompanyManage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1",
        }
    }

    handleTabChange = activeKeys => {
        this.setState({
            activeKey: activeKeys
        })
    }

    render() {
        const {activeKey} = this.state
        return (
            <div className="company-manage-page">
                <div className="card-container">
                    <Tabs type="card" onChange={this.handleTabChange}>
                        <TabPane tab="提示说明" key="1">
                            {activeKey === '1' && <ModalTipTable></ModalTipTable>}
                        </TabPane>
                        <TabPane tab="小贴士内容" key="2">
                            {activeKey === '2' && <NoticeBarTipTable></NoticeBarTipTable>}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

}

export default CompanyManage