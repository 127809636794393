import React, {Component} from "react";
import {getQueryVariable} from "../../../utils";
import {PlusOutlined} from '@ant-design/icons';
import {Button,Popconfirm,Space,Modal,Input,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import {GET_MENU_AUTH_LIST,GET_AUTH_LIST,ADD_MENU_AUTH,DELETE_MENU_AUTH} from "../../../service";
import "./index.less"


class PageAuthList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        menuId: getQueryVariable(this.props.location.search, "menuId"),
        page: 1,
        modalVisible: false,
        keyword:'',
        columns: [
          {
            dataIndex: "id",
            title: "ID",
            width: 48,
            hideInSearch: true,
          },
          {
            title: "权限名称",
            dataIndex: "authName",
            hideInSearch: true,
          },
          {
            title: "权限地址",
            dataIndex: "auth",
            hideInSearch: true,
          },
          {
            title: "操作",
            valueType: "option",
            align: "center",
            render: (text, record, _, action) => (
              <Space>
                <Popconfirm
                  title="确定杀出该权限吗？"
                  okText="删除"
                  cancelText="取消"
                  onConfirm={() => this.handleDeleteAuth(record)}
                >
                  <Button type="primary" danger>
                    删除
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ],
        popColums: [
          {
            dataIndex: "id",
            title: "ID",
            width: 48,
            hideInSearch: true,
          },
          {
            title: "权限名称",
            dataIndex: "authName",
            hideInSearch: true,
          },
          {
            title: "权限地址",
            dataIndex: "auth",
            hideInSearch: true,
          },
          
          {
            title: '操作',
            valueType: 'option',
            align: 'center',
            render: (text, record, _, action) => (
              <Space>
                  <Button type="primary" onClick={()=>this.addAuthToMenu(record)}>添加</Button>
              </Space>
            ),
          },
        ],
      };
    }
    ref = React.createRef()
    popRef = React.createRef()
    handlePrev = () => {
        let page = this.state.page;
        this.setState({
          page: page < 0 ? 0 : --page
        })
      }
      handleNext = () => {
        let page = this.state.page;
        this.setState({
          page: ++page,
        });
      }
      handleKeywordsChange(e){
          this.setState({
            keyword:e.target.value
          },()=>{
            this.popRef.current.reload()
          })
      }
      addAuthToMenu(data){
        React.fetch({
            url: ADD_MENU_AUTH,
            method: "post",
            data: {
                authIdList:[data.id],
                // menuId:Number(this.state.menuId),
                menuId:this.state.menuId,
            },
          }).then(res=>{
              if(res){
                message.success("添加成功！");
                this.ref.current.reload()
              }
              
          });
      }
      handleDeleteAuth(data){
        React.fetch({
            url: DELETE_MENU_AUTH,
            method: "post",
            data: {
                authIdList:[data.authId],
                menuId:Number(this.state.menuId),
            },
          }).then(res=>{
              if(res){
                message.success("删除成功！");
                this.ref.current.reload()
              }
              
          });
      }
    render() {
        return (
          <div className="page-auth-list">
            <ProTable
              actionRef={this.ref}
              columns={this.state.columns}
              request={async (params = {}, sort, filter) => {
                const res = await React.fetch({
                  url: GET_MENU_AUTH_LIST,
                  method: "post",
                  data: {
                    id: this.state.menuId,
                  },
                });
                return {
                  data: res.data,
                  success: true,
                };
              }}
              rowKey="id"
              search={false}
              pagination={false}
              params={{
                page: this.state.page,
              }}
              toolBarRender={(action) => {
                return [
                  <Button
                    key="button"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.setState({ modalVisible: true })}
                  >
                    添加菜单权限
                  </Button>,
                ];
              }}
            />

            <Modal
              title="添加权限"
              footer={null}
              width={1000}
              visible={this.state.modalVisible}
              onCancel={() => this.setState({ modalVisible: false })}
            >
                <Input className="auth-search" value={this.state.keyword} placeholder="请输入关键字"   onChange={(e)=>this.handleKeywordsChange(e)}/>
              <ProTable
                actionRef={this.popRef}
                columns={this.state.popColums}
                request={async (params = {}, sort, filter) => {
                  const res = await React.fetch({
                    url: GET_AUTH_LIST,
                    method: "post",
                    data: {
                      page: params.page,
                      size: 15,
                      keyword:this.state.keyword
                    },
                  });
                  return {
                    data: res.data,
                    success: true,
                  };
                }}
                rowKey="id"
                search={false}
                pagination={false}
                params={{
                  page: this.state.page,
                }}
                options={false}
              />
              <div className="page">
                <Button
                  type="link"
                  className="prev"
                  disabled={this.state.page === 1}
                  onClick={this.handlePrev}
                >
                  上一页
                </Button>
                <Button type="link" className="next" onClick={this.handleNext}>
                  下一页
                </Button>
              </div>
            </Modal>
          </div>
        );
    }
}
export default PageAuthList;