import React, {Suspense} from "react"
import {HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Login from "../views/login/login.js";
import Container from "../views/home/home";
import {connect} from "react-redux";
import LoginSuccess from "../views/container/loginSuccess";
import AdPositionList from "../views/container/AdvertisementManage";
import AdvertisementList from "../views/container/AdvertisementList";
import MessageList from "../views/container/MessgeManage";
import MessageView from "../views/container/MessgeManage/MessageView";
import OrderDetail from "../views/container/orderDetail";
import SendRecord from "../views/container/SendRecord";
import ResumeManage from "../views/container/ResumeManage";
import ResumeDetail from "../views/container/ResumeManage/ResumeDetail";
import EmployeeManage from "../views/container/EmployeeManage";
import JobManage from "../views/container/JobManage";
import JobEditor from "../views/container/JobManage/JobEditor";
import JobView from "../views/container/JobManage/JobView";
import CompanyManage from "../views/container/CompanyManage";
import InfoSetting from "../views/container/CompanyManage/InfoSetting";
import SystemInfo from "../views/container/SystemInfo";
import UserAuth from "../views/container/UserAuth";
import AuthList from "../views/container/AuthList";
import PageAuthList from "../views/container/PageAuthList";
import MenuList from "../views/container/MenuList";
import RoleAuth from "../views/container/RoleAuth";
import TagStore from "../views/container/TagStore";
import Logs from "../views/container/Logs";
import NoMatch from "../views/container/404";
import CollectJobList from "../views/container/CollectJobList";
import CollectJobVerify from "../views/container/CollectJobVerify";
import SwitchCollectJob from "../views/container/SwitchCollectJob";
import CollectJobDetail from "../views/container/CollectJobDetail";
import BannerManage from "../views/container/BannerManage";
import JobCategoryManage from "../views/container/JobCategoryManage";
import Placeholder from "../views/container/Placeholder";
import AuthorizationAccount from "../views/container/AuthorizationAccount";
import ResumeComplete from "../views/container/ResumeComplete";
import DeliveryCity from "../views/container/DeliveryCity";
import DeliveryJobCategory from "../views/container/DeliveryJobCategory";
import PublishJobMount from "../views/container/PublishJobMount";
import JobCityInfo from "../views/container/JobCityInfo";
import PublishJobCategory from "../views/container/PublishJobCategory";
import ResumeDeliveryMount from "../views/container/ResumeDeliveryMount";
import RankingJob from "../views/container/RankingJob";
import AttachmentManage from "../views/container/Attachment";
import SensitiveWords from "../views/container/SensitiveWords";
import childJobCategoryManage from "../views/container/JobCategoryManage/components/childJobCategory";
import CityService from "../views/container/CityService";
import BIJobContainer from "../views/container/BIJob";
import BICompanyContainer from "../views/container/BICompany";
import OperationLogs from "../views/container/OperationLogs/OperationLogs";
import CompanyIdentity from "../views/container/CompanyIdentity/CompanyIdentity";
import CompanySign from "../views/container/CompanySign/CompanySign";
import IntentionDetail from "../views/container/IntentionDetail";
import IntentionList from "../views/container/IntentionList";
import HomeImageConfig from "../views/container/HomeConfig/Image";
import HomeIconConfig from "../views/container/HomeConfig/Icon";
import HomeIconHistory from "../views/container/HomeConfig/History";
import VideoTipList from "../views/container/VideoTipList";
import EvaluateList from "../views/container/EvaluateList";

function IRouter(props) {
    return (
        <Router>
            <Suspense fallback={<div style={{"textAlign": "center"}}>加载中...</div>}>
            </Suspense>
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route path="/" render={() => {
                    const isLogin = props.token;
                    return isLogin ? (<Container>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/login"/>
                            </Route>

                            <Route path="/evaluateList" component={EvaluateList}/>
                            <Route path="/systemInfo" component={SystemInfo}/>
                            <Route path="/userAuth" component={UserAuth}/>
                            <Route path="/roleAuth" component={RoleAuth}/>
                            <Route path="/authList" component={AuthList}/>
                            <Route path="/pageAuthList" component={PageAuthList}/>
                            <Route path="/menuList" component={MenuList}/>
                            <Route path="/tagsStore" component={TagStore}/>
                            <Route path="/logs" component={Logs}/>
                            <Route path="/companyManage" component={CompanyManage}/>
                            <Route path="/infoSetting" component={InfoSetting}/>
                            <Route path="/jobManage" component={JobManage}/>
                            <Route path="/jobSetting" component={JobEditor}/>
                            <Route path="/jobView" component={JobView}/>
                            <Route path="/employeeManage" component={EmployeeManage}/>
                            <Route path="/resumeManage" component={ResumeManage}/>
                            <Route path="/resumeDetail" component={ResumeDetail}/>
                            <Route path="/sendRecord" component={SendRecord}/>
                            <Route path="/orderDetails" component={OrderDetail}/>
                            <Route path="/messageList" component={MessageList}/>
                            <Route path="/messageView" component={MessageView}/>
                            <Route path="/adPositionList" component={AdPositionList}/>
                            <Route path="/adList" component={AdvertisementList}/>
                            <Route path="/loginSuccess" component={LoginSuccess}/>
                            <Route path="/collectJobList" component={CollectJobList}/>
                            <Route path="/collectJobVerify" component={CollectJobVerify}/>
                            <Route path="/switchCollectJob" component={SwitchCollectJob}/>
                            <Route path="/collectJobEdite" component={CollectJobDetail}/>
                            <Route path="/bannerManage" component={BannerManage}/>
                            <Route path="/jobCategoryManage" component={JobCategoryManage}/>
                            <Route path="/childJobCategoryManage" component={childJobCategoryManage}/>
                            <Route path="/placeholder" component={Placeholder}/>
                            <Route path="/operationLogs" component={OperationLogs}/>
                            <Route path="/companyIdentity" component={CompanyIdentity}/>
                            <Route path="/companySign" component={CompanySign}/>
                            <Route path="/intentionList" component={IntentionList}/>
                            <Route path="/intentionDetail" component={IntentionDetail}/>
                            <Route path="/homeImageConfig" component={HomeImageConfig}/>
                            <Route path="/homeIconConfig" component={HomeIconConfig}/>
                            <Route path="/homeIconHistory" component={HomeIconHistory}/>
                            <Route path="/videoTipList" component={VideoTipList}/>
                            <Route path="/BI-job" render={() =>
                                <BIJobContainer>
                                    <Route exact path="/BI-job/authorizationAccount" component={AuthorizationAccount}/>
                                    <Route path="/BI-job/resumeComplete" component={ResumeComplete}/>
                                    <Route path="/BI-job/resumeDeliveryMount" component={ResumeDeliveryMount}/>
                                    <Route path="/BI-job/deliveryCity" component={DeliveryCity}/>
                                    <Route path="/BI-job/deliveryJobCategory" component={DeliveryJobCategory}/>
                                    <Route path="/BI-job/rankingJob" component={RankingJob}/>
                                </BIJobContainer>
                            }/>

                            <Route path="/BI-company" render={() =>
                                <BICompanyContainer>
                                    <Route path="/BI-company/publishJobMount" component={PublishJobMount}/>
                                    <Route path="/BI-company/jobCityInfo" component={JobCityInfo}/>
                                    <Route path="/BI-company/publishJobCategory" component={PublishJobCategory}/>
                                </BICompanyContainer>
                            }/>
                            <Route path="/attachmentManage" component={AttachmentManage}/>
                            <Route path="/sensitiveWords" component={SensitiveWords}/>
                            <Route path="/cityService" component={CityService}/>
                            <Route component={NoMatch}/>
                        </Switch>
                    </Container>) : <Redirect to={{pathname: '/login',}}/>
                }}/>
            </Switch>

        </Router>
    )
}

const mapStateToProps = state => {
    return {
        token: state.login.token
    }
}

export default connect(mapStateToProps)(IRouter)