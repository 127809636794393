import React, {Component} from "react";
import {GET_DELIVERY_CATEGORY_DATA, GET_DELIVERY_CATEGORY_INDUSTRY_DATA} from "../../../service";
import moment from 'moment';
import {Checkbox, DatePicker, Space} from 'antd';
// 引入 ECharts 主模块
import * as echarts from 'echarts/lib/echarts'
// 引入饼状图
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';


import "./index.less";
import ProTable from "@ant-design/pro-table";
import mapBack from "../../../assets/image/map_back.png";

const CheckboxGroup = Checkbox.Group;

class DeliveryCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      date: [],
      jobType: [],
      columns: [
        {
          title: "投递行业",
          dataIndex: "categoryName"
        },
        {
          title: "支付宝投递数",
          dataIndex: "alipayCount"
        },
        {
          title: "微信投递数",
          dataIndex: "weixinCount"
        }
      ],
      tableData: [],
      deepColumns: [
        {
          title: "投递职位",
          dataIndex: "categoryName"
        },
        {
          title: "支付宝投递数",
          dataIndex: "alipayCount"
        },
        {
          title: "微信投递数",
          dataIndex: "weixinCount"
        }
      ],
      deepTableData: [],
      echartOptions: {
        title: {
          text: '投递职位行业前10名分布图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      echartDeepOptions: {
        title: {
          text: '“1级行业名称”前10名职位图',
          left: 'center'
        },
        tooltip: {
          show: false
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      showDeepData: false,
      echartContainer: '',
    };
  }

  componentDidMount = async () => {
    this.initPage();
  }
  initPage = async () => {
    let result = await this.getData();
    if (result.code === 200) {
      this.initEchart(result.data);
      this.initTable(result.data);
    }
  }

  initDeepPage = async () => {
    let result = await this.getDeepData(this.industryId);
    if (result.code === 200) {
      this.initDeepEchart(result.data);
      this.initDeepTable(result.data);
    }
  }
  getData = () => {
    return React.fetch({
      url: GET_DELIVERY_CATEGORY_DATA,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    })
  }
  getDeepData = (category) => {
    return React.fetch({
      url: GET_DELIVERY_CATEGORY_INDUSTRY_DATA,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType,
        jobCategoryIndustry: category
      },
    })
  }
  initTable= (data) => {
    this.setState({
      tableData: data.map(item => ({
        id: item.jobCategory,
        categoryName: item.categoryName,
        alipayCount: item.alipayCount,
        weixinCount: item.weixinCount
      }))
    })
  }
  initDeepTable = (data) => {
    this.setState({
      deepTableData: data.map(item => ({
        id: item.jobCategory,
        categoryName: item.categoryName,
        alipayCount: item.alipayCount,
        weixinCount: item.weixinCount
      }))
    })
  }

  initEchart = (data) => {
    let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
    echartOptions.series[0].data = data.map(item => ({
      name: item.categoryName || '',
      value: item.applyCount,
      category: item.jobCategory
    }))
    this.setState({
      echartOptions: echartOptions
    }, () => {
      if (this.state.echartContainer) {
        this.state.echartContainer.dispose()
      }
      let myChart = echarts.init(document.getElementById('echart-container'));
      myChart.setOption(this.state.echartOptions)
      this.setState({echartContainer: myChart});

      myChart.on('click', async (params) => {
        let echartOptions = JSON.parse(JSON.stringify(this.state.echartDeepOptions));
        echartOptions.title.text = params.data.name + "前10名职位图";

        this.industryId = params.data.category;
        this.setState({
          showDeepData: true,
          echartDeepOptions: echartOptions
        },() => {
          myChart.dispose();
          this.initDeepPage();
        });
      });
    })
  }
  initDeepEchart = (data) => {
    console.log(data)
    let echartOptions = JSON.parse(JSON.stringify(this.state.echartDeepOptions));
    echartOptions.series[0].data = data.map(item => ({
      name: item.categoryName || '',
      value: item.applyCount,
      category: item.jobCategory
    }));

    this.setState({
      echartDeepOptions: echartOptions
    });
    if (this.state.echartContainer) {
      this.state.echartContainer.dispose()
    }
    let myChart = echarts.init(document.getElementById('echart-container'));
    myChart.setOption(this.state.echartDeepOptions)
    this.setState({echartContainer: myChart});
  }


  setDate = (val) => {

    this.setState({
      date: val,
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      if(this.state.showDeepData){
        this.initDeepPage();
      }else{
        this.initPage();
      }
    })
  }

  render() {
    const {date, jobType, columns, tableData,showDeepData,deepColumns,deepTableData} = this.state;
    return (
      <div className="delivery-city">
        <div className="search">
          <Space>
            <DatePicker.RangePicker allowClear
                                    onChange={(val) => this.setDate(val)}
                                    value={date}/>

            <CheckboxGroup options={[
              {label: "全职", value: 1},
              // {label: "线上兼职", value: 2},
              // {label: "线下兼职", value: 3}
            ]} value={jobType} onChange={(val) => {
              this.setState({
                jobType: val
              }, () => {
                if(this.state.showDeepData){
                  this.initDeepPage();
                }else{
                  this.initPage();
                }
              })
            }}/>
          </Space>
        </div>
        <div className="content">
          {
            showDeepData ? <div onClick={() => {
              this.setState({
                showDeepData: false
              }, () => this.initPage())
            }} className="map-back">
              <div className="back">
                <img src={mapBack} alt=""/>
                <span>返回上级</span>
              </div>
            </div> : null
          }
          <div className="table">
            {
              showDeepData?<ProTable
                columns={deepColumns}
                dataSource={deepTableData}
                bordered
                rowKey="id"
                pagination={false}
                search={false}
                toolBarRender={false}
              />:<ProTable
                columns={columns}
                dataSource={tableData}
                bordered
                rowKey="id"
                pagination={false}
                search={false}
                toolBarRender={false}
              />
            }

          </div>
          <div id="echart-container"/>
        </div>
      </div>
    );
  }
}

export default DeliveryCity;
