import './index.less'
import React, {useState, useRef} from "react";
import ProTable from '@ant-design/pro-table';
import {GET_COLLECTJOB_LIST, DELETE_COLLECTJOB} from "../../../service";
import {Button, Form, Input, Select, Checkbox, Popconfirm} from 'antd';

const {Option} = Select;


function CollectJobList(props) {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      ellipsis: true,
    },
    {
      title: "职位名称",
      dataIndex: "title",
      ellipsis: true,
    },
    {
      title: "采集渠道",
      ellipsis: true,
      render: (_, record) => {
        return record.spiderPlatform.desc;
      },
    },
    {
      title: "企业名称",
      dataIndex: "companyName",
      ellipsis: true,
    },
    {
      title: "实效性",
      dataIndex: "jobTime",
      ellipsis: true,
    },
    {
      title: "职位类别",
      ellipsis: true,
      render: (_, record) => {
        return record.jobType.desc;
      },
    },
    {
      title: "职位城市",
      dataIndex: "cityName",
      ellipsis: true,
      fieldProps: {
        placeholder: "选择状态",
        options: [
          {label: "报名成功", value: 1000},
          {label: "取消报名", value: 1200},
          {label: "已录用", value: 1400},
          {label: "不合适", value: 1500},
          {label: "取消录用", value: 1600},
        ],
      },
    },
    {
      title: "审核状态",
      ellipsis: true,
      render: (_, record) => {
        return record.auditState.desc;
      },
    },
    {
      title: "操作人",
      dataIndex: "updatedBy",
      ellipsis: true,
    },
    {
      title: "采集时间",
      dataIndex: "createdTime",
      ellipsis: true,
    },
    {
      title: "操作",
      ellipsis: true,
      width: 190,
      render: (text, record) => (
        <div className="operate" onClick={(e) => e.stopPropagation()}>
          <Button type="primary" disabled={record.auditState.code !== 0} onClick={() => handleEditor(record)}>
            编辑
          </Button>
          <Popconfirm
            title="你确定要删除该数据吗?"
            onConfirm={() => deleteRecord(record)}
            okText="确认"
            cancelText="取消"
          >
            <Button type="danger">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleEditor = (record) => {
    props.history.push("/switchCollectJob?id=" + record.id)
  }

  const deleteRecord = (record) => {
    React.fetch({
      url: DELETE_COLLECTJOB,
      method: "post",
      data: {
        id: record.id
      },
    }).then(res => {
      tableRef.current.reload();
    })
  }
  const [lookUpdate, setLookUpdate] = useState('0');
  const [spiderTime, setSpiderTime] = useState('0');
  const [auditState, setAuditState] = useState('');
  const [ctiyName, setCtiyName] = useState('');
  const [jobType, setJobType] = useState('');
  const [spiderPlatform, setSpiderPlatform] = useState('');
  const [page, setPage] = useState(1)

  function handlePrev() {
    let current=page
    setPage(current < 0 ? 0 : --current)
  }

  function handleNext() {
    let current=page
    setPage(++current)
  }

  const tableRef = useRef()
  return (<div className="collect-job-list">
    <div className="filter-box">
      <Form layout='inline'>
        <Form.Item label="职位所在地区：">
          <Input onChange={(e) => {
            setCtiyName(e.target.value)
          }}/>
        </Form.Item>
        <Form.Item label="职位时效性：">
          <Select defaultActiveFirstOption defaultValue={'0'} style={{width: 120}} onChange={val => setSpiderTime(val)}>
            <Option value="0">全部</Option>
            <Option value="1">3天内</Option>
            <Option value="2">7天内</Option>
            <Option value="3">一个月内</Option>
            <Option value="4">3个月内</Option>
          </Select>
        </Form.Item>
        <Form.Item label="职位类别：">
          <Select defaultActiveFirstOption defaultValue={''} style={{width: 120}} onChange={val => setJobType(val)}>
            <Option value="">全部</Option>
            <Option value="1">全职</Option>
            <Option value="2">线上兼职</Option>
            <Option value="3">线下兼职</Option>
            <Option value="4">任务</Option>
          </Select>
        </Form.Item>
        <Form.Item label="采集渠道：">
          <Select defaultActiveFirstOption defaultValue={''} style={{width: 120}}
                  onChange={val => setSpiderPlatform(val)}>
            <Option value="">全部</Option>
            <Option value="1">兼职猫</Option>
          </Select>
        </Form.Item>
        <Form.Item label="编辑状态：">
          <Select defaultActiveFirstOption defaultValue={''} style={{width: 120}} onChange={val => setAuditState(val)}>
            <Option value="">全部</Option>
            <Option value="0">待审核</Option>
            <Option value="1">已审核</Option>
            <Option value="2">审核不通过</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => tableRef.current.reload()}>搜索</Button>
        </Form.Item>
      </Form>
      <div><Checkbox onChange={(e) => {
        e.target.checked ? setLookUpdate(1) : setLookUpdate(0)
      }}>只看自己编辑过的职位</Checkbox></div>
    </div>
    <ProTable
      actionRef={tableRef}
      columns={columns}
      request={async (params = {}, sort, filter) => {
        const res = await React.fetch({
          url: GET_COLLECTJOB_LIST,
          method: "post",
          data: {
            page: params.page,
            size: 20,
            lookUpdate: lookUpdate,
            spiderTime: spiderTime,
            spiderPlatform: spiderPlatform,
            auditState: auditState,
            ctiyName: ctiyName,
            jobType: jobType

          }
        })
        return {
          data: res.data,
          success: true,
        }
      }}
      rowKey="id"
      search={false}
      pagination={false}
      params={{
        page: page
      }}
    />
    <div className="page">
      <Button type="link" className="prev" disabled={page === 1} onClick={handlePrev}>上一页</Button>
      <Button type="link" className="next" onClick={handleNext}>下一页</Button>
    </div>
  </div>)
}

export default CollectJobList;