import React, {Component} from "react";
import ProForm, {
  ProFormText,
  ProFormDatePicker,
  ProFormTimePicker,
  ProFormSelect,
  ProFormTextArea,
  ProFormCheckbox,
  ProFormRadio,
  ProFormGroup,
  ProFormDependency,
  ProFormList
} from '@ant-design/pro-form';
import {Form, Cascader} from "antd";
import AddressCascader from "../../../components/Address/Address";
import {connect} from "react-redux";
import ProCard from '@ant-design/pro-card';
import {
  GET_JOB_CATEGORY_ALL,
  GET_WELFARE_OPTION,
  GET_REQUIRE_OPTION,
  GET_JOB_INFO
} from "../../../service";
import moment from 'moment';
import "./jobEditor.less";
import {getQueryVariable} from "../../../utils";

class JobEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryOptions: [],
      requireOptions: [],
      welfareOptions: [],
      buttonLoading: false,

    }
  }

  ref = React.createRef();

  //cid = getQueryVariable(this.props.location.search, "cid") || "";
  //aid = getQueryVariable(this.props.location.search, "aid") || "";
  //type = getQueryVariable(this.props.location.search, "type");
  id = getQueryVariable(this.props.location.search, "id") || "";
  jobType = getQueryVariable(this.props.location.search, "jobType") || "";
  jId = "";

  locationCode = "";
  provinceId = "";
  cityId = "";
  areaId = ""

  async componentDidMount() {
    await this.getCategoryOptions(getQueryVariable(this.props.location.search, "jobType") ? getQueryVariable(this.props.location.search, "jobType") : 1);
    this.getJobInfo(this.id)
  }

  formValueChange = (values) => {
    const key = Object.keys(values)[0];
    const value = values[key];
    if (key === 'type') {
      this.getCategoryOptions(value);
      this.getRequireOptions(value);
      this.getWelfareOptions(value);
    }
    if (key === "address") {
      this.locationCode = value.slice(-1)[0].code;
      this.provinceId = value[0].value;
      this.cityId = [1, 2, 3, 4].indexOf(value[0].value) >= 0 ? value[0].value : value[1].value;
      this.areaId = value.slice(-1)[0].value;
    }
  }

  getCategoryOptions = type => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_JOB_CATEGORY_ALL,
        method: "post",
        data: {
          jobType: type
        }
      }).then(res => {
        this.setState({
          categoryOptions: res.data
        })
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }


  getJobInfo = id => {
    React.fetch({
      url: GET_JOB_INFO,
      method: "post",
      data: {
        id: id
      }
    }).then(res => {
      if (res.code === 200) {
        const {job, jobFull, jobPartOffline, jobPartOnline} = res.data;
        let type = 1;
        if (jobFull && !jobPartOffline && !jobPartOnline) {
          type = 1
        }
        if (!jobFull && jobPartOnline && !jobPartOffline) {
          type = 2
        }
        if (!jobFull && !jobPartOnline && jobPartOffline) {
          type = 3
        }
        this.getRequireOptions(type);
        this.getWelfareOptions(type);
        if (type === 1) {
          this.ref.current.setFieldsValue({
            type,
            workType: this.getCategory(this.state.categoryOptions, job.jobCategory),
            name: job.title,
            describe: job.content,
            qty: job.needCount,
            zhimaJobType: job.zhimaJobType && job.zhimaJobType.code,
            contact: job.contactMan,
            mobile: job.contactPhone,
            salaryMin: job.salaryMin,
            salaryMax: job.salaryMax,
            recruitingType: job.recruitingType?.code,
            recruitingEnterprise: job.recruitingEnterprise,
            unit: job.salaryUnit && job.salaryUnit.code,
            payWay: job.settlementType && job.settlementType.code,
            welfare: jobFull.welfareTags && jobFull.welfareTags.split(",").map(item => Number(item)),
            address: [1, 2, 3, 4].indexOf(jobFull.provinceId) >= 0 ? [jobFull.provinceId || "", jobFull.areaId || ""] : [jobFull.provinceId || "", jobFull.cityId || "", jobFull.areaId || ""],
            street: jobFull.address,
            require: jobFull.requireTags && jobFull.requireTags.split(",").map(item => Number(item)),
            education: jobFull.requireEducation && jobFull.requireEducation.code,
            major: jobFull.requireSpecialty
          })
        } else if (type === 2) {
          this.ref.current.setFieldsValue({
            type,
            workType: this.getCategory(this.state.categoryOptions, job.jobCategory),
            name: job.title,
            describe: job.content,
            qty: job.needCount,
            zhimaJobType: job.zhimaJobType?.code,
            contact: job.contactMan,
            mobile: job.contactPhone,
            salaryMin: job.salaryMin,
            salaryMax: job.salaryMax,
            recruitingType: job.recruitingType?.code,
            recruitingEnterprise: job.recruitingEnterprise,
            unit: job.salaryUnit?.code,
            payWay: job.settlementType && job.settlementType.code,
            welfare: jobPartOnline.welfareTags?.split(",").map(item => Number(item)),
            require: jobPartOnline.requireTags?.split(",").map(item => Number(item)),
            education: jobPartOnline.requireEducation?.code,
            major: jobPartOnline.requireSpecialty,
            partPayType: jobPartOnline.salaryType,
            category: jobPartOnline.workType?.code,
            timeRequire: jobPartOnline.onlineLong?.requireWorkTime,
            atLeast: jobPartOnline.onlineLong?.requireWorkWeek,
            workDays: jobPartOnline.onlineShort?.requireWorkDate?.split(",").map(item => ({dates: item})),
            duration: jobPartOnline.workType?.code === 1 ? jobPartOnline.onlineLong?.requireTimeInterval?.split(",").map(item => ({timeRange: item?.split(/[～~]/g).map(item => moment(item, "HH:mm"))})) : jobPartOnline.workType?.code === 2 ? jobPartOnline.onlineShort?.requireTimeInterval?.split(",").map(item => ({timeRange: item?.split(/[～~]/g).map(item => moment(item, "HH:mm"))})) : [],
          })
        } else if (type === 3) {
          this.ref.current.setFieldsValue({
            type,
            workType: this.getCategory(this.state.categoryOptions, job.jobCategory),
            name: job.title,
            describe: job.content,
            qty: job.needCount,
            contact: job.contactMan,
            mobile: job.contactPhone,
            salaryMin: job.salaryMin,
            salaryMax: job.salaryMax,
            recruitingType: job.recruitingType?.code,
            recruitingEnterprise: job.recruitingEnterprise,
            unit: job.salaryUnit && job.salaryUnit.code,
            payWay: job.settlementType && job.settlementType.code,
            welfare: jobPartOffline.welfareTags && jobPartOffline.welfareTags.split(",").map(item => Number(item)),
            address: [1, 2, 3, 4].indexOf(jobPartOffline.provinceId) >= 0 ? [jobPartOffline.provinceId || "", jobPartOffline.areaId || ""] : [jobPartOffline.provinceId || "", jobPartOffline.cityId || "", jobPartOffline.areaId || ""],
            street: jobPartOffline.address,
            require: jobPartOffline.requireTags && jobPartOffline.requireTags.split(",").map(item => Number(item)),
            education: jobPartOffline.requireEducation && jobPartOffline.requireEducation.code,
            partPayType: jobPartOffline.salaryType,
            category: jobPartOffline.workType?.code,
            timeRequire: jobPartOffline.offlineLong?.requireWorkTime,
            atLeast: jobPartOffline.offlineLong?.requireWorkWeek,
            workDays: jobPartOffline.offlineShort?.requireWorkDate?.split(",").map(item => ({dates: item})),
            duration: jobPartOffline.workType?.code === 1 ? jobPartOffline.offlineLong?.requireTimeInterval?.split(",").map(item => ({timeRange: item?.split(/[～~]/g).map(item => moment(item, "HH:mm"))})) : jobPartOffline.workType?.code === 2 ? jobPartOffline.offlineShort?.requireTimeInterval?.split(",").map(item => ({timeRange: item?.split(/[～~]/g).map(item => moment(item, "HH:mm"))})) : [],
            tail: this.getHeightValue(jobPartOffline.requireHeightMin, jobPartOffline.requireHeightMax)
          })
        }
      }
    })
  }

  getCategory = (data, target) => {
    let temp = [];
    let callback = function (nowArr, target) {     //先定义个函数寻找子节点位置 找到后 再找改节点父元素位置 以此类推
      for (let i = 0; i < nowArr.length; i++) {
        let item = nowArr[i];
        if (item.postCode === target) {
          temp.unshift(item.postCode);
          callback(data, item.parentPostCode);     //pid 父级ID
          break;
        } else {
          if (item.childDtoList) {
            callback(item.childDtoList, target);
          }
        }
      }
    };
    callback(data, target);
    return temp;  //最后返回
  }

  getRequireOptions = type => {
    React.fetch({
      url: GET_REQUIRE_OPTION,
      method: "post",
      data: {
        jobType: type
      }
    }).then(res => {
      this.setState({
        requireOptions: res.data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    })
  }

  getWelfareOptions = type => {
    React.fetch({
      url: GET_WELFARE_OPTION,
      method: "post",
      data: {
        jobType: type
      }
    }).then(res => {
      this.setState({
        welfareOptions: res.data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    })
  }


  getHeight = (value) => {
    switch (value) {
      case 0:
        return [0, 0];
      case 1:
        return [160, 150]
      case 2:
        return [170, 160]
      case 3:
        return [180, 170]
      case 4:
        return [180, 0]
      default:
        return [0, 0]
    }
  }
  getHeightValue = (min, max) => {
    if (min === 150 && max === 160) {
      return 1
    } else if (min === 160 && max === 170) {
      return 2
    } else if (min === 170 && max === 180) {
      return 3
    } else if (min === 180 && max === 0) {
      return 4
    }
    return 0
  }


  render() {
    return (<div className="job-editor-page">
      <ProForm
        name="save"
        title="信息设置"
        submitter={{
          render: false,
        }}
        disabled ={true}
        initialValues={{
          type: 1,
          category: 0,
          recruitingType: 0
        }}
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        layout="horizontal"
        formRef={this.ref}
      >
        {/*基本信息*/}
        <ProCard
          title="基本信息"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormSelect
            label="岗位类型"
            name="type"
            disabled
            options={this.props.jobTypeOptions}
          />

          <Form.Item
            name="workType"
            label="岗位分类"
          >
            <Cascader
              disabled
              options={this.state.categoryOptions}
              fieldNames={{label: 'postName', value: 'postCode', children: 'childDtoList'}}
              placeholder="请选择"/>
          </Form.Item>

          <ProFormText
            name="name"
            label="职位名称"
            disabled
            placeholder="请输入"
          />
          <ProFormTextArea
            name="describe"
            label="工作描述"
            disabled
            placeholder="请输入"
          />
          <ProFormText
            name="qty"
            label="招聘人数"
            disabled
            placeholder="请输入"
          />
          <ProFormRadio.Group
            name="recruitingType"
            label="招聘类型"
            disabled
            options={[
              {
                label: '直招',
                value: 0,
              },
              {
                label: '代招',
                value: 1,
              },
            ]}
          />
          <ProFormDependency name={['recruitingType']}>
            {
              ({recruitingType}) => {
                if (recruitingType === 1) {
                  return <ProFormText
                    name="recruitingEnterprise"
                    label="招聘企业"
                    placeholder="请输入"
                  />
                }
                return null
              }
            }
          </ProFormDependency>
          <ProFormText
            name="contact"
            label="联系人"
            disabled
            placeholder="请输入"
          />
          <ProFormText
            name="mobile"
            label="联系方式"
            disabled
            placeholder="请输入"
          />
        </ProCard>
        {/*薪资报酬*/}
        <ProCard
          title="薪资报酬"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormGroup size={0}>
            <ProFormText
              labelCol={{span: 10}}
              wrapperCol={{span: 14}}
              name="salaryMin"
              label="薪资"
              disabled
              placeholder="请输入薪资最小值"
            />
            <ProFormText
              labelCol={{span: 0}}
              wrapperCol={{span: 24}}
              name="salaryMax"
              label=""
              disabled
              placeholder="请输入薪资最大值"
            />
            <ProFormSelect
              labelCol={{span: 0}}
              wrapperCol={{span: 24}}
              name="unit"
              disabled
              placeholder="请选择薪资单位"
              options={[
                {value: 1, label: "元/小时",},
                {value: 2, label: '元/天'},
                {value: 3, label: '元/周'},
                {value: 4, label: '元/月'},
                {value: 5, label: '元/次'},
                {value: 6, label: '其他'},
              ]}/>
          </ProFormGroup>
          <ProFormSelect
            label="结算周期"
            disabled
            name="payWay"
            options={[
              {value: 1, label: "日结",},
              {value: 2, label: '周结'},
              {value: 3, label: '月结'},
              {value: 4, label: '完结'},
              {value: 0, label: '其他'},
              {value: -1, label: '不限'},
            ]}
          />
          <ProFormCheckbox.Group
            name="welfare"
            disabled
            label="福利待遇"
            options={this.state.welfareOptions}
          />
        </ProCard>
        {/*岗位要求*/}
        <ProCard
          title="岗位要求"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormDependency name={['type']}>
            {
              ({type}) => {
                if (type === 2 || type === 3) {
                  return <ProFormRadio.Group
                    name="partPayType"
                    disabled
                    label="发薪方式"
                    options={[
                      {
                        label: '代发薪',
                        value: 1,
                      },
                      {
                        label: '自主发薪',
                        value: 2,
                      },
                    ]}
                  />
                }
                return null
              }
            }
          </ProFormDependency>

          <ProFormDependency name={['type']}>
            {
              ({type}) => {
                if (type === 1 || type === 3) {
                  return <>
                    <Form.Item
                      disabled
                      name="address"
                      label="企业地址"
                    >
                      <AddressCascader disabled defaultAdress={[this.provinceId, this.cityId, this.areaId]}/>
                    </Form.Item>
                    <ProFormText
                      disabled
                      name="street"
                      label="详细地址"
                      placeholder="请输入详细地址"
                    />
                  </>
                }
                return null
              }
            }
          </ProFormDependency>

          <ProFormCheckbox.Group
            name="require"
            label="上岗要求"
            disabled
            options={this.state.requireOptions}
          />
          <ProFormSelect
            disabled
            label="学历要求"
            name="education"
            options={this.props.educationOptions}
          />
          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type === 1) {
                return <ProFormText
                  name="major"
                  disabled
                  label="专业要求"
                  placeholder="请输入专业要求"
                />
              }
              return null
            }}
          </ProFormDependency>

          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type === 3) {
                return <ProFormSelect
                  label="身高要求"
                  disabled
                  name="tail"
                  options={[
                    {label: "不限", value: 0},
                    {label: "150-160", value: 1},
                    {label: "160-170", value: 2},
                    {label: "170-180", value: 3},
                    {label: "180+", value: 4},
                  ]}
                />
              }
              return null
            }}
          </ProFormDependency>
        </ProCard>

        {/*工作时间*/}
        {
          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type !== 1) {
                return <ProCard
                  title="工作时间"
                  bordered
                  headerBordered
                  collapsible
                  style={{
                    marginBottom: 16,
                    minWidth: 640,
                    maxWidth: '100%',
                  }}
                >
                  <ProFormRadio.Group
                    name="category"
                    label="工作种类"
                    disabled
                    options={[
                      {
                        label: '无要求',
                        value: 0,
                      },
                      {
                        label: '长招',
                        value: 1,
                      },
                      {
                        label: '短招',
                        value: 2,
                      },
                    ]}
                  />
                  <ProFormDependency name={['category']}>
                    {({category}) => {
                      if (category === 1) {
                        //长招
                        return (<>
                          <ProFormSelect
                            label="时间要求"
                            name="timeRequire"
                            disabled
                            options={[
                              {value: 1, label: "能工作一个月以上",},
                              {value: 2, label: '能工作二个月以上'},
                              {value: 3, label: '能工作三个月以上'},
                              {value: 4, label: '能工作四个月以上'},
                              {value: 5, label: '能工作五个月以上'},
                              {value: 6, label: '能工作六个月以上'},
                            ]}
                          />
                          <ProFormSelect
                            label="每周至少"
                            name="atLeast"
                            disabled
                            options={[
                              {value: 1, label: "一天",},
                              {value: 2, label: '二天'},
                              {value: 3, label: '三天'},
                              {value: 4, label: '四天'},
                              {value: 5, label: '五天'},
                              {value: 6, label: '六天'},
                              {value: 7, label: '七天'},
                            ]}
                          />
                        </>)
                      } else if (category === 2) {
                        //短招
                        return (
                          <ProFormList
                            label="上班日期"
                            name="workDays"
                            required={true}
                            wrapperCol={{span: 20, offset: 4}}
                            initialValue={[{date: []}]}
                            copyIconProps={false}
                            deleteIconProps={false}
                            creatorButtonProps={false}
                          >
                            <ProFormDatePicker
                              disabled
                              name="dates"
                              label=""/>
                          </ProFormList>
                        )
                      }
                    }}
                  </ProFormDependency>
                  <ProFormDependency name={['category']}>
                    {
                      ({category}) => {
                        if (category === 0) {
                          return null
                        } else {
                          return <ProFormList
                            name="duration"
                            label="上班时段"
                            initialValue={[{timeRange: []}]}
                            copyIconProps={false}
                            deleteIconProps={false}
                            creatorButtonProps={false}
                          >
                            <ProFormTimePicker.RangePicker
                              disabled
                              name="timeRange"
                              fieldProps={{
                                format: 'HH:mm',
                              }}
                              rules={[{required: true, message: "请选择上班时段"}]}/>
                          </ProFormList>
                        }
                      }
                    }
                  </ProFormDependency>
                </ProCard>
              }
              return null
            }}
          </ProFormDependency>
        }

      </ProForm>
      <div style={{height: '64px'}}/>
    </div>)
  }
}

const mapStateToProps = state => ({
  jobTypeOptions: state.global.jobTypeOptions,
  sexOptions: state.global.sexOptions,
  educationOptions: state.global.educationOptions
})
export default connect(mapStateToProps, null)(JobEditor)