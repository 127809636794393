import React from "react";
import IRouter from "./router";
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import './App.less';
import "./assets/iconfont/iconfont.less"

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <IRouter/>
      </div>
    </ConfigProvider>
  );
}

export default App;
