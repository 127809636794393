import React, {Component} from "react";
import ProForm, {
  ProFormText,
  ProFormDatePicker,
  ProFormTimePicker,
  ProFormSelect,
  ProFormTextArea,
  ProFormCheckbox,
  ProFormRadio,
  ProFormGroup,
  ProFormDependency,
  ProFormList
} from '@ant-design/pro-form';
import {Form, Cascader, message} from "antd";
import AddressCascader from "../../../components/Address/Address";
import {connect} from "react-redux";
import ProCard from '@ant-design/pro-card';
import {FooterToolbar} from "@ant-design/pro-layout";
import {
  GET_JOB_CATEGORY_ALL,
  GET_WELFARE_OPTION,
  GET_REQUIRE_OPTION,
  AGREE_COLLECTJOB_DETAIL,
  UPDATE_COLLECTJOB_DETAIL
} from "../../../service";
import "./index.less";
import {getQueryVariable} from "../../../utils";

class JobEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryOptions: [],
      requireOptions: [],
      welfareOptions: [],
      buttonLoading: false
    }
  }

  ref = React.createRef();

  cid = getQueryVariable(this.props.location.search, "cid") || "";
  aid = getQueryVariable(this.props.location.search, "aid") || "";
  type = getQueryVariable(this.props.location.search, "type");
  id = getQueryVariable(this.props.location.search, "id") || "";
  jId = "";

  locationCode = "";
  provinceId = "";
  cityId = "";
  areaId = ""

  async componentDidMount() {
    await this.getCategoryOptions(1);
    if(Number(this.type) === 1){
      this.getRequireOptions(1);
      this.getWelfareOptions(1);
    }

    this.getJobInfo(this.id)
  }

  formValueChange = (values) => {
    if (values[0].name[0] === 'type') {
      this.getCategoryOptions(values[0].value);
      this.getRequireOptions(values[0].value);
      this.getWelfareOptions(values[0].value);
    }
    if (values[0].name[0] === "address") {
      this.locationCode = values[0].value.slice(-1)[0].code;
      this.provinceId = values[0].value[0].value;
      this.cityId = [1, 2, 3, 4].indexOf(values[0].value[0].value) >= 0 ? values[0].value[0].value : values[0].value[1].value;
      this.areaId = values[0].value.slice(-1)[0].value;
    }
  }

  getCategoryOptions = type => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_JOB_CATEGORY_ALL,
        method: "post",
        data:{
          jobType:type
        }
      }).then(res => {
        this.setState({
          categoryOptions: res.data
        })
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }


  getJobInfo = id => {
    React.fetch({
      url: AGREE_COLLECTJOB_DETAIL,
      method: "post",
      data: {
        id: id
      }
    }).then(res => {
        console.log(res);
        const data = res.data;
      if (res.code === 200) {
        this.getRequireOptions(data.jobType.code);
        this.getWelfareOptions(data.jobType.code);
        this.ref.current.setFieldsValue({
            type:data.jobType.code,
            workType: this.getCategory(this.state.categoryOptions, data.jobCategory),
            name: data.title,
            describe: data.content,
            qty: data.needCount,
            zhimaJobType: data.zhimaJobType?.code,
            contact: data.contactMan,
            mobile: data.contactPhone,
            salary: data.salary,
            unit: data.salaryUnit?.code,
            payWay: data.settlementType && data.settlementType.code,
            welfare: data.welfareTags?.split(",").map(item => Number(item)),
            require: data.requireTags?.split(",").map(item => Number(item)),
            sex: data.sex?.code,
            education: data.requireEducation?.code,
            major: data.requireSpecialty,
            partPayType: data.salaryType,
            category: data.workType?.code,
            address: [1, 2, 3, 4].indexOf(data.provinceId) >= 0 ? [data.provinceId || "", data.areaId || ""] : [data.provinceId || "", data.cityId || "", data.areaId || ""],
            street: data.address,
            timeRequire: data.onlineLong?.requireWorkTime,
            atLeast: data.onlineLong?.requireWorkWeek,
            workDays: data.onlineShort?.requireWorkDate?.split(",").map(item => ({dates: item})),
            duration:data.requireTimeInterval,
          })
          this.cid = data.companyId;
          this.aid = data.companyAccountId;
          this.jId = data.id;
          this.locationCode = data.locationCode;
          this.provinceId = data.provinceId;
          this.cityId = data.cityId;
          this.areaId = data.areaId;
      }
    })
  }

  getCategory = (data, target) => {
    let callbackData = [];
    data.forEach(item => {
      if (item.postCode === target) {
        callbackData = [item.postCode]
      } else {
        item.childDtoList && item.childDtoList.forEach(_item => {
          if (_item.postCode === target) {
            callbackData = [item.postCode, _item.postCode]
          }
        })
      }
    })
    return callbackData
  }

  getRequireOptions = type => {
    React.fetch({
      url: GET_REQUIRE_OPTION,
      method: "post",
      data: {
        jobType: type
      }
    }).then(res => {
      this.setState({
        requireOptions: res.data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    })
  }

  getWelfareOptions = type => {
    React.fetch({
      url: GET_WELFARE_OPTION,
      method: "post",
      data: {
        jobType: type
      }
    }).then(res => {
      this.setState({
        welfareOptions: res.data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    })
  }

  handleSubmit = (values) => {
    this.setState({
      buttonLoading: true
    })
    if((!this.areaId||!this.provinceId||!this.cityId)&&Number(this.type) !== 2){
        message.error("企业地址填写不完整，请填写完整后提交");
        return ;
    }  
    let data = {
      id: getQueryVariable(this.props.location.search, "id") || "",
      title: values.name,
      provinceId: this.provinceId,
      cityId: this.cityId,
      areaId: this.areaId,
      locationCode: this.locationCode,
      address: values.street,
      jobCategory: values.workType && values.workType.slice(-1)[0],
      content: values.describe,
      needCount: values.qty,
      sex: values.sex,
      contactMan: values.contact,
      contactPhone: values.mobile,
      companyId: this.cid,
      companyAccountId: this.aid,
      salary: values.salary,
      salaryUnit: values.unit,
      settlementType: values.payWay,
      zhimaJobType: values.zhimaJobType,
      requireEducation: values.education,
      requireTags: values.require && values.require.toString(),
      welfareTags: values.welfare && values.welfare.toString(),
      salaryType: values.partPayType,
      workType: values.category,
      longForm:
        values.category === 1
          ? {
              requireWorkTime: values.timeRequire,
              requireWorkWeek: values.atLeast,
              requireTimeInterval:
                values.duration &&
                values.duration
                  .map((item) => {
                    return (
                      item &&
                      item.timeRange &&
                      item.timeRange
                        .map((_item) => {
                          return _item && _item.split(" ")[1];
                        })
                        .join("~")
                    );
                  })
                  .join(","),
            }
          : null, //长招
      shortForm:
        values.category === 2
          ? {
              requireWorkDate:
                values.workDays &&
                values.workDays
                  .map((item) => item && item.dates.split(" ")[0])
                  .join(","),
              requireTimeInterval:
                values.duration &&
                values.duration
                  .map((item) => {
                    return (
                      item &&
                      item.timeRange &&
                      item.timeRange
                        .map((_item) => {
                          return _item && _item.split(" ")[1];
                        })
                        .join("~")
                    );
                  })
                  .join(","),
            }
          : null,
    };
    if (Number(this.type) === 2) {
      data.jobForm.id = this.id;
      data.id = this.jId;
    }

    React.fetch({
      url: UPDATE_COLLECTJOB_DETAIL,
      method: "post",
      data
    }).then(res => {
      if (res.code === 200) {
        if (+this.type === 1) {
          message.success("发布成功！");
          this.ref.current.resetFields();
        } else {
          message.success("更新成功！");
        }
      }
      this.setState({
        buttonLoading: false
      })
    })
  }

  getHeight = (value) => {
    switch (value) {
      case 0:
        return [0, 0];
      case 1:
        return [160, 150]
      case 2:
        return [170, 160]
      case 3:
        return [180, 170]
      case 4:
        return [180, 0]
      default:
        return [0, 0]
    }
  }
  getHeightValue = (min, max) => {
    if(min === 150 && max === 160) {
      return  1
    }else if(min===160 && max === 170) {
      return 2
    }else if(min===170 && max === 180) {
      return 3
    }else if(min ===180 && max === 0){
      return 4
    }
    return 0
  }


  render() {
    return (<div className="collect-job-page">
      <ProForm
        name="save"
        title="信息设置"
        submitter={{
          render: (_, dom) => {
            return <FooterToolbar
              style={{width: 'calc(100% - 200px)', justifyContent: "center"}}
              children={dom}
            />
          },
          submitButtonProps: {
            loading: this.state.buttonLoading
          }
        }}
        initialValues={{
          type: 1,
          category: 0,
        }}
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        layout="horizontal"
        onFieldsChange={this.formValueChange}
        onFinish={(values) => this.handleSubmit(values)}
        formRef={this.ref}
      >
        {/*基本信息*/}
        <ProCard
          title="基本信息"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormSelect
            label="岗位类型"
            name="type"
            disabled={this.type === "2"}
            rules={[
              {
                required: true,
                message: "请选择岗位类型"
              },
            ]}
            options={this.props.jobTypeOptions}
          />

          <Form.Item
            name="workType"
            label="岗位分类"
            rules={[
              {
                required: true,
                message: "请选择岗位分类"
              },
            ]}
          >
            <Cascader
              options={this.state.categoryOptions}
              fieldNames={{label: 'postName', value: 'postCode', children: 'childDtoList'}}
              placeholder="请选择"/>
          </Form.Item>

          <ProFormText
            name="name"
            label="职位名称"
            placeholder="请输入"
            rules={[{required: true, message: "请填写名称"}]}
          />
          <ProFormTextArea
            name="describe"
            label="工作描述"
            placeholder="请输入"
            rules={[{required: true, message: "请填写工作描述"}]}
          />
          <ProFormText
            name="qty"
            label="招聘人数"
            placeholder="请输入"
            rules={[{required: true, message: "请填写招聘人数"}]}
          />
          <ProFormSelect
            label="芝麻工作证"
            name="zhimaJobType"
            rules={[{required: true, message: "请选择是否使用芝麻工作证"}]}
            options={[
              {value: 0, label: "芝麻优先"},
              {value: 1, label: '无需认证'},
              {value: 2, label: '需要认证'}
            ]}/>
          <ProFormText
            name="contact"
            label="联系人"
            placeholder="请输入"
            rules={[{required: true, message: "请填写联系人姓名"}]}
          />
          <ProFormText
            name="mobile"
            label="联系方式"
            placeholder="请输入"
            rules={[{required: true, message: "请填写联系方式"}]}
          />
        </ProCard>
        {/*薪资报酬*/}
        <ProCard
          title="薪资报酬"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormGroup size={0}>
            <ProFormText
              style={{width: "75%"}}
              labelCol={{span: 5}}
              wrapperCol={{span: 19}}
              name="salary"
              label="薪资"
              placeholder="请输入"
              rules={[{required: true, message: "请填写薪资"}]}
            />
            <ProFormSelect
              style={{width: "25%"}}
              labelCol={{span: 0}}
              wrapperCol={{span: 24}}
              name="unit"
              options={[
                {value: 1, label: "元/小时",},
                {value: 2, label: '元/天'},
                {value: 3, label: '元/周'},
                {value: 4, label: '元/月'},
                {value: 5, label: '元/次'},
                {value: 6, label: '其他'},
              ]}/>
          </ProFormGroup>
          <ProFormSelect
            label="结算周期"
            name="payWay"
            rules={[
              {
                required: true,
                message: "请选择结算周期"
              },
            ]}
            options={[
              {value: 1, label: "日结",},
              {value: 2, label: '周结'},
              {value: 3, label: '月结'},
              {value: 4, label: '完结'},
              {value: 0, label: '其他'},
              {value: -1, label: '不限'},
            ]}
          />
          <ProFormCheckbox.Group
            name="welfare"
            label="福利待遇"
            options={this.state.welfareOptions}
          />
        </ProCard>
        {/*岗位要求*/}
        <ProCard
          title="岗位要求"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 16,
            minWidth: 640,
            maxWidth: '100%',
          }}
        >
          <ProFormDependency name={['type']}>
            {
              ({type}) => {
                if (type === 2 || type === 3) {
                  return <ProFormRadio.Group
                    name="partPayType"
                    label="发薪方式"
                    options={[
                      {
                        label: '代发薪',
                        value: 1,
                      },
                      {
                        label: '自主发薪',
                        value: 2,
                      },
                    ]}
                  />
                }
                return null
              }
            }
          </ProFormDependency>

          <ProFormDependency name={['type']}>
            {
              ({type}) => {
                if (type === 1 || type === 3) {
                  return <>
                    <Form.Item
                      name="address"
                      label="企业地址"
                    >
                      <AddressCascader/>
                    </Form.Item>
                    <ProFormText
                      name="street"
                      label="详细地址"
                      placeholder="请输入详细地址"
                    />
                  </>
                }
                return null
              }
            }
          </ProFormDependency>

          <ProFormCheckbox.Group
            name="require"
            label="上岗要求"
            options={this.state.requireOptions}
          />
          <ProFormRadio.Group
            name="sex"
            label="性别要求"
            options={this.props.sexOptions}
          />
          <ProFormSelect
            label="学历要求"
            name="education"
            options={this.props.educationOptions}
          />
          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type === 1) {
                return <ProFormText
                  name="major"
                  label="专业要求"
                  placeholder="请输入专业要求"
                />
              }
              return null
            }}
          </ProFormDependency>

          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type === 3) {
                return <ProFormSelect
                  label="身高要求"
                  name="tail"
                  options={[
                    {label: "不限", value: 0},
                    {label: "150-160", value: 1},
                    {label: "160-170", value: 2},
                    {label: "170-180", value: 3},
                    {label: "180+", value: 4},
                  ]}
                />
              }
              return null
            }}
          </ProFormDependency>
        </ProCard>

        {/*工作时间*/}
        {
          <ProFormDependency name={['type']}>
            {({type}) => {
              if (type !== 1) {
                return <ProCard
                  title="工作时间"
                  bordered
                  headerBordered
                  collapsible
                  style={{
                    marginBottom: 16,
                    minWidth: 640,
                    maxWidth: '100%',
                  }}
                >
                  <ProFormRadio.Group
                    name="category"
                    label="工作种类"
                    options={[
                      {
                        label: '无要求',
                        value: 0,
                      },
                      {
                        label: '长招',
                        value: 1,
                      },
                      {
                        label: '短招',
                        value: 2,
                      },
                    ]}
                  />
                  <ProFormDependency name={['category']}>
                    {({category}) => {
                      if (category === 1) {
                        //长招
                        return (<>
                          <ProFormSelect
                            label="时间要求"
                            name="timeRequire"
                            rules={[{required: true, message: "请选择工作时长"}]}
                            options={[
                              {value: 1, label: "能工作一个月以上",},
                              {value: 2, label: '能工作二个月以上'},
                              {value: 3, label: '能工作三个月以上'},
                              {value: 4, label: '能工作四个月以上'},
                              {value: 5, label: '能工作五个月以上'},
                              {value: 6, label: '能工作六个月以上'},
                            ]}
                          />
                          <ProFormSelect
                            label="每周至少"
                            name="atLeast"
                            rules={[{required: true, message: "请选择每周上班天数"}]}
                            options={[
                              {value: 1, label: "一天",},
                              {value: 2, label: '二天'},
                              {value: 3, label: '三天'},
                              {value: 4, label: '四天'},
                              {value: 5, label: '五天'},
                              {value: 6, label: '六天'},
                              {value: 7, label: '七天'},
                            ]}
                          />
                        </>)
                      } else if (category === 2) {
                        //短招
                        return (
                          <ProFormList
                            label="上班日期"
                            name="workDays"
                            required={true}
                            wrapperCol={{span: 20, offset: 4}}
                            initialValue={[{date: []}]}
                            copyIconProps={{tooltipText: '复制'}}
                            deleteIconProps={{tooltipText: '删除'}}
                            creatorButtonProps={{
                              creatorButtonText: '新增工作日期',
                              position: 'bottom',
                              type: 'dashed'
                            }}
                          >
                            <ProFormDatePicker
                              name="dates"
                              label=""
                              rules={[{required: true, message: "请选择工作日期"}]}/>
                          </ProFormList>
                        )
                      }
                    }}
                  </ProFormDependency>
                  <ProFormDependency name={['category']}>
                    {
                      ({category}) => {
                        if (category === 0) {
                          return null
                        } else {
                          return <ProFormList
                            name="duration"
                            label="上班时段"
                            initialValue={[{timeRange: []}]}
                            copyIconProps={{tooltipText: '复制'}}
                            deleteIconProps={{tooltipText: '删除'}}
                            creatorButtonProps={{
                              creatorButtonText: '新增时间段',
                              position: 'bottom',
                              type: 'dashed'
                            }}
                          >
                            <ProFormTimePicker.RangePicker
                              name="timeRange"
                              fieldProps={{
                                format: 'HH:mm',
                              }}
                              rules={[{required: true, message: "请选择上班时段"}]}/>
                          </ProFormList>
                        }
                      }
                    }
                  </ProFormDependency>
                </ProCard>
              }
              return null
            }}
          </ProFormDependency>
        }

      </ProForm>
      <div style={{height: '64px'}}/>
    </div>)
  }
}

const mapStateToProps = state => ({
  jobTypeOptions: state.global.jobTypeOptions,
  sexOptions: state.global.sexOptions,
  educationOptions: state.global.educationOptions
})
export default connect(mapStateToProps, null)(JobEditor)