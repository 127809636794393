import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from "./store";
import reportWebVitals from './reportWebVitals';
import request from "./Fetch";
import './index.css';
React.fetch = request;
switch (process.env.REACT_APP_SECRET_ENV) {
  case "dev":
    React.baseUrl = "https://zp-api.qianfan-cloud.cn" //http://124.222.83.208:8088";
    break;
  case "test":
    React.baseUrl = "https://zp-api.qianfan-cloud.cn"  //http://124.222.83.208:8088";
    break;
  case "uat":
    React.baseUrl = "http://124.222.83.208:8088";
    break;
  case "production":
    React.baseUrl = "http://124.222.83.208:8088";
    break
  default:
    React.baseUrl = "";
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
