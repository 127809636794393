import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {GET_PLACEHOLDER_LIST, ADD_PLACEHOLDER, UPDATE_PLACEHOLDER, CHANGE_PLACEHOLDER_STATUS} from "../../../service";
import {Button, Modal, Space, Tag,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import "./index.less"
import ProForm, {ProFormText, ProFormCheckbox} from "@ant-design/pro-form";
const urlMap= {
  1: ADD_PLACEHOLDER,
  2: UPDATE_PLACEHOLDER,
  3: CHANGE_PLACEHOLDER_STATUS
}
class Placeholder extends Component {
  formRef = React.createRef();
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sortFieldList:{
        1:{
          field:'created_time',
          sort:1
        },
        2:{
          field:'created_time',
          sort:0
        },
        3:{
          field:'up_online_time',
          sort:1
        },
        4:{
          field:'up_online_time',
          sort:0
        },
        5:{
          field:'down_online_time',
          sort:1
        },
        6:{
          field:'down_online_time',
          sort:0
        },
      },
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '默认文字',
          dataIndex: 'text',
          fieldProps: {
            placeholder: "请输入默认文字"
          }
        },
        {
          title: '创建人',
          hideInSearch: true,
          dataIndex: 'createdBy',
        },
        {
          title: '显示平台',
          dataIndex: 'platform',
          hideInSearch: true,
          render: (text, record) => (
            record.platform.join(",")
          ),
        },
        {
          title: '创建时间',
          hideInSearch: true,
          dataIndex: 'createdTime',
        },
        {
          title: '上架时间',
          hideInSearch: true,
          dataIndex: 'upOnlineTime',
        },
        {
          title: '下架时间',
          hideInSearch: true,
          dataIndex: 'downOnlineTime',
        },
        {
          title: '状态',
          dataIndex: 'state',
          hideInSearch: true,
          render: (text, record) => (
            record.state.code === 1 ? <Tag color="processing">上架</Tag> : <Tag color="warning">下架</Tag>
          ),
        },
        {
          title: '排序',
          dataIndex: 'sort',
          valueType: 'select',
          hideInTable: true,
          fieldProps: {
            defaultValue:0,
            options: [
              {
                label: '默认',
                value: 0,
              },
              {
                label: '创建时间升序',
                value: 1,
              },
              {
                label: '创建时间降序',
                value: 2,
              },
              {
                label: '上架时间升序',
                value: 3,
              },
              {
                label: '上架时间降序',
                value: 4,
              },
              {
                label: '下架时间升序',
                value: 5,
              },
              {
                label: '下架时间降序',
                value: 6,
              },
            ]
          }
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          render: (text, record) => (
            <Space>
              <Button type="primary" onClick={() => this.handleEditor(record)}>编辑</Button>
              <Button type="default" danger={record.state.code === 0}
                      onClick={() => this.handleStatus(record)}>{record.state.code === 0 ? '上架' : '下架'}</Button>
            </Space>
          ),
        },
      ],
      modalVisible: false,
      eType: 1, //1添加，2编辑
      eId: "",  //编辑项id
    }
  }

  componentDidMount() {
  }

  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }

  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }


  handleAdd = () => {
    this.setState({
      modalVisible: true,
      eType: 1,
    },() => {
      this.formRef.current.resetFields()
    })
  }

  handleStatus = record => {
    React.fetch({
      url: urlMap["3"],
      method: "post",
      data: {
        id: record.id,
        state: record.state.code === 0 ? 1: 0
      }
    }).then(res => {
      if(res.code === 200) {
        this.tableRef.current.reload();
        message.success(`已${record.state.code===0?'上架':'下架'}`);
      }
    })
  }

  handleEditor = record => {
    this.setState({
      modalVisible: true,
      eType: 2,
      eId: record.id
    },() => {
      this.formRef.current.setFieldsValue({
        text: record.text,
        platform: record.platform
      })
    });
  }

  render() {
    return (
      <div className="placeholder-page">
        <ProTable
          actionRef={this.tableRef}
          columns={this.state.columns}
          request={async (params = {}, filter) => {
            console.log(params,this.state.sortFieldList[params.sort])
            let field='',sort='';
            if(params.sort&&params.sort!==0){
              field=this.state.sortFieldList[params.sort].field;
              sort=this.state.sortFieldList[params.sort].sort
            }
            
            
            const res = await React.fetch({
              url: GET_PLACEHOLDER_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                text: params.text,
                field:field,
                sort:sort
              }
            })
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                添加默认文字
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>

        <Modal
          title={`${this.state.eType === 1?'添加':'编辑'}搜索默认文字`}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout="horizontal"
            onFinish={(values) => {
              let data = {
                text: values.text,
                platform: values.platform
              }
              data.id = this.state.eId;
              React.fetch({
                url: urlMap[this.state.eType],
                method: "post",
                data
              }).then(res => {
                if(res.code === 200) {
                  this.modalHide();
                  this.tableRef.current.reload();
                  message.success(`${this.state.eType === 1?'新增':'编辑'}成功`);
                }
              })
            }}
            submitter={{
              render: (props, doms) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    {[...doms]}
                  </div>
                );
              },
            }}
          >
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "搜索默认文字!",
                },
              ]}
              required
              name="text"
              label="默认文字"
              placeholder="请填写搜索默认文字"
            />
            <ProFormCheckbox.Group
              name="platform"
              label="显示平台"
              options={[
                {
                  label: '支付宝',
                  value: "ALIPAY_MINI",
                },
                {
                  label: '微信',
                  value: "WECHAT_MINI",
                },
                {
                  label: 'H5',
                  value: "WEB_H5",
                },
                {
                  label: 'PC',
                  value: "WEB_PC",
                },
              ]}
              rules={[{required: true, message: '请选择显示平台'}]}
            />
          </ProForm>
        </Modal>
      </div>
    )
  }
}

export default Placeholder