import React, {Component} from "react";
import {GET_RANKING_JOB, PROVINCE_OPTiON, CITY_OPTION} from "../../../service";
import moment from 'moment';
import {DatePicker, Cascader, Space, Select} from 'antd';
import ProTable from '@ant-design/pro-table';
import "./index.less";

const {Option} = Select;

class RankingJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      date: [],
      columns: [
        {
          dataIndex: 'index',
          valueType: 'indexBorder',
          align: 'center',
          width: 48,
        },
        {
          dataIndex: 'jobId',
          title: "ID",
        },
        {
          dataIndex: 'jobTitle',
          title: "岗位名称",
        },
        {
          dataIndex: 'cityName',
          title: "城市",
        },
        {
          dataIndex: 'cityName',
          title: "职位类型",
          render: (text, record) => {
            return record.jobType?.desc
          }
        },
        {
          dataIndex: 'applyCount',
          title: "数量",
        },
        {
          dataIndex: 'alipayApplyCount',
          title: "支付宝",
        },
        {
          dataIndex: 'weixinApplyCount',
          title: "微信",
        },
      ],
      cityId: '',
      type: null,
      cityOptions: []
    };
  }

  componentDidMount() {
    this.loadProvinceData();
  }

  tableRef = React.createRef();
  loadProvinceData = () => {
    React.fetch({
      url: PROVINCE_OPTiON,
    }).then(res => {
      const result = res.data && res.data.map(item => {
        return {
          isLeaf: [1, 2, 3, 4].indexOf(item.id) > -1,
          label: item.name + item.suffix,
          value: item.id,
          code: item.code,
          level: 1
        }
      })
      this.setState({
        cityOptions: result
      })
    })
  }
  loadData = async selectedOptions => {
    const cityOptions = JSON.parse(JSON.stringify(this.state.cityOptions))
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.level === 1) {
      cityOptions.forEach((item) => {
          if (item.value === targetOption.value) {
            item.loading = true;
            item.children = []
          }
        }
      )
    }

    let result = [];
    const dProvince = [1, 2, 3, 4]; //直辖市id
    if (targetOption.level === 1 && dProvince.indexOf(targetOption.value) < 0) {
      result = await this.getCityOptions(targetOption.value);
    }

    if (targetOption.level === 1) {
      cityOptions.forEach((item) => {
          if (item.value === targetOption.value) {
            item.loading = false;
            item.children = result
          }
        }
      )
    }

    this.setState({
      cityOptions
    })
  }

  getCityOptions = (province) => {
    //获取城市
    return new Promise((resolve, reject) => {
      React.fetch({
        url: CITY_OPTION,
        method: "post",
        data: {
          provinceId: province
        }
      }).then(res => {
        resolve(res.data && res.data.slice(1).map(item => {
          return {
            label: item.name + item.suffix,
            value: item.id,
            code: item.code,
            isLeaf: true,
            level: 2
          }
        }))
      }).catch(err => {
        reject(err)
      })
    })
  }

  handleDateChange = val => {
    this.setState({
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      date: val,
    }, () => {
      this.tableRef.current.reload();
    })
  }

  handleCityChange = (value) => {
    this.setState({
      cityId: value[1]
    }, () => {
      this.tableRef.current.reload();
    })
  }

  handleTypeChange = value => {
    this.setState({
      type: value
    }, () => {
      this.tableRef.current.reload();
    })
  }


  render() {
    const {date, type} = this.state;
    return (
      <div className="ranking-job">
        <div className="search">
          <Space>
            <DatePicker.RangePicker allowClear onChange={this.handleDateChange} value={date}/>
            <Cascader
              options={this.state.cityOptions}
              placeholder="请选择城市"
              onChange={this.handleCityChange}
              loadData={this.loadData}/>
            <Select value={type} style={{width: '240px'}} onChange={this.handleTypeChange} placeholder="请选择">
              <Option value="1">全职</Option>
              {/*<Option value="2">线上兼职</Option>*/}
              {/*<Option value="3">线下兼职</Option>*/}
            </Select>
          </Space>
        </div>

        <div className="content">
          <ProTable
            actionRef={this.tableRef}
            columns={this.state.columns}
            bordered
            request={async (params = {}, sort, filter) => {
              const res = await React.fetch({
                url: GET_RANKING_JOB,
                method: "post",
                data: {
                  start: this.state.start,
                  end: this.state.end,
                  cityId: this.state.cityId,
                  jobType: this.state.type
                },
              });
              return {
                data: res.data,
                success: true,
              };
            }}
            rowKey="jobId"
            pagination={false}
            search={false}
            toolBarRender={false}
          />
        </div>
      </div>
    );
  }
}

export default RankingJob;
