import React, {Component} from "react";
import {Descriptions, Tabs, Tag} from 'antd';
import ProCard from '@ant-design/pro-card';
import Field from '@ant-design/pro-field';
import {GET_INTENTION_DETAIL} from "../../../service";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getQueryVariable} from "../../../utils";
import "./index.less";

const {TabPane} = Tabs;
const weekMap = {
  postsMon: "星期一",
  postsTue: "星期二",
  postsWed: "星期三",
  postsThu: "星期四",
  postsFri: "星期五",
  postsSat: "星期六",
  postsSun: "星期日"
}

class IntentionDetail extends Component {
  id = getQueryVariable(this.props.location.search, "id") || "";

  constructor(props) {
    super(props);
    this.state = {
      info: [
        {label: "姓名", value: ''},
        {label: "性别", value: ''},
        {label: "生日", value: ''},
        {label: "身份", value: ''},
        {label: "手机", value: ''},
        {label: "学校", value: ''},
        {label: "学历", value: ''},
        {label: "专业", value: ''},
        {label: "目前所在城市", value: ''},
        {label: "最快到岗时间", value: ''},
      ],
      intentions: [],
      activeKey: ""
    }
  }

  componentDidMount() {
    this.getInfo()
  }

  getInfo = () => {
    React.fetch({
      url: GET_INTENTION_DETAIL,
      method: "post",
      data: {
        id: this.id
      }
    }).then(res => {
      if (res.code === 200) {
        const {resume, intents} = res.data;
        this.setState({
          info: [
            {label: "姓名", value: resume.name || "-"},
            {label: "性别", value: resume.sex?.desc || "-"},
            {label: "生日", value: resume.birthday || "-"},
            {label: "身份", value: resume.identity?.desc || "-"},
            {label: "手机", value: resume.mobile || "-"},
            {label: "学校", value: resume.collegeName || "-"},
            {label: "学历", value: resume.education?.desc || "-"},
            {label: "专业", value: resume.specialty || "-"},
            {label: "目前所在城市", value: resume.cityName || "-"},
            {label: "最快到岗时间", value: resume.arrivalDateType?.desc || "-"},
          ],
          intentions: intents.map(item => ({
            ...item,
            id: item.id + "",
          })),
          activeKey: getQueryVariable(this.props.location.search, "i") || (intents[0]?.id + "")
        })
      }
    }).catch((err) => {
      throw err
    })
  }

  createTimeRequireText = date => {
    if (date.filter(item => item.value === "").length === date.length) {
      return "-"
    }
    return date.filter(item => item.value !== "")?.map(item => <li className="item" key={item}>
      <span>{weekMap[item.key]}</span> {item.value}</li>)
  }

  TabChange = activeKey => {
    this.setState({
      activeKey
    })
  }


  render() {
    const {info,intentions,activeKey} = this.state;
    return (
      <div className="intention-detail">
        <ProCard split="vertical">
          <ProCard title="基本信息" colSpan="30%">
            <Descriptions column={1}>
              {
                info.map(item => <Descriptions.Item label={item.label} key={item.label}>
                  <Field text={item.value} valueType="text" mode='read' plain/>
                </Descriptions.Item>)
              }
            </Descriptions>
          </ProCard>
          <ProCard title="求职意向" headerBordered>
            <Tabs type="card" activeKey={activeKey} onChange={this.TabChange}>
              {
                intentions.map((item, index) => (
                  <TabPane tab={'求职意向' + (index + 1)} key={item.id}>
                    <Descriptions column={1}>
                      <Descriptions.Item label="求职类型">
                        <Field valueType="text" mode='read' plain
                               render={() => <Tag color="success">{item.jobType?.desc}</Tag>}/>
                      </Descriptions.Item>
                      {
                        item.jobType?.code === 2 ? null : <Descriptions.Item label="工作城市">
                          <Field text={item.cityName} valueType="text" mode='read' plain/>
                        </Descriptions.Item>
                      }

                      <Descriptions.Item label="期望职位">
                        <Field text={item.position?.postName} valueType="text" mode='read' plain/>
                      </Descriptions.Item>
                      <Descriptions.Item label="期望行业">
                        <Field valueType="text" mode='read' plain
                               render={() => item.industry?.map(item => item.aliasName)?.join(', ')}/>
                      </Descriptions.Item>
                      <Descriptions.Item label="薪资要求">
                        <Field valueType="text" mode='read' plain render={() => {
                          if(+item.salaryMin === 0 ||  +item.salaryMax === 0){
                            return '面议'
                          }else if (item.salaryMin === item.salaryMax) {
                            return `${item.salaryMin}k`
                          } else {
                            return `${item.salaryMin} - ${item.salaryMax}k`
                          }
                        }}/>
                      </Descriptions.Item>
                      {
                        item.jobType?.code !== 1 ? <Descriptions.Item label="时间要求">
                          <Field mode='read' plain render={() => {
                            return (
                              <ul className="list">
                                {
                                  this.createTimeRequireText([
                                      {key: 'postsMon', value: item.postsMon},
                                      {key: 'postsTue', value: item.postsTue},
                                      {key: 'postsWed', value: item.postsWed},
                                      {key: 'postsThu', value: item.postsThu},
                                      {key: 'postsFri', value: item.postsFri},
                                      {key: 'postsSat', value: item.postsSat},
                                      {key: 'postsSun', value: item.postsSun},
                                    ]
                                  )
                                }
                              </ul>
                            )
                          }}/>
                        </Descriptions.Item> : null
                      }

                    </Descriptions>
                  </TabPane>
                ))
              }
            </Tabs>
          </ProCard>
        </ProCard>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, null)(withRouter(IntentionDetail))