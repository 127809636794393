import React, {Component} from "react";
import {Button, Modal, Image, message, Form} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText, ProFormSelect, ProFormUploadDragger} from '@ant-design/pro-form';
import {GET_ICON_LIST, GET_ICON_DETAIL, EDITE_ICON, REFRESH_ICON, UPDATE_STATE} from "../../../service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./style.less";

const bannerTypeOptions = {'0': '不跳转', '1': '支付宝小程序', '2': '直播', '3': '外部链接'}
const bannerSateOptions = {'0': '不显示', '1': '显示'}

class HomeIconConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1, //1添加2编辑
      eId: "",
      state: 0,
      columns: [
        {
          dataIndex: "id",
          title: "ID",
          width: 48,
        },
        {
          title: "图片",
          width: 160,
          dataIndex: "src",
          render: (text, record) => {
            return <Image width={70} src={record.url}/>;
          },
        },
        {
          title: "ICON名称",
          ellipsis: true,
          width: 150,

          dataIndex: "name",
        },
        {
          title: "显示状态",
          width: 80,
          dataIndex: "state",
          render: (text, record) => {
            return <Button type="link" danger={record.state === 0}>{bannerSateOptions[record.state]}</Button>
          },
        },
        {
          title: "跳转类型",
          width: 100,
          dataIndex: "bannerType",
          render: (text, record) => {
            return bannerTypeOptions[record.type];
          },
        },
        {
          title: "跳转地址",
          width: 180,
          ellipsis: true,
          dataIndex: "targetUrl",
        },
        {
          title: "平台",
          width: 100,
          ellipsis: true,
          dataIndex: "platform",
        },
        {
          title: "创建人",
          width: 80,
          dataIndex: "createdBy",
        },
        {
          title: "操作人",
          width: 80,
          dataIndex: "updatedBy",
        },
        {
          title: "创建时间",
          width: 180,
          dataIndex: "createdTime",
        },
        {
          title: "操作时间",
          width: 180,
          dataIndex: "updatedTime",
        },
        {
          title: "备注",
          width: 60,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 120,
          valueType: "option",
          fixed: 'right',
          align: "center",
          render: (text, record) => (
            <Button type="primary" onClick={() => this.handleEditor(record)}>
              修改
            </Button>
          ),
        },
      ],
    };
  }

  formRef = React.createRef()
  tableRef = React.createRef()

  componentDidMount() {
  }

  handleEditor = (data) => {
    React.fetch({
      url: GET_ICON_DETAIL,
      method: "post",
      data: {
        id: data.id
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          eType: 2,
          eId: data.id,
          modalVisible: true
        }, () => {
          this.formRef.current.setFieldsValue({
            bannerName: res.data.name,
            bannerUrl: [{url: res.data.url, name: data.realUrl}],
            bannerType: res.data.type.toString(),
            platform: res.data.platform,
            targetUrl: res.data.targetUrl,
            remark: res.data.remark,
          })
        })
      }
    })
  }
  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  editeBanner(data) {
    let url = ""
    if (!!(data.bannerUrl[0]?.response?.code === 200 && data.bannerUrl[0]?.response?.data?.fileName)) {
      // 新上传图片
      url = data.bannerUrl[0]?.response?.data?.fileName

    } else {
      url = data.bannerUrl[0]?.name
    }

    React.fetch({
      url: EDITE_ICON,
      method: "post",
      data: {
        id: this.state.eId,
        name: data.bannerName,
        url,
        type: data.bannerType,
        platform: data.platform,
        targetUrl: data.targetUrl,
        remark: data.remark,
      },
    }).then((res) => {
      if (res.code === 200) {
        message.success("修改成功！");
        this.modalHide();
        this.formRef.current.resetFields();
        this.tableRef.current.reload();
      }
    });
  }

  handleRefresh = () => {
    React.fetch({
      url: REFRESH_ICON,
    }).then(res => {
      if (res.code === 200) {
        this.tableRef.current.reload();
        message.success("同步成功！")
      }
    })
  }

  handleUpdateState = () => {
    React.fetch({
      url: UPDATE_STATE,
      method: "post",
      data: {
        state: this.state.state === 0 ? 1 : 0
      }
    }).then(res => {
      if (res.code === 200) {
        this.tableRef.current.reload();
        message.success("修改成功！")
      }
    })
  }

  render() {
    const {state} = this.state
    return (
      <div className="banner-list-page">
        <ProTable
          actionRef={this.tableRef}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_ICON_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
              },
            });
            this.setState({
              state: res.data[0].state
            })
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={false}
          pagination={false}
          params={{
            page: this.state.page
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          toolBarRender={(action) => {
            return [
              <Button key="1" type="primary"
                      onClick={() => this.handleUpdateState()}>{state === 0 ? '显示' : '不显示'}</Button>,
              <Button key="2" type="primary" onClick={() => this.props.history.push("/homeIconHistory")}>查看历史</Button>,
              <Button key="3" type="primary" onClick={() => this.handleRefresh()}>同步新版</Button>,
            ];
          }}
        />
        <Modal
          title={this.state.eType === 1 ? "添加" : "编辑"}
          width={800}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout="horizontal"
            onFinish={async (values) => {
              this.editeBanner(values);
            }}
            submitter={{
              render: (props, doms) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    {[...doms]}
                  </div>
                );
              },
            }}
          >
            <ProFormText
              name="bannerName"
              label="ICON名称"
              placeholder="ICON名称"
              rules={[{required: true, message: "请填写名称"}]}
            />
            <ProFormSelect
              name="platform"
              label="ICON展示平台"
              valueEnum={{
                ALIPAY_MINI: "ALIPAY_MINI",
                WEB_PC: "WEB_PC",
                WEB_H5: "WEB_H5",
                WECHAT_MINI: 'WECHAT_MINI'
              }}
              placeholder="请选择"
              rules={[{required: true, message: "请选择ICON展示平台"}]}
            />
            <ProFormUploadDragger
              require
              label="ICON"
              max={1}
              action={React.baseUrl + "/web/boss/upload/img"}
              fieldProps={{
                name: "file",
                listType: "picture",
                headers: {
                  "x-zp-ats-auth-token": this.props.token,
                },
              }}
              rules={[{required: true, message: "请上传图片"}]}
              name="bannerUrl"
              description="支持jpg,png格式，小于1M的图片,建议图片尺寸76 * 76"
            />
            <ProFormText
              name="remark"
              label="备注"
              placeholder="轮播备注"
            />
            <ProFormSelect
              name="bannerType"
              label="跳转类型"
              valueEnum={bannerTypeOptions}
              placeholder="请选择"
              rules={[{required: true, message: "请选择ICON跳转类型"}]}
            />

            <Form.Item noStyle shouldUpdate>
              {(form) => {
                return form.getFieldValue("bannerType") !== '0' ? (
                  <ProFormText
                    rules={[{required: true, message: "请输入轮播跳转地址"}, {
                      validator: async (_, targetUrl) => {
                        let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/;
                        if (targetUrl !== '' && (!reg.test(targetUrl)) && form.getFieldValue("bannerType") === '2') {
                          return Promise.reject(new Error('请输入合法的外部链接地址'));
                        }
                      }
                    }]}
                    name="targetUrl"
                    label="跳转地址"
                    placeholder="跳转地址"
                  />
                ) : (
                  ""
                );
              }}
            </Form.Item>
          </ProForm>
        </Modal>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  token: state.login.token
})

export default connect(mapStateToProps, null)(withRouter(HomeIconConfig))