import React, {Component} from "react";
import {Button, Space, Modal, Table, message, Tag} from "antd";
import {withRouter} from "react-router-dom";
import ProTable from "@ant-design/pro-table";
import {RESUME_LIST, JOB_ONLINE_LIST, RECOMMEND_JOB, GET_RESUME_VIDEO} from "../../../service";
import {connect} from "react-redux";
import "./index.less";

class ResumeManage extends Component {
  applicantAccountId = "";
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isModalVisible: false,
      selectedRowKeys: [],
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '就读学校',
          dataIndex: 'collegeName',
        },
        {
          title: '就读专业',
          dataIndex: 'specialty',
        },
        {
          title: '最高学历',
          dataIndex: 'education',
          valueType: "select",
          fieldProps: {
            placeholder: "选择学历",
            options: this.props.educationOptions
          },
          render: ((text, record) => {
            return record.education && record.education.desc
          })
        },
        {
          title: '毕业年份',
          dataIndex: 'educationYear',
          hideInSearch: true,
        },
        {
          title: '身份',
          dataIndex: 'identity',
          valueType: 'select',
          fieldProps: {
            placeholder: "选择身份",
            options: this.props.identityOptions
          },
          render: (text, record) => {
            return record.identity && record.identity.desc
          }
        },
        {
          title: '创建时间',
          hideInSearch: true,
          dataIndex: 'create_time',
        },
        {
          title: '简历完成度',
          hideInSearch: true,
          dataIndex: 'process',
        },
        {
          title: '操作',
          width: 200,
          valueType: 'option',
          align: 'center',
          render: (text, record) => (
            <Space>
              <Button type="primary" onClick={() => this.handleView(record)}>详情</Button>
              <Button type="primary" onClick={() => this.handleRecommend(record)} danger>推荐</Button>
              <Button onClick={() => this.handleViewVideo(record)}>简历视频</Button>
            </Space>
          ),
        },
      ],
      modalColumn: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 58,
          hideInSearch: true
        },
        {
          title: '职位名称',
          ellipsis: true,
          dataIndex: 'title',
        },
        {
          title: '企业名称',
          width: 150,
          ellipsis: true,
          dataIndex: 'companyName',
        },
        {
          title: '学历要求',
          width: 80,
          ellipsis: true,
          dataIndex: 'requireEducation',
          valueType: 'select',
          hideInSearch: true,
          render: (text, record) => {
            return record.requireEducation?.desc
          },
          fieldProps: {
            placeholder: "选择学历",
            options: this.props.educationOptions
          }
        },
        {
          title: '薪资',
          width: 150,
          ellipsis: true,
          dataIndex: 'salary',
          hideInSearch: true,
          render: (text, record) => {
            let salary = 0;
            if(record.salaryMin === record.salaryMax) {
              salary = record.salaryMin
            }else if(!record.salaryMax){
              salary = record.salaryMin
            }else{
              salary = record.salaryMin + '-' + record.salaryMax
            }
            return salary + "/" + record.salaryUnit?.desc
          }
        },
        {
          title: '是否认证',
          width: 100,
          hideInTable: true,
          dataIndex: 'isCompanyCert',
          valueType: 'select',
          render: (text, record) => {
            return record.isCompanyCert?.desc
          },
          fieldProps: {
            placeholder: "是否认证",
            options: [
              {label: "未认证", value: 0},
              {label: "已认证", value: 1},
            ]
          }
        },
        {
          title: '职位类型',
          width: 80,
          dataIndex: 'jobType',
          valueType: 'select',
          fieldProps: {
            placeholder: "岗位类型",
            options: this.props.jobTypeOptions
          },
          render: (text, record) => {
            return record.jobType?.desc
          },
        },
        {
          title: '发布时间',
          width: 180,
          hideInSearch: true,
          dataIndex: 'publishTime',
        },
        {
          title: '刷新时间',
          width: 180,
          hideInSearch: true,
          dataIndex: 'refreshTime',
        },
      ],
      videoModal: false,
      videoSrc: ''
    }
  }

  handleView = data => {
    this.props.history.push("/resumeDetail?resume=" + data.id+"&apply="+ data.applicantAccountId)
  }

  handleViewVideo(record) {
    React.fetch({
      url: GET_RESUME_VIDEO,
      method: "post",
      data: {
        id: record.id
      }
    }).then(res => {
      if(res.data) {
        this.setState({
          videoModal: true,
          videoSrc: res.data || ''
        })
      }else {
        message.error("简历视频不存在");
      }

    })
  }

  handleRecommend = data => {
    this.modalVisible(true);
    this.applicantAccountId = data.applicantAccountId
  }
  handleOk = () => {
    this.modalVisible(false);
    React.fetch({
      url: RECOMMEND_JOB,
      method: "post",
      data: {
        applicantAccountId: this.applicantAccountId,
        jobIdList: this.state.selectedRowKeys
      }
    }).then(res => {
      if(res.code === 200) {
        message.success("岗位推荐成功");
        this.setState({ selectedRowKeys:[] });
      }
      this.modalVisible(false);
    })
  }
  handleCancel = () => {
    this.modalVisible(false);
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  }

  modalVisible = status => {
    this.setState({
      isModalVisible: status
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="resume-manage-page">
        <ProTable
          columns={this.state.columns}
          request={async (params) => {
            const res = await React.fetch({
              url: RESUME_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                collegeName: params.collegeName,
                education: params.education,
                identity: params.identity,
                name: params.name,
                specialty: params.specialty
              }
            })

            return Promise.resolve({
              data: res.data,
              success: true,
            })
          }}
          onSubmit={()=>{
           this.setState({
             page: 1
           })
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={false}
        />

        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>

        {
            this.state.videoSrc && <Modal title='视频预览' visible={this.state.videoModal} onCancel={() => this.setState({videoModal: false, videoSrc: ''})} footer={null}>
              {
                <video style={{width: '100%'}} src={this.state.videoSrc} controls autoPlay></video>
              }
            </Modal>
        }

        {
          this.state.isModalVisible && <Modal width={1200} title="推荐岗位" visible={this.state.isModalVisible} onOk={this.handleOk}
                                              onCancel={this.handleCancel}>
            <ProTable
              columns={this.state.modalColumn}
              rowSelection={{
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                selectedRowKeys: this.state.selectedRowKeys,
                onChange: this.onSelectChange
              }}
              tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}) => <span>已选 {selectedRowKeys.length} 项</span>}
              search={{
                labelWidth: "auto"
              }}
              pagination={{
                pageSize: 10
              }}
              rowKey="id"
              toolBarRender={false}
              request={async (params) => {
                const res = await React.fetch({
                  url: JOB_ONLINE_LIST,
                  method: "post",
                  data: {
                    page: params.current,
                    size: params.pageSize,
                    title: params.title,
                    jobType: params.jobType,
                    companyName: params.companyName,
                    zhimaJobType: params.zhimaJobType,
                    isCompanyCert: params.isCompanyCert
                  }
                })

                return Promise.resolve({
                  data: res.data,
                  success: true,
                  total: 1000
                })
              }}
            />
          </Modal>
        }
      </div>
    )
  }
}

const mapStateToProps =  state => {
  return {
    educationOptions: state.global.educationOptions,
    identityOptions: state.global.identityOptions,
    jobTypeOptions: state.global.jobTypeOptions
  }
}

export default connect(mapStateToProps, null)(withRouter(ResumeManage))