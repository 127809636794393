import React, {Component} from "react";
import ProCard from '@ant-design/pro-card';
import "./index.less"

class MessageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        id: 1,
        title: "这是系统信息",
        content: "<p>春节放假期间，不接受任何简历投递</p><p>公司放假时间为 <span style='color: red'>2-1至2-18日</span></p>"
      }
    }
  }

  componentDidMount() {
    console.log(this.props)
  }

  render() {
    const {info} = this.state;
    return (
      <div className="message-view-page">
        <ProCard title="消息信息" headerBordered>
          <ul className="info-list">
            <li className="info-item">
              <span className="info-item-label">ID:</span>
              <span className="info-item-value">{info.id}</span>
            </li>
            <li className="info-item">
              <span className="info-item-label">消息标题:</span>
              <span className="info-item-value">{info.title}</span>
            </li>
          </ul>
        </ProCard>
        <ProCard title="消息内容" headerBordered>
          <div className="message-content" dangerouslySetInnerHTML={{__html: info.content}}></div>
        </ProCard>
      </div>
    )
  }
}

export default MessageView