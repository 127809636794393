import React, {Component} from "react";
import {CloudDownloadOutlined} from '@ant-design/icons';
import {OPERATION_LIST} from "../../../service";
import {Button} from 'antd';
import ProTable from '@ant-design/pro-table';
import "./index.less"

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '姓名',
          dataIndex: 'accountName',
          fieldProps: {
            placeholder: "请输入姓名"
          }
        },
        {
          title: '操作类型',
          hideInSearch: true,
          dataIndex: 'operateData',
        },
        {
          title: '行为场景',
          dataIndex: 'platform',
          hideInSearch: true,
        },
        {
          title: '记录时间',
          hideInSearch: true,
          dataIndex: 'createdTime',
        },
        {
          title: 'IP端口',
          dataIndex: 'sourceIp',
          hideInSearch: true,
        },
        {
          title: '',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          fieldProps: {
            placeholder: ["开始时间","结束时间"]
          }
        },
      ]
    }
  }
  componentDidMount() {
  }
  handleExport = () => {

  }

  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }

  render() {
    return (
      <div className="logs-page">
        <ProTable
          columns={this.state.columns}
          request={async (params = {}, filter) => {
            console.log(params)
            const res = await React.fetch({
              url:OPERATION_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                accountName: params.accountName,
                beginDate: params.created_at&&params.created_at[0],
                endDate: params.created_at&&params.created_at[1]
              }
            })
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<CloudDownloadOutlined/>} type="primary" onClick={this.handleExport}>
                导出
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
      </div>
    )
  }
}

export default Logs