import React, {Component} from "react";
import moment from 'moment';
import {Button, Modal, message, Form, Input, Select, Checkbox, DatePicker} from 'antd';
import ProTable from '@ant-design/pro-table';
import {PlusOutlined} from '@ant-design/icons';
import ProForm, {ProFormCheckbox, ProFormText, ProFormRadio} from "@ant-design/pro-form";
import {GET_TAG_LIST, ADD_TAG, UPDATE_TAG, GET_TAG_INFO} from "../../../../service";
import {connect} from "react-redux";

class CompanyPlatformTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 48,
          hideInSearch: true,
        },
        {
          title: ' 标签名称',
          dataIndex: 'name',
        },
        {
          title: '创建人',
          dataIndex: 'createdBy',
          hideInSearch: true,
        },
        {
          title: '应用端',
          dataIndex: 'bizType',
          render: (dom, rowData) => {
            return rowData.bizType?.desc
          }
        },
        {
          title: '适用范围',
          dataIndex: 'tagTypes',
          valueType: 'select',
          fieldProps: {
            options: this.props.jobTagTypeOptions
          },
          render: (dom, rowData) => {
            if (rowData.tagTypes && rowData.tagTypes.length) {
              let arr = [];
              this.state.allTagsOptions.forEach(item => {
                if(rowData.tagTypes.indexOf(item.value)>=0){
                  arr.push(item.label)
                }
              })
              return arr.join(', ')
            } else {
              return ''
            }
          },
        },
        {
          title: '岗位类型',
          dataIndex: 'jobTypes',
          hideInSearch: true,
          render: (text, record) => {
            return record.jobTypes ? [...new Set(record.jobTypes?.map(item => item.desc))].join(",") : '-'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '操作',
          width: 120,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record, _) => (
            <Button type="primary" onClick={() => this.handleEditor(record)}>编辑</Button>
          ),
        },
      ],
      applyScopeList: [],
      tagName: '',
      application: 1,
      applyScope: '',
      jobType: [],
      creatTimeRange: '',
      allTagsOptions: []
    }
  }

  componentDidMount() {
    this.setState({
      applyScopeList: this.props.jobTagTypeOptions.concat([]),
      allTagsOptions: [...this.props.jobTagTypeOptions,...this.props.tagTypeOptions]
    })
  }

  formRef = React.createRef()
  ref = React.createRef()
  handleEditor = record => {
    this.setState({
      eType: 2,
      eId: record.id,
      modalVisible: true
    }, () => {
      React.fetch({
        url: GET_TAG_INFO,
        method: "post",
        data: {
          id: record.id
        }
      }).then(res => {
        this.formRef.current.setFieldsValue({
          name: res.data.name,
          ranges: res.data.tagTypes,
          type: res.data.jobTypes?.map(item => item.code),
          application: res.data.bizType.code,

        })
      })
    })
  }
  setApplication = (value) => {
    let o = this.props.jobTagTypeOptions.concat(this.props.jobTagTypeOptions)
    if (value) {
      o = value === 1 ? this.props.jobTagTypeOptions.concat([]) : this.props.tagTypeOptions.concat([])
    }
    this.setState({
      applyScopeList: o,
      application: value,
      applyScope: ''
    })
  }
  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    }, () => {
      this.formRef.current.setFieldsValue({
        name: '',
        ranges: [],
        type: []
      })
    })
  }

  handleSubmit = values => {
    const urlMap = {
      1: ADD_TAG,
      2: UPDATE_TAG
    }
    const data = {
      name: values.name,
      jobTypes: [...new Set(values.type)],
      tagTypes: values.ranges,
      bizType: values.application
    }
    if (this.state.eType === 2) {
      data.id = this.state.eId
    }
    React.fetch({
      url: urlMap[this.state.eType],
      method: "post",
      data
    }).then(res => {
      if (res.code === 200) {
        this.modalHide();
        message.success(this.state.type === 1 ? "添加成功" : "更新成功");
        this.ref.current.reload()
      }
    })
  }
  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  setJobType = (checkedValues) => {
    this.setState({
      jobType: checkedValues
    })
  }
  setTagName = (e) => {
    this.setState({
      tagName: e.target.value
    })
  }
  setApplyScope = (value) => {
    this.setState({
      applyScope: value
    })
  }
  setDateRange = (value) => {
    this.setState({
      creatTimeRange: value
    })
  }

  render() {
    return (
      <div className="pro-table">
        <div className="table-filter">
          <Form layout="inline">
            <Form.Item label="标签名称">
              <Input onChange={(e) => {
                this.setTagName(e)
              }} style={{"width": "300px"}}/>
            </Form.Item>
            <Form.Item label="应用端">
              <Select defaultValue={this.state.application} allowClear style={{"width": "300px"}} onChange={(value) => {
                this.setApplication(value)
              }}>
                {this.props.applicationOptions.map(item => {
                  return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item label="适用范围">
              <Select allowClear showArrow style={{"width": "280px"}} value={this.state.applyScope}
                      onChange={(value) => {
                        this.setApplyScope(value)
                      }}>
                {
                  this.state.applyScopeList.map(item => {
                    return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            {this.state.application === 2 ? <Form.Item label="职位类型">
              <Checkbox.Group onChange={(checkedValues) => {
                this.setJobType(checkedValues)
              }}>
                {this.props.jobTypeOptions.map(item => {
                  return <Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>
                })}
              </Checkbox.Group>
            </Form.Item> : ''}
            <Form.Item label="创建时间">
              <DatePicker.RangePicker onChange={(value) => {
                this.setDateRange(value)
              }} allowClear/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => {
                this.setState({
                  page: 1
                },() =>{
                  this.ref.current.reload()
                })
              }}>查询</Button>
            </Form.Item>

          </Form>
        </div>
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_TAG_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                name: this.state.tagName,
                bizType: this.state.application,
                jobTypes: this.state.jobType.length === 0 ? null : this.state.jobType,
                tagType: this.state.applyScope === '' ? null : this.state.applyScope,
                beginDate: this.state.creatTimeRange && moment(this.state.creatTimeRange[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
                endDate: this.state.creatTimeRange && moment(this.state.creatTimeRange[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              }
            })
            return Promise.resolve({
              data: res.data,
              success: true,
              total: 500,
            });
          }}
          rowKey="id"
          search={false}
          pagination={false}
          params={{
            page: this.state.page
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          dateFormatter="string"
          toolBarRender={() => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                发布标签
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title={this.state.eType === 1 ? "添加" : "编辑"}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout='horizontal'
            onFinish={async (values) => {
              this.handleSubmit(values)
            }}
          >
            <ProFormText name="name" label="标签名称" placeholder="请输入标签"/>
            <ProFormRadio.Group
              name="application"
              disabled={this.state.eType === 2}
              label="应用端"
              options={this.props.applicationOptions}
            />
            <Form.Item noStyle shouldUpdate>
              {(form) => {
                console.log(form.getFieldValue("application"))
                if (form.getFieldValue("application")) {
                  return form.getFieldValue("application") === 1 ? (<div><ProFormCheckbox.Group
                    name="ranges"
                    label="适用范围"
                    options={this.props.jobTagTypeOptions}
                  /></div>) : (<div><ProFormCheckbox.Group
                    name="ranges"
                    label="适用范围"
                    options={this.props.tagTypeOptions}
                  />
                    <ProFormCheckbox.Group
                      name="type"
                      label="职位类型"
                      options={this.props.jobTypeOptions}
                    /></div>)
                } else {
                  return ''
                }
              }}
            </Form.Item>

          </ProForm>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    jobTypeOptions: state.global.jobTypeOptions,
    tagTypeOptions: state.global.tagTypeOptions,
    jobTagTypeOptions: state.global.jobTagTypeOptions,
    applicationOptions: state.global.applicationOptions,
  }
}

export default connect(mapStateToProps)(CompanyPlatformTable)