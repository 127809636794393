export const LOGIN = "/web/boss/login";   //登录
export const GET_MENU_LIST = "/web/boss/menu/list";    //获取菜单列表
export const ADD_MESSAGE = "/web/boss/message/send";   //新增系统消息
export const GET_ACCOUNT = "/web/boss/account/list";   //获取账号列表
export const CREAT_ACCOUNT = "/web/boss/account/create";  //创建账号
export const EDITE_ACCOUNT = "/web/boss/account/update";  //创建账号
export const GET_ROLE_LIST = "/web/boss/sys/role/list";    //获取角色列表

export const GET_MESSAGE_LIST = "/web/boss/message/list";   //系统消息列表
export const ADD_ROLE = "/web/boss/sys/role/add";    //添加角色
export const UPDATE_ROLE = "/web/boss/sys/role/update";    //修改角色
export const GET_SYSMENU_LIST = "/web/boss/sys/menu/list";    //获取全部菜单列表
export const GET_USER_INFO = '/web/boss/account/info' //获取账号信息  
export const GET_ROLEMENU_LIST = "/web/boss/sys/role/menu/list";    //获取角色菜单列表
export const UPDATE_ROLE_INFO = '/web/boss/sys/role/update'//更新角色信息  不包括菜单
export const ADD_ROLE_MENU = '/web/boss/sys/role/menu/add'//添加角色下的菜单
export const DELETE_ROLE_MENU = '/web/boss/sys/role/menu/del'//删除角色下的菜单
export const GET_AUTH_LIST = '/web/boss/sys/auth/list'//获取权限列表
export const ADD_AUTH = '/web/boss/sys/auth/add'//添加权限
export const UPDATE_AUTH = '/web/boss/sys/auth/update'//修改权限
export const ADD_MENU = '/web/boss/sys/menu/add'//添加菜单
export const UPDATE_MENU = '/web/boss/sys/menu/update'//修改菜单
export const DELETE_MENU = '/web/boss/sys/menu/delete'//删除菜单
export const GET_MENU_AUTH_LIST = '/web/boss/sys/menu/auth/list'//获取菜单权限
export const DELETE_MENU_AUTH = '/web/boss/sys/menu/auth/del'//删除菜单权限
export const ADD_MENU_AUTH = '/web/boss/sys/menu/auth/add'//添加菜单权限
export const GET_SENSITIVEWORDS_LIST = '/web/boss/dic/sensitivity/list'//敏感词列表
export const ADD_SENSITIVEWORDS = '/web/boss/dic/sensitivity/add'//添加敏感词
export const SENSITIVEWORDS_COUNT = '/web/boss/dic/sensitivity/count'//敏感词总数
export const DELETE_SENSITIVEWORDS = '/web/boss/dic/sensitivity/delete'//删除敏感词
export const UPDATE_SENSITIVEWORDS = '/web/boss/dic/sensitivity/update'//更新敏感词
export const UPLOAD_SENSITIVEWORDS = '/web/boss/dic/sensitivity/upload'//批量上传敏感词


export const JOB_LIST = "/web/boss/job/list";   //岗位列表
export const JOB_ONLINE_LIST = "/web/boss/job/list/online";   //在线岗位列表
export const AGREEMENT_JOB = "/web/boss/job/audit/agreement"; //审核通过
export const REJECTION_JOB = "/web/boss/job/audit/rejection"; //拒绝岗位
export const CANCEL_JOB = "/web/boss/job/down";  //下架岗位
export const UPLOAD_JOB = "/web/boss/job/up";  //上架岗位

export const APPLICANT_LIST = "/web/boss/applicant/list"; //求职者列表
export const RESUME_LIST = "/web/boss/resume/list";    //简历列表
export const RESUME_INFO = "/web/boss/resume/info";   //简历详情

export const GET_COMPANY_LIST = "/web/boss/company/list"; //企业列表
export const GET_COMPANY_LIST_ALL = "/web/boss/company/list/all"; //企业所有列表信息
export const GET_COMPANY_INFO = "/web/boss/company/info"; //企业信息
export const UPDATE_COMPANY_INFO = "/web/boss/company/update";  //修改企业信息
export const RESET_PASSWORD = "/web/boss/company/account/password/update"; //重置企业密码
export const ADD_COMPANY_ACCOUNT = "/web/boss/company/account/add";  //新增企业账号
export const UPDATE_CONTACTOR_INFO = "/web/boss/company/account/contact/update"; //修改企业联系人信息
export const GEt_INDUSTRY_LIST = "/web/boss/industry/list"; //行业列表
export const GET_CONTACT_LIST = "/web/boss/contactMan/list"; //联系人列表
export const GET_APPLY_RECORD = "/web/boss/apply/list";   //投递记录
export const GET_TAG_LIST = "/web/boss/tag/list";    //标签库
export const ADD_TAG = "/web/boss/tag/add";  //新增标签
export const GET_TAG_INFO = "/web/boss/tag/info";   //获取单个标签
export const UPDATE_TAG = "/web/boss/tag/update"; //更新标签
export const GET_JOB_CATRGORY_LIST = "/web/boss/dic/job/category/list"//岗位分类列表
export const ADD_JOB_CATRGORY = "/web/boss/dic/job/category/add"//新增岗位分类
export const UPDATE_JOB_CATRGORY = "/web/boss/dic/job/category/update"//修改岗位分类
export const REFRESH_JOB_CATEGORY = "/web/boss/dic/job/category/refresh"//岗位分类刷新


export const PROVINCE_OPTiON = "/web/boss/province/list"; //省份
export const CITY_OPTION = "/web/boss/city/list";  //城市
export const AREA_OPTION = "/web/boss/area/list";  //区域
export const GET_JOB_CATEGORY_ALL = "/web/boss/dic/job/category";  //获取岗位全部类型
export const GET_WELFARE_OPTION = "/web/boss/job/tag/welfare";   //福利待遇标签
export const GET_REQUIRE_OPTION = "/web/boss/job/tag/require";   //岗位要求标签
export const CREAT_FULL_JOB = "/web/boss/job/full/create";  //创建全职岗位
export const UPDATE_FULL_JOB = "/web/boss/job/full/update";   //更新全职岗位
export const CREAT_ONLINE_JOB = "/web/boss/job/partOnline/create";   //创建线上岗位
export const UPDATE_ONLINE_JOB = "/web/boss/job/partOnline/update";   //更新线上岗位
export const CREAT_OFFLINE_JOB = "/web/boss/job/partOffline/create";   //创建线下岗位
export const UPDATE_OFFLINE_JOB = "/web/boss/job/partOffline/update";   //更新线下岗位
export const GET_JOB_INFO = "/web/boss/job/info";    //岗位详情信息
export const AGREE_APPLY = "/web/boss/apply/agreement";   //录取
export const REJECT_APPLY = "/web/boss/apply/rejection";  //不合适
export const RECOMMEND_JOB = "/web/boss/job/recommend";   //推荐岗位


//职位采集管理
export const GET_COLLECTJOB_LIST = "/web/boss/spider/edit/list";   //采集岗位列表
export const GET_COLLECTJOBVERIFY_LIST = "/web/boss/spider/list";   //采集岗位审核列表
export const GET_COLLECTJOB_DETAIL = '/web/boss/spider/edit/info' //获取采集职位详情
export const EDITE_AGREEMENT = '/web/boss/spider/edit/agreement'//提交抓取job
export const GET_ALLOWN_COMPANYLIST = '/web/boss/company/list/all'//获取公司列表
export const DELETE_COLLECTJOB = '/web/boss/spider/edit/del'//删除爬虫job
export const REJECT_COLLECTJOB = '/web/boss/spider/edit/rejection'//爬虫驳回
export const AGREE_COLLECTJOB = '/web/boss/spider/agreement'//爬虫job审核通过
export const AGREE_COLLECTJOB_DETAIL = '/web/boss/spider/info'//爬虫已修改job详情
export const UPDATE_COLLECTJOB_DETAIL = '/web/boss/spider/update'//编辑完爬虫岗位后更新


// banner
export const GET_BANNER_LIST = '/web/boss/ad/banner/list'   //轮播列表
export const GET_BANNER_DETAIL = '/web/boss/ad/banner/info' //获取轮播详情
export const DELETE_BANNER = '/web/boss/ad/banner/del'      //删除banner
export const ADD_BANNER = '/web/boss/ad/banner/add'         //添加banner
export const EDITE_BANNER = '/web/boss/ad/banner/update'    //修改banner

// 广告入口
export const GET_AD_LIST = '/web/boss/ad/print/list'   //AD列表
export const GET_AD_DETAIL = '/web/boss/ad/print/info' //获取AD详情
export const DELETE_AD = '/web/boss/ad/print/del'      //删除AD
export const ADD_AD = '/web/boss/ad/print/add'         //添加AD
export const EDITE_AD = '/web/boss/ad/print/update'    //修改AD


// 广告ICON入口
export const GET_ICON_LIST = '/web/boss/ad/icon/list'   //ICON列表
export const GET_ICON_DETAIL = '/web/boss/ad/icon/info' //获取ICON详情
export const DELETE_ICON = '/web/boss/ad/icon/del'      //删除ICON
export const ADD_ICON = '/web/boss/ad/icon/add'         //添加ICON
export const EDITE_ICON = '/web/boss/ad/icon/update'    //修改ICON
export const ICON_HISTORY_LIST = '/web/boss/ad/icon/log/list'    //历史记录列表
export const REFRESH_ICON = "/web/boss/ad/icon/refresh"      //同步ICON
export const UPDATE_STATE = "/web/boss/ad/icon/update/state"    //更改状态
export const GET_ICON_HISTORY_DETAIL = '/web/boss/ad/icon/log/info' //获取ICON历史记录详情

export const BATCH_REFRESH = "/web/boss/job/refresh/batch"  //批量刷新岗位

export const OPERATION_LIST = "/web/boss/sys/log/list"  //操作日志

export const GET_PLACEHOLDER_LIST = "/web/boss/placeholder/list"  //获取搜索文字列表
export const ADD_PLACEHOLDER = "/web/boss/placeholder/add"  //新增搜索文字
export const UPDATE_PLACEHOLDER = "/web/boss/placeholder/update"  //编辑搜索文字
export const CHANGE_PLACEHOLDER_STATUS = "/web/boss/placeholder/upOrDown"  //上架或下架搜索文字
// BI系统
export const GET_AUTHORIZATION_ACCOUNT = '/web/boss/bi/empower/count'//用户授权数
export const GET_RESUME_COMPLETE_MOUNT = '/web/boss/bi/resume/finish/count'//简历完成数
export const GET_DELIVERY_CITY_DATA = '/web/boss/bi/apply/city/top'//投递职位城市
//export const GET_DELIVERY_CATEGORY_DATA='/web/boss/bi/apply/category/top'//投递职位分类
export const GET_DELIVERY_CATEGORY_DATA = '/web/boss/bi/apply/categoryIndustry/top'//投递职位分类
export const GET_DELIVERY_CATEGORY_INDUSTRY_DATA = "/web/boss/bi/apply/category/top"   //求职端统计--根据行业显示前10名职位
//export const GET_PBLISH_JOB_MOUNT='/web/boss/bi/job/state/group'//发布岗位数
export const GET_PBLISH_JOB_MOUNT = '/web/boss/bi/job/count'//发布岗位数
export const GET_JOB_CITY_DATA = '/web/boss/bi/job/city/group'//岗位所在地域
//export const GET_JOB_CATEGORY_DATA='/web/boss/bi/job/category/group'//岗位所在分类
export const GET_JOB_CATEGORY_INDUSTRY = '/web/boss/bi/job/publish/industry'//岗位发布分类-行业
export const GET_JOB_CATEGORY_JOB = '/web/boss/bi/job/publish'   //岗位发布分类-职位
export const GET_RESUME_DELIVERY_MOUNT = '/web/boss/bi/resume/user/count'//简历投递数
export const GET_RANKING_JOB = '/web/boss/bi/job/apply/group'//职位被投递排行榜
//新BI
export const GET_PROVINCE_DATA = '/web/boss/bi/job/distribute/province'//企业端统计-岗位分布区域-省份
export const GET_CITY_DATA = '/web/boss/bi/job/distribute/city'//企业端统计-岗位分布区域-城市
export const GET_AREA_DATA = '/web/boss/bi/job/distribute/area'//企业端统计-岗位分布区域-城市

export const GET_ATTACHMENT_LIST = "/web/boss/resume/attachement/list";  //附件简历列表
export const SET_ATTACHMENT_STATUS = "/web/boss/resume/attachement/enable";  //设置附件简历状态
export const PREVIEW_ATTACHMENT = "/web/boss/resume/preview/attachement";  //预览附件简历

//第三方管理
export const GET_PUSH_JOB_LIST = "/web/boss/push/job/list";  //获取推荐岗位列表
export const PUSH_JOB = "/web/boss/push/job/push";     //推送岗位
export const PUSH_JOB_STATE = "/web/boss/push/job/state";   //更新推送设置
export const DELETE_UNABLE_JOB = "/web/boss/push/job/delete";   //删除下架岗位


//人才库
export const GET_COMPANY_ROLE_LIST = "/web/boss/company/role/list";   //企业身份列表
export const GET_COMPANY_ROLE_LIST_ALL = "/web/boss/company/role/listAll";   //企业身份列表所有
export const ADD_COMPANY_ROLE = "/web/boss/company/role/add";   //新增企业身份列表
export const UPDATE_COMPANY_ROLE = "/web/boss/company/role/update";   //更新企业身份列表
export const DEL_COMPANY_ROLE = "/web/boss/company/role/delete";   //删除身份列表
export const GET_SIGN_COMPANY_LIST = '/web/boss/premiumUser/company/list';   //签约企业列表
export const ADD_SIGN_COMPANY = '/web/boss/premiumUser/company/add';   //新增签约企业
export const UPDATE_SIGN_COMPANY = '/web/boss/premiumUser/company/update';   //更新签约企业
export const GET_COMPANY_SIGN_INFO = "/web/boss/premiumUser/company/info";   //查询企业签约信息
export const GET_COMPANY_SIGN_INFO_ID = "/web/boss/premiumUser/company/info/companyId";   //查询企业签约信息企业ID
export const GET_OPERATION_LIST = "/web/boss/premiumUser/company/log";    //操作记录
export const DOWNLOAD_LOGS = "/web/boss/premiumUser/company/log/download";   //导出操作记录


//求职意向
export const GET_INTENTION_LIST = "/web/boss/intent/list";  //求职意向列表
export const GET_INTENTION_DETAIL = "/web/boss/intent/info"

//生成图形验证码
export const CREAT_CAPTCHA_IMG = "/web/boss/captchaCode/create";  //图形验证码
export const ACCOUNT_LOGIN = "/web/boss/login/password";  //账号登录
export const MOBILE_LOGIN = "/web/boss/login/smsCode";    //手机验证码登录
export const SEND_SMS_CODE = "/web/boss/smsCode/send";    //发送短信验证码

// 视频小贴士
export const GET_VIDEO_TIP_LIST = "/web/boss/video/tip/list"   //列表
export const CREAT_VIDEO_TIP = "/web/boss/video/tip/add";  //添加视频小贴士
export const DELETE_VIDEO_TIP = "/web/boss/video/tip/delete";  //删除视频小贴士
export const UPDATE_VIDEO_TIP = "/web/boss/video/tip/update";    //更新视频小贴士

// 视频提示内容
export const GET_NoticeBar_LIST = "/web/boss/video/notice/list"   //列表
export const CREAT_NoticeBar = "/web/boss/video/notice/add";  //添加视频小贴士
export const DELETE_NoticeBar = "/web/boss/video/notice/delete";  //删除视频小贴士
export const UPDATE_NoticeBar = "/web/boss/video/notice/update";    //更新视频小贴士


// 简历视频
export const GET_RESUME_VIDEO = "/web/boss/resume/video"   //列表

// 评价列表
export const GET_EVALUATE_LIST = "/web/boss/score/job/score"
export const POST_EVALUATE="/web/boss/score/apply"