import React,{useState, useEffect} from "react";
import logo from "../../assets/image/logo.png";
import {Menu, Layout} from "antd";
import {Link,withRouter} from "react-router-dom";
import "./sideMenu.less"
const {Sider} = Layout;

function SideMenu(props) {
  const [openKeys, setOpenKeys] = useState(['1']);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(function() {
    const path = props.location.pathname;

    props.menuData.forEach(menu => {
      menu.subMenu?.forEach(item => {
        if(item.path === path) {
          setOpenKeys([menu.id]);
          setSelectedKeys([item.id]);
        }
      })
    })
  });
  const MenuItemClick = (item) => {
    setSelectedKeys([item.key]);
  }

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (props.menuData.map(item => item.id).indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  return (
    <Sider style={{overflow:"auto"}}>
      <div className="logo">
        <img src={logo} alt=""/>
      </div>
      <Menu
        theme="dark"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode="inline"
        onOpenChange={onOpenChange}
        onClick={(item) => MenuItemClick(item)}>
        {
          
          props.menuData&&props.menuData.length>0?props.menuData?.map(item => {
            return (
              <Menu.SubMenu key={item.id + ''} title={item.menuName}>
                {
                  item.subMenu?.map(menu => (<Menu.Item key={menu.id}>
                    <Link to={menu.menuRouter}>{menu.menuName}</Link>
                  </Menu.Item>))
                }
              </Menu.SubMenu>
            )
          }):''
        }
      </Menu>
    </Sider>
  )
}

export default withRouter(SideMenu)