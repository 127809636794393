import React, {Component} from "react";
import {
  Form, message
} from 'antd';
import ProForm, {ProFormText, ProFormSelect, ProFormUploadDragger,ProFormDependency} from '@ant-design/pro-form';
import {FooterToolbar} from '@ant-design/pro-layout';
import AddressCascader from "../../../components/Address/Address";
import {withRouter} from "react-router-dom";
import {GET_COMPANY_INFO, GEt_INDUSTRY_LIST, UPDATE_COMPANY_INFO} from "../../../service";
import {connect} from "react-redux";
import {getQueryVariable} from "../../../utils";
import "./InfoSetting.less";

class InfoSetting extends Component {
  account = getQueryVariable(this.props.history.location.search, "account");
  cname = decodeURIComponent(getQueryVariable(this.props.history.location.search, "cname"));
  provinceId = "";
  cityId = "";
  areaId = "";
  locationCode = ""

  constructor(props) {
    super(props);
    this.state = {
      industryOptions: [],
      provinceId: "",
      cityId: "",
      areaId: "",
      locationCode: ""
    }
  }

  componentDidMount() {
    this.getIndustryList()
  }

  getIndustryList() {
    React.fetch({
      url: GEt_INDUSTRY_LIST
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          industryOptions: res.data.map(item => ({
            label: item.chineseName,
            value: item.id
          }))
        })
      }
    })
  }

  render() {
    return (
      <div className="info-setting-page">
        <div className="form">
          <ProForm
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout="horizontal"
            submitter={{
              render: (_, dom) => <FooterToolbar style={{width: 'calc(100% - 200px)'}}>{dom}</FooterToolbar>,
            }}
            onFinish={(values) => {
              console.log(values)
              React.fetch({
                url: UPDATE_COMPANY_INFO,
                method: "post",
                data: {
                  id: Number(getQueryVariable(this.props.location.search, "companyId")),
                  companyAccountId: Number(getQueryVariable(this.props.location.search, "accountId")),
                  name: values.company,
                  provinceId: this.state.provinceId,
                  cityId: this.state.cityId,
                  areaId: this.state.areaId,
                  locationCode: this.state.locationCode,
                  address: values.street,
                  industryId: values.industry,
                  companyType: values.character,
                  selectCert: values.authentication,
                  faceImg: values.logo[0]?.uid < 0 ? values.logo[0]?.name : values.logo[0]?.response?.data?.fileName,
                  businessLicenseUrl: values.authentication===1?(values.cover[0]?.uid < 0 ? values.cover[0]?.name : values.cover[0]?.response?.data?.fileName):'',
                }
              }).then(res => {
                if (res.code === 200) {
                  message.success("修改成功！")
                }
              })

            }}
            onFieldsChange={(changedFields) => {
              if (changedFields[0].name[0] === "address") {
                this.locationCode = changedFields[0].value.slice(-1)[0].code;
                this.provinceId = changedFields[0].value[0].value;
                this.cityId = [1, 2, 3, 4].indexOf(changedFields[0].value[0].value) >= 0 ? changedFields[0].value[0].value : changedFields[0].value[1].value;
                this.areaId = changedFields[0].value.slice(-1)[0].value;
              }
            }}
            request={async () => {
              const res = await React.fetch({
                url: GET_COMPANY_INFO,
                method: "post",
                data: {
                  companyId: getQueryVariable(this.props.location.search, "companyId")
                }
              })
              const result = res.data;

              this.setState({
                provinceId: result.provinceId,
                cityId: result.cityId,
                areaId: result.areaId,
                locationCode: result.locationCode,
              })
              return {
                name: this.cname,
                mobile: this.account,
                company: result.name,
                address: [1, 2, 3, 4].indexOf(result.provinceId) >= 0 ? [result.provinceId, result.areaId] : [result.provinceId, result.cityId, result.areaId],
                street: result.address,
                industry: result.industryId,
                character: result.companyType?.code,
                authentication: result.isCert,
                logo: result.faceImgUrl ? [
                  {
                    uid: '-1',
                    name: result.faceImg,
                    status: 'done',
                    url: result.faceImgUrl,
                  }
                ] : [],
                cover: result.businessLicenseFileUrl ? [
                  {
                    uid: '-1',
                    name: result.businessLicenseUrl,
                    status: 'done',
                    url: result.businessLicenseFileUrl,
                  }
                ] : []
              }
            }}
          >
            <ProFormText
              disabled
              name="name"
              label="联系人姓名"
              placeholder="请输入姓名"
            />
            <ProFormText
              disabled
              name="mobile"
              label="联系人手机号"
              placeholder="请输入手机号"
            />
            <ProFormText
              name="company"
              label="企业名称"
              placeholder="请输入企业名称"
            />
            <Form.Item
              name="address"
              label="企业地址"
              rules={[{required: true, message: '请选择城市!', type: 'array'}]}
            >
              <AddressCascader/>
            </Form.Item>
            <ProFormText
              name="street"
              label="详细地址"
              placeholder="请输入详细地址"
            />
            <ProFormSelect
              name="industry"
              label="所属行业"
              options={this.state.industryOptions}
              placeholder="选择行业"
              rules={[{required: true, message: '请选择行业'}]}
            />
            <ProFormSelect
              name="character"
              label="企业性质"
              options={[
                {label: '国企', value: 1},
                {label: '私企', value: 2},
                {label: '外资', value: 3},
                {label: '合营', value: 4},
                {label: '其他', value: 0},
              ]}
              placeholder="选择企业性质"
              rules={[{required: true, message: '请选择行业'}]}
            />
            <ProFormSelect
              name="authentication"
              label="是否认证"
              options={[
                {label: '未认证', value: 0},
                {label: '已认证', value: 1},
              ]}
              placeholder="是否认证"
              rules={[{required: true, message: '请选择是否认证'}]}
            />
            <ProFormUploadDragger
              label="企业图片"
              max={1}
              action={React.baseUrl + "/web/boss/upload/img"}
              fieldProps={{
                name: 'file',
                listType: 'picture',
                headers: {
                  "x-zp-ats-auth-token": this.props.token
                }
              }}
              name="logo"
              description="支持jpg,png格式，小于1M的图片"/>
            <ProFormDependency name={['authentication']}>
              {({ authentication }) => {
                if (authentication === 0) {
                  return null;
                }
                return <ProFormUploadDragger
                  label="企业认证"
                  name="cover"
                  action={React.baseUrl + "/web/boss/upload/img"}
                  max={1}
                  fieldProps={{
                    name: 'file',
                    listType: 'picture',
                    headers: {
                      "x-zp-ats-auth-token": this.props.token
                    }
                  }}
                  description="支持jpg,png格式，小于2M的图片"/>
              }}
            </ProFormDependency>

          </ProForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.login.token
})
export default connect(mapStateToProps, null)(withRouter(InfoSetting))