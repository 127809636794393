import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {Button, Space, Cascader} from 'antd';
import ProTable from '@ant-design/pro-table';
import {ProFormDigitRange, ProFormSelect} from '@ant-design/pro-form';
import {connect} from "react-redux";
import {
  GET_INTENTION_LIST,
  GET_JOB_CATEGORY_ALL,
  PROVINCE_OPTiON,
  CITY_OPTION
} from "../../../service";
import "./index.less";


class IntentionList extends Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      CategoryOptions: [],
      cityOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 48,
          hideInSearch: true,
        },
        {
          title: '手机',
          dataIndex: 'mobile',
          ellipsis: true,
          hideInSearch: true,
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          hideInSearch: true,
        },
        {
          title: '求职意向',
          dataIndex: 'jobType',
          valueType: 'select',
          fieldProps: {
            options: this.props.jobTypeOptions
          },
          render: (text, record) => {
            return record.jobType && record.jobType.desc
          }
        },
        {
          title: '工作城市',
          dataIndex: 'cityName',
          hideInSearch: true,
        },
        {
          title: '期望职位',
          dataIndex: 'position',
          hideInTable: true,
          valueType: 'cascader',
          renderFormItem: () => {
            return <Cascader
              options={this.state.CategoryOptions}
              placeholder="请选择期望职位"
              fieldNames={{label: 'postName', value: 'postCode', children: 'childDtoList'}}
              showSearch={this.filterCategory}
            />
          },
        },
        {
          title: '期望行业',
          dataIndex: 'industry',
          valueType: 'select',
          renderFormItem: () => {
            return <ProFormSelect
              options={this.state.CategoryOptions?.map(item => ({
                value: item.postCode,
                label: item.aliasName || "-"
              }))}
            />
          },
          render: (text, record) => {
            return record.industry?.map(item => item.aliasName).join(", ")
          }
        },
        {
          title: '工作城市',
          dataIndex: 'city',
          hideInTable: true,
          renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
            if (form.getFieldValue("jobType") === 2) {
              return null
            }
            return <Cascader
              options={this.state.cityOptions}
              placeholder="请选择城市"
              loadData={this.loadData}
            />
          },
        },
        {
          title: '期望职位',
          dataIndex: 'position',
          hideInSearch: true,
          render: (text, record) => {
            return record.position?.postName
          }
        },
        {
          title: '薪资要求',
          dataIndex: 'salary',
          valueType: 'digit',
          renderFormItem: () => {
            return <ProFormDigitRange/>
          },
          render: (text, record) => {
            return (+record.salaryMin === 0 || +record.salaryMax === 0) ? '面议' : (record.salaryMin === record.salaryMax) ? record.salaryMin + 'k' : record.salaryMin + " - " + record.salaryMax + "k"
          }
        },
        {
          title: '更新时间',
          dataIndex: 'updatedTime',
          hideInSearch: true,
        },
        {
          title: '操作',
          width: 240,
          fixed: "right",
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record, _) => (
            <Space>
              <Button type="primary" onClick={() => this.handleDetail(record)}>详情</Button>
              <Button type="default" onClick={() => this.handleView(record)}>查看简历</Button>
            </Space>
          ),
        },

      ],
      btnLoading: {}
    }
  }

  componentDidMount() {
    this.getCategoryOptions(1);
    this.loadProvinceData();
  }

  getCategoryOptions = type => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_JOB_CATEGORY_ALL,
        method: "post",
        data: {
          jobType: type
        }
      }).then(res => {
        this.setState({
          CategoryOptions: res.data
        })
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  loadData = async selectedOptions => {
    const {cityOptions} = this.state;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let result = await this.loadCityData(targetOption.id)

    targetOption.loading = false;
    targetOption.children = result;
    this.setState({
      cityOptions: [...cityOptions]
    })
  }

  loadCityData = (id) => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: CITY_OPTION,
        method: "post",
        data: {
          provinceId: id
        }
      }).then(res => {
        if (res.code === 200) {
          resolve(res.data?.slice(1).map(item => {
            return {
              label: item.name + item.suffix,
              value: item.code,
              id: item.id,
              code: item.code,
              isLeaf: true,
            }
          }))
        } else {
          reject()
        }
      }).catch(err => {
        reject()
      })
    })
  }

  loadProvinceData = () => {
    React.fetch({
      url: PROVINCE_OPTiON,
    }).then(res => {
      const result = res.data && res.data.map(item => {
        return {
          isLeaf: [1, 2, 3, 4].indexOf(item.id) > -1,
          label: item.name + item.suffix,
          value: item.code,
          id: item.id,
          code: item.code,
        }
      })
      this.setState({
        cityOptions: result
      })
    })
  }

  filterCategory = (inputValue, path) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handleDetail = (record) => {
    this.props.history.push("/intentionDetail?id=" + record.applicantAccountId + "&i=" + record.id);
  }
  handleView = (record) => {
    this.props.history.push("/resumeDetail?resume=" + +record.resumeId);
  }

  onFormValuesChange = changedValues => {
    if ('jobType' in changedValues) {
      this.getCategoryOptions(changedValues.jobType);
    }
  }

  render() {
    return (
      <div className="intention-page">
        <ProTable
          actionRef={this.ref}
          form={{
            onValuesChange: (changedValues) => this.onFormValuesChange(changedValues)
          }}
          columns={this.state.columns}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_INTENTION_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                jobType: params.jobType,
                locationCode: params.city?.slice(-1)[0],
                industry: params.industry,
                position: params.position?.slice(-1)[0],
                salaryMin: params.salary && params.salary[0],
                salaryMax: params.salary && params.salary[1]
              }
            })

            return Promise.resolve({
              data: res.data,
              success: true,
              total: 500
            });
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          dateFormatter="string"
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    jobTypeOptions: state.global.jobTypeOptions
  }
}


export default connect(mapStateToProps, null)(withRouter(IntentionList))