import React, {Component} from "react";
import {Button, Space} from 'antd';
import {withRouter} from "react-router-dom";
import ProTable from '@ant-design/pro-table';
import {APPLICANT_LIST} from "../../../service";
import "./index.less";
import {connect} from "react-redux";

class EmployeeManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '登录账号',
          dataIndex: 'account',
          hideInSearch: true,
        },
        {
          title: '关键字',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: "请输入姓名或手机号"
          }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          hideInSearch: true,
        },
        {
          title: '性别',
          dataIndex: 'sex',
          hideInSearch: true,
          render: ((text, record) => {
            return record.sex && record.sex.desc
          })
        },
        {
          title: '最高学历',
          dataIndex: 'education',
          valueType: 'select',
          fieldProps: {
            options: this.props.educationOptions
          },
          render: ((text, record) => {
            return record.education && record.education.desc
          })
        },
        {
          title: '身份',
          dataIndex: 'identity',
          valueType: 'select',
          fieldProps: {
            placeholder: "选择身份",
            options: this.props.identityOptions
          },
          render: ((text, record) => {
            return record.identity && record.identity.desc
          })
        },
        {
          title: '注册时间',
          dataIndex: 'createdTime',
          valueType: 'dateRange',
          fieldProps: {
            placeholder: ['请选择开始日期', '请选择结束日期'],
          },
          search: {
            transform: (value) => ({ beginDate: value[0], endDate: value[1] }),
          },
          render: ((text, record) => {
            return record.createdTime
          })
        },
        {
          title: '简历完成度',
          dataIndex: 'percent',
          valueType: 'select',
          fieldProps: {
            options: [
              {label: "完成度<=20%", value: 1},
              {label: "21%<完成度<=40%", value: 2},
              {label: "41%<完成度<=60%", value: 3},
              {label: "61%<完成度<=80%", value: 4},
              {label: "完成度>81%", value: 5},
            ]
          },
          search: {
            transform: (value) => {
              switch (value){
                case 1:
                  return {minPercent: 0, maxPercent: 20}
                case 2:
                  return {minPercent: 21, maxPercent: 40}
                case 3:
                  return {minPercent: 41, maxPercent: 60}
                case 4:
                  return {minPercent: 61, maxPercent: 80}
                case 5:
                  return {minPercent: 81, maxPercent: 100}
                default:
                  return {minPercent: 0, maxPercent: 20}
              }}
          },
          render: ((text, record) => {
            return (record.percent || 0) + "%"
          })
        },
        {
          title: '操作',
          width: 180,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record) => (
            <Space>
              <Button type="default" onClick={() => this.handleIntention(record)}>求职意向</Button>
              <Button type="primary" onClick={() => this.handleView(record)}>查看简历</Button>
              <Button type="default" danger onClick={() => this.handleRecord(record)}>投递记录</Button>
            </Space>
          ),
        },
      ]
    }
  }

  handleIntention = record => {
    this.props.history.push("/intentionDetail?id=" + record.id)
  }

  handleView = record => {
    this.props.history.push("/resumeDetail?resume=" + record.resumeId)
  }
  handleRecord = record => {
    this.props.history.push("/sendRecord?name=" + record.name)
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  render() {
    return (
      <div className="company-manage-page">
        <ProTable
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            console.log(params)
            const res = await React.fetch({
              url: APPLICANT_LIST,
              method: "post",
              data: {
                keyword: params.keyword,
                page: params.page,
                size: 20,
                education: params.education,
                identity: params.identity,
                beginDate: params.beginDate,
                endDate: params.endDate,
                minPercent:params.minPercent,
                maxPercent:params.maxPercent
              }
            })

            return Promise.resolve({
              data: res.data,
              success: true,
            })
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps =  state => {
  return {
    educationOptions: state.global.educationOptions,
    identityOptions: state.global.identityOptions,
  }
}

export default connect(mapStateToProps, null)(withRouter(EmployeeManage))