import React, { Component } from "react";
import ProTable from "@ant-design/pro-table";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Space, Popconfirm, Modal, message, Input, Upload } from "antd";
import {
  GET_SENSITIVEWORDS_LIST,
  ADD_SENSITIVEWORDS,
  SENSITIVEWORDS_COUNT,
  DELETE_SENSITIVEWORDS,
  UPDATE_SENSITIVEWORDS,
  UPLOAD_SENSITIVEWORDS,
} from "../../../service";

import "./index.less";

class SensitiveWords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      sensitiveWords: "",
      loading:false,
      selectedRowKeys: [],
      sensitiveWordsCount: 0,
      editeModalVisible:false,
      editeWords:'',
      editeWordsId:-1,
      columns: [
        {
          dataIndex: "id",
          title: "ID",
          width: 48,
          hideInSearch: true,
        },
        {
          dataIndex: "text",
          title: "敏感词",
          width: 48,
          hideInSearch: false,
          valueType: 'text',
        },
        {
          dataIndex: "createdBy",
          title: "创建人",
          width: 48,
          hideInSearch: true,
        },
        {
          dataIndex: "updatedTime",
          title: "更新时间",
          width: 48,
          hideInSearch: true,
        },
        {
          title: "操作",
          valueType: "option",
          width: 200,
          align: "center",
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() => this.handleEditor(record)}>
                编辑
              </Button>
              <Popconfirm
                title="确定杀出该项吗？"
                okText="删除"
                cancelText="取消"
                onConfirm={() => this.handleDelete(record)}
              >
                <Button type="primary" danger>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    this.getSensitiveWordsCount();
  }
  ref = React.createRef();
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page,
    });
  };
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page,
    });
  };
  handleEditor = (record) => {
    this.setState({ editeModalVisible:true,editeWords:record.text,editeWordsId:record.id });
  };
  getSensitiveWordsCount = () => {
    React.fetch({
      url: SENSITIVEWORDS_COUNT,
      method: "get",
    }).then((res) => {
      if (res) {
        this.setState({ sensitiveWordsCount: res.data });
      }
    });
  };
  addSensitiveWords = () => {
    if(this.state.sensitiveWords===''){
      message.error("请输入要添加的敏感词!");
      return
    }
    React.fetch({
      url: ADD_SENSITIVEWORDS,
      method: "post",
      data: {
        text: this.state.sensitiveWords,
      },
    }).then((res) => {
      if (res) {
        message.success("添加成功");
        this.ref.current.reload();
        this.setState({ modalVisible: false });
      }
    });
  };
  handleDelete = (record) => {
    this.deleteSensitiveWords([record.id]);
  };
  deleteSelectRecord = () => {
    this.deleteSensitiveWords(this.state.selectedRowKeys);
  };
  handleFileChange=({ file, fileList })=>{
    if(file.response&&file.response.code===200){
      this.setState({ loading: false,page:1 },()=>{
        this.ref.current.reload();
      });
      
    }
  }
  beforeUpload = (file) => {
    let fileName = file.name;
    if (
      fileName.indexOf("xlsx")>-1 ||
      fileName.indexOf("xls")>-1 ||
      fileName.indexOf("xltx")>-1 ||
      fileName.indexOf("xlt")>-1 ||
      fileName.indexOf("xlsm")>-1 ||
      fileName.indexOf("xlsb")>-1 ||
      fileName.indexOf("xltm")>-1 ||
      fileName.indexOf("csv")>-1
    ) {
      this.setState({ loading: true })
      return true;
    } else {
      message.error("请上传表格类型!");
      return false;
    }
  };
  editeSensitiveWords=()=>{
    React.fetch({
      url: UPDATE_SENSITIVEWORDS,
      method: "post",
      data: {
        id: this.state.editeWordsId,
        text:this.state.editeWords
      },
    }).then((res) => {
      if (res) {
        message.success("修改成功");
        this.ref.current.reload();
        this.setState({ editeModalVisible: false });
      }
    });
  }
  deleteSensitiveWords(arr) {
    if(arr.length===0){
      message.error("请选择需要删除的敏感词!");
      return
    }
    React.fetch({
      url: DELETE_SENSITIVEWORDS,
      method: "post",
      data: {
        ids: arr,
      },
    }).then((res) => {
      if (res) {
        message.success("删除成功");
        this.ref.current.reload();
        this.setState({ selectedRowKeys: [] });
      }
    });
  }

  render() {
    return (
      <div className="sensitive-words">
        <ProTable
          rowSelection={{
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) =>
              this.setState({ selectedRowKeys: selectedRowKeys }),
          }}
          headerTitle={<span>共录数:{this.state.sensitiveWordsCount}</span>}
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_SENSITIVEWORDS_LIST,
              method: "post",
              data: {
                page: params.page ? params.page : 1,
                size: 20,
                text: params?.text,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: "auto",
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                type="danger"
                onClick={() => {
                  this.deleteSelectRecord();
                }}
              >
                批量删除
              </Button>,
              <Button
                key="button"
                type="primary"
                onClick={() => this.setState({ modalVisible: true })}
              >
                添加敏感词
              </Button>,
              <Upload
                name="file"
                className="avatar-uploader"
                showUploadList={false}
                action={React.baseUrl + UPLOAD_SENSITIVEWORDS}
                headers={{
                  "x-zp-ats-auth-token": this.props.token,
                }}
                beforeUpload={this.beforeUpload}
                onChange={({ file, fileList })=>{this.handleFileChange({ file, fileList })}}
              >
                <Button loading={this.state.loading} disabled={this.state.loading}key="button">批量导入</Button>
              </Upload>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          title="添加敏感词"
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => this.addSensitiveWords()}
        >
          <Input.TextArea
            placeholder='请输入文字，若需添加多个词汇，请用","区分'
            onChange={(e) => this.setState({ sensitiveWords: e.target.value })}
          />
        </Modal>
        <Modal
          title="编辑敏感词"
          visible={this.state.editeModalVisible}
          onCancel={() => this.setState({ editeModalVisible: false })}
          onOk={() => this.editeSensitiveWords()}
        >
          <Input
            value={this.state.editeWords}
            onChange={(e) => this.setState({ editeWords: e.target.value })}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  educationOptions: state.global.educationOptions,
});
export default connect(mapStateToProps, null)(withRouter(SensitiveWords));
