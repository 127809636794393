import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Modal,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText, ProFormSelect} from '@ant-design/pro-form';
import {GET_ACCOUNT,GET_ROLE_LIST,CREAT_ACCOUNT,GET_USER_INFO,EDITE_ACCOUNT} from "../../../service";
import "./index.less"

class UserAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '登录账号',
          dataIndex: 'account',
          ellipsis: true,
          hideInSearch: true,
          formItemProps: {
            rules: [
              {
                required: true,
                message: '此项为必填项',
              },
            ],
          },
        },
        {
          title: '姓名',
          dataIndex: 'accountName',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          hideInSearch: true,
        },
        {
          title: '最后登录IP',
          dataIndex: 'ip',
          hideInSearch: true,
        },
        {
          title: '有效状态',
          hideInSearch: true,
          dataIndex: 'isEnable',
          render: (_, record) => (
            <span>{record.isEnable === 0 ?'无效': '有效'}</span>
          ),
        },
        {
          title: '注册时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '更新时间',
          hideInSearch: true,
          dataIndex: 'updatedTime',
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() =>this.handleEditor(record)}>修改</Button>
              {/* <Popconfirm title="确定杀出该项吗？" okText="删除" cancelText="取消" onConfirm={() => this.handleDelete(record)}>
                <Button type="primary" danger>删除</Button>
              </Popconfirm> */}
            </Space>
          ),
        },
      ]
    }
  }
  formRef = React.createRef()
  ref = React.createRef()
  componentDidMount() {

  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    },() => {
      this.formRef.current.setFieldsValue({
        name: "",
        account: "",
        password: "",
        mobile: "",
        status: "",
        role: ""
      })
    })
  }

  handleEditor = (data) => {
    console.log(data)
    React.fetch({
      url: GET_USER_INFO,
        method: "post",
        data: {
          id:data.id
        }
    }).then(res=>{
      if(res.code===200){
        this.setState({
          eType: 2,
          eId: data.id,
          modalVisible: true
        }, () => {
          this.formRef.current.setFieldsValue({
            name: data.accountName,
            account: data.account,
            password: "",
            mobile: data.mobile,
            status: data.isEnable.toString(),
            role:res.data.roleId
          })
        })
      }
    })
  }

  submit = (values) => {
    React.fetch({
        url: CREAT_ACCOUNT,
        method: "post",
        data: {
          account: values.account,
          accountName: values.name,
          accountPassword: values.password,
          isEnable: values.status,
          mobile: values.mobile,
          roleId: values.role
        }}
    ).then(res => {
      console.log(res);
      if(res.code === 200) {
        message.success("账号添加成功！");
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }
  edite = (values) => {
    React.fetch({
        url: EDITE_ACCOUNT,
        method: "post",
        data: {
          id:this.state.eId,
          account: values.account,
          accountName: values.name,
          accountPassword: values.password?values.password:null,
          isEnable: values.status,
          mobile: values.mobile,
          roleId: values.role
        }}
    ).then(res => {
      console.log(res);
      if(res.code === 200) {
        message.success("账号编辑成功！");
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }
  handleDelete = (data) => {
    console.log(data);
  }


  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div className="user-auth-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_ACCOUNT,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                accountName: params.accountName
              }
            })
            return {
              data: res.data,
              success: true,
            }
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                添加管理员
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title={this.state.eType===2?'添加':'编辑'}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout='horizontal'
            onFinish={(values) => {
              console.log(this.state.eType)
              this.state.eType===1?this.submit(values):this.edite(values);
            }}
            submitter={{
              render: (props, doms) => {
                return <div style={{"display": "flex", "justifyContent": "center", "gap": "8px"}}>
                  {[...doms]}
                </div>;
              },
            }}
          >
            <ProFormText name="name" label="姓名" placeholder="请输入名称"/>
            <ProFormText name="account" label="登录账号" placeholder="请输入账号名"/>
            <ProFormText.Password name="password" label="登录密码" placeholder="请输入密码"/>
            <ProFormText name="mobile" label="手机号" placeholder="请输入手机号"/>
            <ProFormSelect
              name="status"
              label="有效状态"
              valueEnum={{
                1: '有效',
                0: '停用',
              }}
              placeholder="请选择"
              rules={[{required: true, message: '请选择账号状态'}]}
            />
            <ProFormSelect
              name="role"
              label="角色"
              request={async () => {
                const res = await React.fetch({
                  url: GET_ROLE_LIST
                })
                return res.data.map(item => ({
                  label: item.roleName,
                  value: item.id
                }));
              }}
              placeholder="请选择"
              rules={[{required: true, message: '请选择角色'}]}
            />
          </ProForm>

        </Modal>
      </div>
    )
  }
}

export default UserAuth;