import React, {Component} from "react";
import {connect} from "react-redux";
import {ToTopOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import ProTable from '@ant-design/pro-table';
import {getQueryVariable} from "../../../utils";
import download from 'downloadjs';
import {GET_OPERATION_LIST, DOWNLOAD_LOGS, GET_COMPANY_INFO} from "../../../service";
import "./index.less"
import {withRouter} from "react-router-dom";

class OperationLogs extends Component {
  companyId = getQueryVariable(this.props.history.location.search, "id") || undefined;
  downLoadParams = {};

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      downloadLoading: false,
      eType: 1,   //1添加2编辑
      eId: "",
      companyName: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '企业名称',
          dataIndex: 'companyName',
          hideInSearch: true,
        },
        {
          title: '关键字',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: this.companyId ? "操作人/操作人联系方式" : "企业名称/操作人/操作人联系方式"
          }
        },
        {
          title: '',
          dataIndex: 'dateRange',
          hideInTable: true,
          valueType: 'dateTimeRange',
          search: {
            transform: (value) => ({beginDate: value[0], endDate: value[1]}),
          },
        },
        {
          title: '操作行为',
          dataIndex: 'operateData',
          hideInSearch: true,
        },
        {
          title: '功能模块',
          dataIndex: 'platformFunction',
          valueType: 'select',
          fieldProps: {
            options: [
              {value: "TALENTS", label: "人才库"},
              {value: "RESUME", label: "简历管理"},
            ]
          }
        },
        {
          title: '终端',
          dataIndex: 'platform',
          hideInSearch: true,
        },
        {
          title: '记录时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },

        {
          title: '操作人',
          hideInSearch: true,
          dataIndex: 'companyAccountName',
        },
        {
          title: '操作人联系方式',
          hideInSearch: true,
          dataIndex: 'companyAccountContactPhone',
        },
        {
          title: '排序方式',
          dataIndex: 'sort',
          hideInTable: true,
          valueType: 'select',
          fieldProps: {
            options: [
              {value: 1, label: "时间降序"},
              {value: 0, label: "时间升序"},
            ]
          }
        },
      ]
    }
  }

  formRef = React.createRef()
  ref = React.createRef()

  componentDidMount() {
    if (this.companyId) {
      this.getCompanyInfo()
    }
  }

  getCompanyInfo = () => {
    React.fetch({
      url: GET_COMPANY_INFO,
      method: "post",
      data: {
        companyId: this.companyId
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          companyName: res.data.name
        })
      }
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  handleExport = () => {
    this.setState({
      downloadLoading: true
    })
    React.fetch({
      url: DOWNLOAD_LOGS,
      method: "post",
      data: Object.assign(this.downLoadParams, {fileName: "log" + new Date().getTime()})
    }).then(res => {
      download((new Blob([res])), "operation_log_" + new Date().getTime() + '.xls');
      this.setState({
        downloadLoading: false
      })
    });
  }

  render() {
    const {columns, downloadLoading, companyName} = this.state;
    return (
      <div className="operationLog-page">
        <ProTable
          actionRef={this.ref}
          columns={columns}
          headerTitle={companyName ? companyName + '操作记录' : ''}
          request={async (params = {}, sort, filter) => {
            for (let key in params) {
              if (params[key] && key !== 'page') {
                this.downLoadParams[key] = params[key]
              }
            }
            const res = await React.fetch({
              url: GET_OPERATION_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                companyId: params.companyId,
                keyword: params.keyword,
                platformFunction: params.platformFunction,
                beginDate: params.beginDate,
                endDate: params.endDate,
                sort: params.sort
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          pagination={false}
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          params={{
            page: this.state.page,
            companyId: this.companyId
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<ToTopOutlined/>}
                type="primary"
                loading={downloadLoading}
                onClick={this.handleExport}
              >
                导出
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(withRouter(OperationLogs))
