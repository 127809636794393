import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {Button, Modal, Space, Image, Popconfirm, notification, message, Cascader,Descriptions} from 'antd';
import ProTable from '@ant-design/pro-table';
import Field from '@ant-design/pro-field';
import {PlusOutlined} from '@ant-design/icons';
import {
  GET_COMPANY_LIST,
  RESET_PASSWORD,
  ADD_COMPANY_ACCOUNT,
  CITY_OPTION,
  PROVINCE_OPTiON, GET_COMPANY_SIGN_INFO_ID, GET_COMPANY_ROLE_LIST_ALL
} from "../../../../service";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import moment from "moment";

class CompanyInfoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      modalSignVisible: false,
      cityOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 48,
          hideInSearch: true,
        },
        {
          title: ' 企业名称',
          dataIndex: 'companyName',
          hideInSearch: true,

        },
        {
          title: '企业logo',
          width: 120,
          dataIndex: 'logo',
          hideInSearch: true,
          render: ((text, record, _, action) => {
            return <Image
              width={100}
              src={record.logo}
            />
          })
        },
        {
          title: '登录账号',
          dataIndex: 'account',
          hideInSearch: true,
        },
        {
          title: '',
          dataIndex: 'keyword',
          hideInTable: true,
          fieldProps: {
            placeholder: "企业名或手机号"
          }
        },
        {
          title: '是否认证',
          dataIndex: 'isCert',
          valueType: 'select',
          fieldProps: {
            options: [
              {label: '已认证', value: 1},
              {label: '未认证', value: 0}
            ]
          },
          render: (text, record, _) => {
            return record.isCert === 0 ? '未认证' : '已认证'
          }
        },
        {
          title: '城市',
          dataIndex: 'city',
          hideInTable: true,
          renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
            return <Cascader
              options={this.state.cityOptions}
              placeholder="请选择城市"
              loadData={this.loadData}
            />
          },
        },
        {
          title: '排序',
          dataIndex: 'sort',
          hideInSearch: true,
        },
        {
          title: '联系人',
          dataIndex: 'name',
          hideInSearch: true,
        },
        {
          title: '签约状态',
          dataIndex: 'status',
          initialValue: 0,
          hideInSearch: true,
          valueType: 'select',
          render: ((text, record) => <Button type="link" onClick={() => this.handleSign(record)}>{record.premiumUserType?.desc}</Button>),
          fieldProps: {
            options: [
              {value: "NOT_SIGNED", label: "未签约"},
              {value: "NOT_STARTED", label: "未开始"},
              {value: "LOSE_EFFICACY", label: "已失效"},
              {value: "UNDERWAY", label: "履约中"},
            ]
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '账号设置',
          width: 100,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record, _, action) => (
            <Popconfirm title="确定重置密码吗？" okText="确定" cancelText="取消" onConfirm={() => this.handleResetPassword(record)}>
              <Button type="primary">重置密码</Button>
            </Popconfirm>

          ),
        },
        {
          title: '操作',
          width: 400,
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record) => (
            <Space>
              <Button type="default" onClick={() => this.handleModifyInfoSetting(record)}>信息修改</Button>
              <Button type="default" danger onClick={() => this.handlePublishWork(record)}>发布职位</Button>
              <Button type="dashed" onClick={() => this.handleToJobList(record)}>职位列表</Button>
              <Button type="primary" onClick={() => this.handleOperationList(record)}>操作记录</Button>
            </Space>
          ),
        },
      ],
      signInfo: {
        name: "",
        identity: "",
        time: ["", ""],
        number: "",
        remainingNumber: "",
        payType: "",
        remark: ""
      }
    }
  }

  formRef = React.createRef();
  tableRef = React.createRef();

  componentDidMount() {
    this.loadProvinceData();
    this.getAllRoleList();
  }

  loadData = async selectedOptions => {
    const {cityOptions} = this.state;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let result = await this.loadCityData(targetOption.value)

    targetOption.loading = false;
    targetOption.children = result;
    this.setState({
      cityOptions: [...cityOptions]
    })
  }

  loadCityData = (id) => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: CITY_OPTION,
        method: "post",
        data:{
          provinceId: id
        }
      }).then(res => {
        if(res.code === 200) {
          resolve(res.data?.slice(1).map(item => {
            return {
              label: item.name + item.suffix,
              value: item.id,
              id: item.id,
              code: item.code,
              isLeaf: true,
            }
          }))
        }else {
          reject()
        }
      }).catch(err => {
        reject()
      })
    })
  }

  handleOperationList = data => {
    this.props.history.push("/operationLogs?id=" + data.id)
  }

  getSignInfo = id => {
    return new Promise((resolve, reject) => {
      React.fetch({
          url: GET_COMPANY_SIGN_INFO_ID,
          method: "post",
          data: {id}
        }
      ).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }
  findRoleId = (name) => {
    return this.roleList.find(item => item.label === name)?.value || ""
  }
  handleSign = async data => {
    const result = await this.getSignInfo(data.id);
    if(result){
      this.setState({
        modalSignVisible: true
      }, () =>{
        this.setState({
          signInfo: {
            identity: this.findRoleId(result.roleName),
            time: [result.premiumBeginTime, result.premiumEndTime],
            number: result.number,
            remainingNumber: result.remainingNumber,
            payType: result.premiumType?.code,
            remark: result.remark
          }
        })
      })
    }else{
      message.error("该企业未签约")
    }
  }

  loadProvinceData = () => {
    React.fetch({
      url: PROVINCE_OPTiON,
    }).then(res => {
      const result = res.data && res.data.map(item => {
        return {
          isLeaf: [1, 2, 3, 4].indexOf(item.id) > -1,
          label: item.name + item.suffix,
          value: item.id,
          code: item.code,
          level: 1
        }
      })
      this.setState({
        cityOptions: result
      })
    })
  }

  handleModifyInfoSetting = record => {
    this.props.history.push("/infoSetting?companyId=" + record.id + "&accountId=" + record.accountId + "&cname=" + record.name + "&account=" + record.account);
  }

  handlePublishWork = record => {
    this.props.history.push("/jobSetting?type=1&cid=" + record.id + "&aid=" + record.accountId)
  }
  handleToJobList = record => {
    this.props.history.push("/jobManage?companyAccountId=" + record.accountId);
  }

  handleResetPassword = record => {
    React.fetch({
      url: RESET_PASSWORD,
      method: "post",
      data: {
        id: record.accountId
      }
    }).then(res => {
      if (res.code === 200) {
        notification.open({
          message: '您的密码已重置！',
          description: <p>您的新密码是 <span style={{color: 'red'}}>{res.data}</span></p>,
          duration: 0,
        });
      }
    })
  }
  handleAdd = () => {
    this.setState({
      modalVisible: true
    })
  }
  modalHide = () => {
    this.setState({
      modalVisible: false,
      modalSignVisible: false
    })
  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  getAllRoleList = () => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_COMPANY_ROLE_LIST_ALL
      }).then(res => {
        if (res.code === 200) {
          const result = res.data.map(item => ({
            value: item.id,
            label: item.roleName
          }))
          resolve(result)
          this.roleList = result;
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }

  render() {
    const {columns,page,modalVisible,modalSignVisible,signInfo} = this.state;
    return (
      <div className="pro-table">
        <ProTable
          actionRef={this.tableRef}
          columns={columns}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_COMPANY_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                isCert: params.isCert,
                keyword: params.keyword,
                provinceId: params.city && params.city[0],
                cityId: params.city && params.city[1],
              }
            })
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page
          }}
          dateFormatter="string"
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                添加联系人账号
              </Button>
            ]
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title="添加账号"
          footer={null}
          visible={modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout='horizontal'
            submitter={{
              render: (props, doms) => {
                return <div style={{textAlign: "right"}}>
                  {[...doms,]}
                </div>;
              },
            }}
            onFinish={async (values) => {
              React.fetch({
                url: ADD_COMPANY_ACCOUNT,
                method: "post",
                data: {
                  contactMan: values.name,
                  mobile: values.mobile,
                  password: values.password,
                  repassword: values.repassword
                }
              }).then(res => {
                if (res.code === 200) {
                  message.success("账号添加成功！");
                  this.formRef.current.resetFields();
                  this.modalHide();
                  this.tableRef.current.reload()
                }
              })
            }}
          >
            <ProFormText
              name="name"
              label="联系人姓名"
              placeholder="请输入姓名"
              rules={[{required: true, message: '请输入联系人姓名'}]}/>
            <ProFormText
              name="mobile"
              label="手机号码"
              placeholder="请输入手机号码"
              rules={[{required: true, message: '请输入手机号码'}]}/>
            <ProFormText.Password
              name="password"
              label="密码"
              placeholder="请输入密码"
              rules={[{required: true, message: '请输入密码'}]}/>
            <ProFormText.Password
              name="repassword"
              label="确认密码"
              placeholder="请确认密码"
              rules={[
                {required: true, message: '请再次输入您的密码'},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次密码输入不一致'));
                  },
                }),]}/>
          </ProForm>
        </Modal>

        <Modal
          title="签约信息"
          footer={<div className="modal-footer"><Button type="primary" onClick={this.modalHide}>关闭</Button></div>}
          visible={modalSignVisible}
          onCancel={this.modalHide}
        >

          <Descriptions column={1}>
            <Descriptions.Item label="签约身份">
              <Field
                text={signInfo.identity}
                mode="read"
                request={
                  async () => {
                    return await this.getAllRoleList()
                  }
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label="签约时间">
              <Field
                text={[
                  moment(signInfo?.time[0]).valueOf(),
                  moment(signInfo?.time[1]).valueOf(),
                ]}
                valueType="dateTimeRange"
              />
            </Descriptions.Item>
            <Descriptions.Item label="结算方式">
              <Field
                text={signInfo.payType + ""}
                mode="read"
                valueEnum={{
                  1: {text: '线上结算'},
                  2: {text: '线下结算'},
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="查看简历次数">
              <Field text={`剩余${signInfo.remainingNumber}次/总${signInfo.number}次`} mode="read" />
            </Descriptions.Item>
            <Descriptions.Item label="备注说明">
              <Field text={signInfo.remark} mode="read" />
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </div>
    )
  }
}

export default withRouter(CompanyInfoTable)