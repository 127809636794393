import React,{Component} from "react";
import {Tabs} from "antd";
import CompanyInfoTable from "./components/CompanyInfoTable";
import ContactsTable from "./components/ContactsTable";
import CooperationTable from "./components/CooperationTable";
import "./index.less"
const { TabPane } = Tabs;

class CompanyManage extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
    }
  }

  handleTabChange = activeKeys => {
    this.setState({
      activeKey: activeKeys
    })
  }

  render() {
    const {activeKey} = this.state
    return (
      <div className="company-manage-page">
        <div className="card-container">
          <Tabs type="card" onChange={this.handleTabChange}>
            <TabPane tab="企业信息列表" key="1">
              {activeKey === '1' && <CompanyInfoTable />}
            </TabPane>
            <TabPane tab="联系人信息列表" key="2">
              {activeKey === '2' && <ContactsTable />}
            </TabPane>
            {/*<TabPane tab="合作企业" key="3" disabled>*/}
            {/*  {activeKey === '3' && <CooperationTable />}*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </div>
    )
  }

}

export default CompanyManage