import axios from "axios";
import React from "react";
import store from "../store";
import {message} from "antd";
import {createHashHistory} from 'history';
import {CREAT_CAPTCHA_IMG,PREVIEW_ATTACHMENT,DOWNLOAD_LOGS} from "../service";

const history = createHashHistory();
const paramType = {
  put: "data",
  post: "data",
  patch: "data",
  get: "params",
  delete: "params"
}
const binary = [CREAT_CAPTCHA_IMG,PREVIEW_ATTACHMENT,DOWNLOAD_LOGS];
class Http {
  constructor() {
    this.pending = [];
  }

  __interceptors(instance) {
    //添加请求拦截器
    instance.interceptors.request.use((config) => {
      //统一添加token （除登录接口外）
      if (binary.includes(config.url)) {
        config.responseType = "blob";
      }
      if (config.url.indexOf("login") < 0) {
        const token = store.getState().login.token;
        token && (config.headers["x-zp-ats-auth-token"] = token);
      }
      //添加取消请求的标记 --功能后续迭代
      config.cancelToken = new axios.CancelToken(cancel => {
        this.pending.push({
          cancel
        })
      })
      return config
    }, function (error) {
      return Promise.reject(error)
    })
    //添加响应拦截器
    instance.interceptors.response.use((response) => {
      if (binary.includes(response.config.url)) {
        return Promise.resolve(response.data)
      }else{
        const {data} = response;
        if (data.code === 200) {
          return data
        } else if (data.code === 401) {
          message.error("登录超时，请重新登录！");
          history.push("/login");
          return "登录超时，请重新登录！"
        } else {
          message.error(data.message);
          return Promise.reject(response.data)
        }
      }
    }, function (error) {
      return Promise.reject(error)
    });
  }


  request(config) {
    const instance = axios.create({
      baseURL: React.baseUrl,
      timeout: 6000,
      headers: {},
    });
    const baseConfig = {
      url: config.url,
      method: config.method || 'get',
      headers: config.headers || {},
    }
    // put post patch请求数据传递使用data字段，其他为params
    let dataConfig = {[paramType[baseConfig.method]]: config.data}
    let __config = Object.assign(baseConfig, dataConfig)

    this.__interceptors(instance);
    return instance.request(__config);

  }
}

const http = new Http();
const request = http.request.bind(http);
export default request