import React, {Component} from "react";
import {GET_PBLISH_JOB_MOUNT} from "../../../service";
import moment from 'moment';
import {DatePicker, Table,Typography} from 'antd';
import "./index.less";
const { Text } = Typography;
class PublishJobMount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      tableData: [],
      column: [
        {
          title: '岗位类型',
          dataIndex: 'type',
          key: 'type',
          width: 120
        },
        {
          title: '数据',
          dataIndex: 'data',
          key: 'data',
          width: 120
        },
      ]
    };
  }

  componentDidMount(){
    this.getData();
  }
  getData = async () => {
    return await React.fetch({
      url: GET_PBLISH_JOB_MOUNT,
      method: "post",
      data: {
        start: this.state.start||"",
        end: this.state.end||"",
      },
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          tableData: [
            {
              key: 1,
              dataIndex: "type",
              type: "全职",
              data: res.data.jobFullCount
            },
            // {
            //   key: 2,
            //   dataIndex: "type",
            //   type: "线上兼职",
            //   data: res.data.jobOnlineCount
            // },
            // {
            //   key: 3,
            //   dataIndex: "type",
            //   type: "线下兼职",
            //   data: res.data.jobOfflineCount
            // },
          ]
        })
      }
    });
  }
  setDate = (val) => {
    this.setState({
      date: val,
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      this.getData()
    })
  }
  render() {
    const {date,tableData, column} = this.state;
    return (
      <div className="delivery-city">
        <DatePicker.RangePicker inputReadOnly={true} allowClear value={date} onChange={(val) => this.setDate(val)}/>
        <div className="publish-count-page">
          <div className="title">招聘中职位数据明细表</div>
          <div className="table">
            <Table columns={column} dataSource={tableData} pagination={false} bordered summary={pageData => {
              let total = 0;
              pageData.forEach(({data}) => {
                total += data;
              });
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>总数</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}><Text type="danger">{total}</Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}/>
          </div>
        </div>
      </div>
    );
  }
}

export default PublishJobMount;
