import React, {Component} from "react";
import {GET_PROVINCE_DATA, GET_CITY_DATA, GET_AREA_DATA} from "../../../service";
import moment from 'moment';
import {Checkbox, DatePicker, Space} from 'antd';
import mapBack from "../../../assets/image/map_back.png";
// 引入 ECharts 主模块
import * as echarts from 'echarts/lib/echarts'
// 引入中国地图数据
import chinaJson from './mapData/china.json'

import 'echarts/lib/component/geo'
import 'echarts/lib/chart/map'

import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import "./index.less";

const CheckboxGroup = Checkbox.Group;

class JobCityInfo extends Component {
  locationId = "";

  constructor(props) {
    super(props);
    this.state = {
      end: '',
      start: '',
      cityWhiteList: [110000, 120000, 500000, 310000],//直辖市
      echartOptions: {
        title: {
          text: '中国地图',
          textStyle: {
            color: '#000',
          },
          textAlign: 'center',
          x: 'center',
          y: 'top',
        },
        legend: {
          orient: 'vertical',
          y: 'bottom',
          x: 'right',
          textStyle: {
            color: '#000',
          },
        },
        visualMap: {
          realtime: true,
          calculable: true,
          inRange: {
            color: ['#B9E2F4', '#1890FF', '#0050b3'] // 渐变颜色
          }
        },
        tooltip: {
          trigger: 'item',
        },
        series: [{
          selectedMode: false,
          type: 'map',
          coordinateSystem: 'geo',
          top: '100px',
          map: 'china',
          zoom: 1.2,
          showLegendSymbol: false,
          itemStyle: {
            borderColor: '#999',
          },
          emphasis: {
            label: {
              show: true,
              color: '#000'
            },
            itemStyle: {
              areaColor: 'rgba(1,112,254,0.5)',

            }
          },

          //鼠标提示框
          tooltip: {
            show: true,
            color: '#fff',
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderColor: 'rgba(50,50,50,0.7)',
            textStyle: {
              color: '#fff'
            },
          },
          scaleLimit: {
            min: 0.5,
            max: 20,
          },
          roam: true,
          data: []
        }],//各省地图颜色数据依赖value
      },
      echartContainer: '',
      score: '10',
      showBackBtn: false,
      backToData: '',
      jobType: [],
      level: 1
    };
  }

  componentDidMount = async () => {
    this.getData();
  }
  handlePieData = (data) => {
    let pieData = [];
    data?.forEach(item => {
      let j = {};
      j.name = item.name || 'null';
      j.value = item.cnt;
      pieData.push(j)
    })
    return pieData;
  }
  getData = async () => {
    return await React.fetch({
      url: GET_PROVINCE_DATA,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    }).then(res => {
      if (res.code === 200) {

        if (res.data) {
          this.initChinaEchart(res)
        }

      }
    });
  }
  getProvinceData = async (data) => {
    React.fetch({
      url: GET_CITY_DATA,
      method: "post",
      data: {
        provinceId: data.id,
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    }).then(res => {
      if (res.code === 200 && res.data) {
        this.initProvinceEchart({parentData: data, data: res.data})
      }
    })
  }
  getCityData = async (data) => {
    React.fetch({
      url: GET_AREA_DATA,
      method: "post",
      data: {
        cityId: data.id,
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    }).then(res => {
      if (res.code === 200 && res.data) {
        this.initCityEchart({parentData: data, data: res.data})
      }
    })
  }
  initChinaEchart = (res) => {
    let mapData = []
    for (let i = 0; i < res.data.length; i++) {
      mapData.push({
        name: res.data[i]['provinceName'],
        value: res.data[i]['jobCount'],
        id: res.data[i]['provinceId'],
        code: res.data[i]['provinceCode'],
      })
    }
    echarts.registerMap('china', {
      geoJSON: chinaJson
    })
    let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
    echartOptions.series[0].tooltip.formatter = (params) => {
      return `${params.name}:   ${params.data ? params.data.value : '-'}`
    }
    echartOptions.series[0].data = mapData;
    this.setState({
      echartOptions: echartOptions
    }, () => {
      if (this.state.echartContainer) {
        this.state.echartContainer.dispose()
      }
      let myChart = echarts.init(document.getElementById('echart-container'));
      myChart.setOption(echartOptions);
      myChart.off('click').on('click', async (params) => {
        if (params.data) {
          //直辖市或者特别行政区直接走区域
          this.locationId = params.data;
          if (this.state.cityWhiteList.includes(Number(params.data.code))) {
            this.getCityData(params.data);
            this.setState({showBackBtn: true, backToData: '', level: 3})
          } else {
            this.getProvinceData(params.data);
            this.setState({showBackBtn: true, backToData: '', level: 2})
          }
        }
      })
      this.setState({echartContainer: myChart})
    })
  }

  initProvinceEchart = async (data) => {
    let mapData = []
    for (let i = 0; i < data.data.length; i++) {
      mapData.push({
        name: data.data[i]['cityName'],
        value: data.data[i]['jobCount'],
        id: data.data[i]['cityId'],
        code: data.data[i]['cityCode'],
      })
    }
    let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
    let mapDataJson = await import(`./mapData/province/${data.parentData.code}.json`);
    echarts.registerMap(data.parentData.name, {
      geoJSON: mapDataJson.default
    })
    if (this.state.echartContainer) {
      this.state.echartContainer.dispose()
    }
    echartOptions.series[0].map = data.parentData.name;
    echartOptions.title.text = data.parentData.name;
    echartOptions.series[0].tooltip.formatter = (params) => {
      return `${params.name}:   ${params.data ? params.data.value : '-'}`
    }
    let myChart = echarts.init(document.getElementById('echart-container'));
    echartOptions.series[0].data = mapData;
    myChart.setOption(echartOptions);
    myChart.off('click').on('click', async (params) => {
      if (params.data) {
        this.locationId = params.data;
        this.getCityData(params.data)
        this.setState({showBackBtn: true, backToData: data.parentData, level: 3})
      }
    })
    this.setState({echartContainer: myChart})
  }
  initCityEchart = async (data) => {

    let mapData = []
    //处理map数据
    for (let i = 0; i < data.data.length; i++) {
      mapData.push({
        name: data.data[i]['areaName'],
        value: data.data[i]['jobCount'],
        id: data.data[i]['areaId'],
        code: data.data[i]['areaCode'],
      })
    }


    let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
    //根据adcode获取对应的 geo JSON
    let mapDataJson
    if (this.state.cityWhiteList.includes(Number(data.parentData.code))) {
      mapDataJson = await import(`./mapData/province/${data.parentData.code}.json`);
    } else {
      mapDataJson = await import(`./mapData/citys/${data.parentData.code}.json`);
    }
    echarts.registerMap(data.parentData.name, {
      geoJSON: mapDataJson.default
    })
    if (this.state.echartContainer) {
      this.state.echartContainer.dispose()
    }
    echartOptions.series[0].map = data.parentData.name;
    echartOptions.title.text = data.parentData.name;
    //恢复被JSON字符串化破坏的function
    echartOptions.series[0].tooltip.formatter = (params) => {
      return `${params.name}:   ${params.data ? params.data.value : '-'}`
    }
    let myChart = echarts.init(document.getElementById('echart-container'));
    echartOptions.series[0].data = mapData;
    myChart.setOption(echartOptions);
    this.setState({echartContainer: myChart})
  }
  backMap = () => {
    if (this.state.backToData) {
      this.getProvinceData(this.state.backToData);
      //清除省份数据
      this.setState({backToData: '', level: 2})
    } else {
      this.getData();
      this.setState({showBackBtn: false, backToData: '', level: 1})
    }

  }
  setDate = async (val) => {
    this.setState({
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      if (this.state.level === 1) {
        this.getData()
      } else if (this.state.level === 2) {
        this.getProvinceData(this.locationId)
      } else if (this.state.level === 3) {
        this.getCityData(this.locationId)
      }
    })
  }

  render() {
    const {jobType} = this.state;
    return (
      <div className="job-city-info">
        <Space>
          <DatePicker.RangePicker inputReadOnly={true}
                                  allowClear
                                  onChange={(val) => this.setDate(val)}
                                  value={
                                    this.state.start && this.state.end
                                      ? [
                                        moment(this.state.start, "YYYY-MM-DD"),
                                        moment(this.state.end, "YYYY-MM-DD"),
                                      ]
                                      : ""
                                  }
          />
          <CheckboxGroup options={[
            {label: "全职", value: 1},
            // {label: "线上兼职", value: 2},
            // {label: "线下兼职", value: 3}
          ]} value={jobType} onChange={(val) => {
            this.setState({
              jobType: val
            }, () => {
              if (this.state.level === 1) {
                this.getData()
              } else if (this.state.level === 2) {
                this.getProvinceData(this.locationId)
              } else if (this.state.level === 3) {
                this.getCityData(this.locationId)
              }
            })
          }}/>
        </Space>

        <div onClick={() => this.backMap()} className="map-back"
             style={{'display': this.state.showBackBtn ? 'flex' : 'none'}}>
          <img src={mapBack} alt="返回上级"/>
          <span>返回上级</span>
        </div>
        <div id="echart-container"/>
      </div>
    );
  }
}

export default JobCityInfo;
