import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Modal, message, Popconfirm} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {GET_COMPANY_ROLE_LIST, ADD_COMPANY_ROLE, UPDATE_COMPANY_ROLE} from "../../../service";
import "./index.less"

class CompanyIdentity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '企业身份名称',
          dataIndex: 'roleName',
        },
        {
          title: '企业身份备注',
          dataIndex: 'remark',
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          width: 200,
          render: (text, record) => (
            <Space>
              <Button type="default" onClick={() => this.handleEditor(record)}>修改</Button>
              <Popconfirm title={`确定${record.isEnable === 0 ? '启用' : '禁用'}吗？`} okText="是" cancelText="否"
                          onConfirm={() => this.handleEnable(record)}>
                {
                  record.isEnable === 1 ? <Button type="primary" danger>禁用</Button> : <Button type="primary">启用</Button>
                }
              </Popconfirm>
            </Space>
          ),
        },
      ]
    }
  }

  formRef = React.createRef()
  ref = React.createRef()

  componentDidMount() {

  }

  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    }, () => {
      this.formRef.current.setFieldsValue({
        roleName: "",
        remark: "",
      })
    })
  }

  handleEditor = (data) => {
    this.setState({
      eType: 2,
      eId: data.id,
      modalVisible: true
    }, () => {
      this.formRef.current.setFieldsValue({
        "roleName": data.roleName,
        "remark": data.remark
      })
    })
  }

  handleEnable = data => {
    React.fetch({
        url: UPDATE_COMPANY_ROLE,
        method: "post",
        data: {
          id: data.id,
          isEnable: data.isEnable === 0 ? 1 : 0
        }
      }
    ).then(res => {
      if (res.code === 200) {
        message.success("修改成功");
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }

  submit = (values) => {
    let url = ADD_COMPANY_ROLE;
    let data = {
      roleName: values.roleName,
      remark: values.remark
    }
    let messageTip = "权限添加成功!"
    if (this.state.eType === 2) {
      url = UPDATE_COMPANY_ROLE;
      data.id = this.state.eId;
      messageTip = "权限更新成功!";
    }
    React.fetch({
        url,
        method: "post",
        data
      }
    ).then(res => {
      if (res.code === 200) {
        message.success(messageTip);
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }

  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div className="company-identity-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params) => {
            const res = await React.fetch({
              url: GET_COMPANY_ROLE_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                roleName: params.roleName,
                remark: params.remark
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          pagination={false}
          search={{
            labelWidth: 'auto'
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          params={{
            page: this.state.page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<PlusOutlined/>}
                type="primary"
                onClick={this.handleAdd}
              >
                添加企业身份
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          title={this.state.eType === 1 ? "添加" : "编辑"}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout="horizontal"
            onFinish={values => this.submit(values)}
            submitter={{
              render: (props, doms) => <div
                style={{display: "flex", justifyContent: "center", gap: "8px"}}>{[...doms]}</div>,
            }}
          >
            <ProFormText
              rules={[{required: true, message: "请输入企业身份名称!"}]}
              required
              name="roleName"
              label="身份名称"
              placeholder="请输入企业身份名称"
            />
            <ProFormText
              rules={[{required: true, message: "请输入企业身份名称!"}]}
              required
              name="remark"
              label="身份备注"
              placeholder="请输入企业身份名称"
            />
          </ProForm>
        </Modal>
      </div>
    );
  }
}

export default CompanyIdentity;