import React,{Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Popconfirm, Modal, Image} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText, ProFormRadio, ProFormSelect, ProFormUploadDragger} from '@ant-design/pro-form';
import "./index.less";

class AdvertisementList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
        },
        {
          title: '图片',
          dataIndex: 'src',
          render: ((text, record, _, action) => {
            return <Image
              width={100}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          })
        },
        {
          title: '广告名称',
          dataIndex: 'name',
        },
        {
          title: '跳转对象',
          dataIndex: 'target',
        },
        {
          title: '显示状态',
          dataIndex: 'status',
        },
        {
          title: '创建时间',
          dataIndex: 'create_at',
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
        },
        {
          title: '操作',
          width: 200,
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() =>this.handleEditor(record)}>修改</Button>
              <Popconfirm title="确定杀出该项吗？" okText="删除" cancelText="取消" onConfirm={() => this.handleDelete(record)}>
                <Button type="primary" danger>删除</Button>
              </Popconfirm>
            </Space>
          ),
        },
      ]
    }
  }
  formRef = React.createRef()

  componentDidMount() {
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    })
  }

  handleEditor = (data) => {
    this.setState({
      eType: 2,
      eId: data.id,
      modalVisible: true
    })
  }
  handleDelete = (data) => {
    console.log(data);
  }

  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div className="advertisement-list-page">
        <ProTable
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            return {
              data: [
                {id: 1, account: "hello",name: "小张", mobile: "17621478502"}],
              success: true,
              total: 100
            }
          }}
          rowKey="id"
          search={false}
          pagination={{
            pageSize: 20,
          }}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>新增广告</Button>
            ]
          }}
        />

        <Modal
          title="添加/编辑"
          width={800}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout='horizontal'
            onFinish={async (values) => {
              console.log(values);
            }}
            submitter={{
              render: (props, doms) => {
                return <div style={{"display": "flex", "justifyContent": "center", "gap": "8px"}}>
                  {[...doms]}
                </div>;
              },
            }}
          >
            <ProFormText name="name" label="广告名称" placeholder="广告名称" rules={[{required: true, message: '请填写名称'}]}/>
            <ProFormSelect
              name="position"
              label="广告位置"
              valueEnum={{
                1: '网站_首页',
                2: 'App_首页',
              }}
              placeholder="请选择"
              rules={[{required: true, message: '请选择广告位置'}]}
            />
            <ProFormText name="size" label="广告图片尺寸" placeholder="图片尺寸" disabled/>
            <ProFormUploadDragger label="广告图片" name="logo" description="支持jpg,png格式，小于2M的图片" />
            <ProFormText name="link" label="链接地址" placeholder="链接地址"/>
            <ProFormRadio.Group
              name="target"
              label="跳转对象"
              options={[
                {
                  label: '推荐活动',
                  value: 1,
                },
                {
                  label: '话题官帖',
                  value: 2,
                },
                {
                  label: '企业详情',
                  value: 3,
                },
                {
                  label: '人才库',
                  value: 4,
                },
                {
                  label: '其他',
                  value: 5,
                },
              ]}
            />
            <ProFormRadio.Group
              name="scene"
              label="出现场景"
              options={[
                {
                  label: '支付宝小程序',
                  value: 1,
                },
                {
                  label: '微信小程序',
                  value: 2,
                },
              ]}
            />
            <ProFormSelect
              name="status"
              label="显示状态"
              valueEnum={{
                1: '显示',
                2: '隐藏',
              }}
              placeholder="请选择"
            />
            <ProFormText name="remark1" label="备注一" placeholder=""/>
            <ProFormText name="remark2" label="备注二" placeholder=""/>
          </ProForm>
        </Modal>
      </div>
    )
  }

}

export default AdvertisementList