import React,{Component} from "react";
import {Tabs} from "antd";
import OutputTable from "./components/outputTable";
import CashoutTable from "./components/cashoutTable";
import "./index.less"
const { TabPane } = Tabs;
class OrderDetail extends Component{
  render() {
    return (
      <div className="order-detail-page">
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab="红包产出明细" key="1">
              <OutputTable />
            </TabPane>
            <TabPane tab="红包提现明细" key="2">
              <CashoutTable />
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }

}

export default OrderDetail