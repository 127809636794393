import {FETCH_LOGIN} from "../actionTypes";

const initialState = {
  userName: "",
  password: ""
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN:
      const _state = {...state};
      _state.userName = action.payload.account;
      _state.password = action.payload.password;
      return _state
    default:
      return state
  }
}