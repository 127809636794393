import {ADD_TOKEN} from "../actionTypes";

const initialState = {
  token: localStorage.getItem("token") || ""
}
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOKEN:
      const _state = {...state};
      localStorage.setItem("token", action.token);
      _state.token = action.token;
      return _state
    default:
      return state
  }
}