import React, {Component} from "react";
import {GET_AUTHORIZATION_ACCOUNT} from "../../../service";
import moment from 'moment';
import {DatePicker, Table, Typography} from 'antd';
import "./index.less";
const { Text } = Typography;
class AuthorizationAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      date: [],
      tableData: [],
      column: [
        {
          title: '渠道',
          dataIndex: 'channel',
          key: 'channel',
          width: 120
        },
        {
          title: '注册用户数',
          dataIndex: 'count',
          key: 'count',
          width: 120
        },
        {
          title: '占比',
          dataIndex: 'percent',
          key: 'percent',
          width: 120
        },
      ]
    };
  }

  componentDidMount = async () => {

    await this.getData();
  }
  getData = async () => {
    let res = await React.fetch({
      url: GET_AUTHORIZATION_ACCOUNT,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
      },
    });
    if (res.code === 200) {
      this.setState({
        tableData: [
          // {
          //   key: 1,
          //   channel: "支付宝",
          //   count: res.data.alipayCount,
          //   percent: (res.data.alipayCount + res.data.weixinCount) !==0 ?(res.data.alipayCount / (res.data.alipayCount + res.data.weixinCount) * 100).toFixed(2) + "%": 0
          // },
          {
            key: 2,
            channel: "微信",
            count: res.data.weixinCount,
            percent:  (res.data.alipayCount + res.data.weixinCount) !==0 ?(res.data.weixinCount / (res.data.alipayCount + res.data.weixinCount) * 100).toFixed(2) + "%":0
          }
        ],
      });
    }

    return res;
  }

  setDate = async (val) => {
    this.setState({
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      date: val,
    }, () => {
      this.getData()
    })
  }

  initEchart() {
  }

  render() {
    const {date, tableData, column} = this.state;
    return (
      <div className="authorization-account">
        <DatePicker.RangePicker inputReadOnly={true} onChange={val => this.setDate(val)}
                                value={date}/>
        <div className="publish-count-page">
          <div className="title">注册用户</div>
          <div className="table">
            <Table columns={column} dataSource={tableData} pagination={false} bordered summary={pageData => {
              let total = 0;
              pageData.forEach(({count}) => {
                total += count;
              });
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>总数</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}><Text type="danger">{total}</Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}/>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthorizationAccount;
