import React, {Component} from "react";
import {Tree,Button,Modal,Input,message,Popconfirm,Form} from 'antd';
import {GET_SYSMENU_LIST,ADD_MENU,UPDATE_MENU,DELETE_MENU} from "../../../service";
import { FormOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import "./index.less"

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      addFirstMenuState:false,
      editeMenuName:'',//一级菜单名称
      editeMenuId:'',//一级菜单Id
      addSecondMenuState:false,
      editeSecondMenuName:'',//二级三级菜单名称
      editeSecondMenuId:'',//二级三级菜单Id
      editeSecondParentId:'',//二级菜单父级菜单id
      editeSecondParentName:''//二级菜单父级菜单名称
    };
  }
  formRef = React.createRef()
  componentDidMount() {
    this.getMenuList();
  }

  // 分出子节点  父节点
  translateDataToTree = (data) => {
    if(data){
      let parents = data.filter((value) => value.parentId === 0);
      let children = data.filter((value) => value.parentId !== 0);
      let translator = (parents, children) => {
        parents.forEach((parent) => {
          children.forEach((current, index) => {
            if (current.parentId === parent.id) {
              let temp = JSON.parse(JSON.stringify(children));
              temp.splice(index, 1);
              translator([current], temp);
              typeof parent.children !== "undefined"
                ? parent.children.push(current)
                : (parent.children = [current]);
            }
          });
        });
      };

      translator(parents, children);

      return parents;
    }

  };
  // 递归菜单数据  并拼凑出tree组件需要的数据结构
  recursionMenu=(data=[],index=0,parantName='')=>{
    let menuList=[];
    data.forEach(item=>{
        let menu={
            title:item.menuName,
            key:item.id.toString(),
            parentId:item.parentId,
            parantName:parantName,
            menuRouter:item.menuRouter,
            menuType:item.menuType,
            children:item.children,
            index:index,
        };
        if(item.children){
            menu.children= this.recursionMenu(item.children,index+1,item.menuName);
        }
        menuList.push(menu)
    })
    return menuList
  }
  openAddMenuModal(){
    this.setState({
      editeMenuName:'',
      addFirstMenuState:true,
      editeMenuId:''
    })
  }
  getMenuList() {
    React.fetch({
      url: GET_SYSMENU_LIST,
      method: "get",
    }).then(res=>{
      this.setState({
        treeData:this.recursionMenu(this.translateDataToTree(res.data))
      })
    });
    
  }
  addMenu(pId,editeMenuName,menuRouter){
    React.fetch({
      url: ADD_MENU,
      method: "post",
      data:{
        "menuIcon": "",
        "menuName": editeMenuName,
        "menuRouter": menuRouter?menuRouter:'',
        "menuSort": 0,
        "menuType": 0,
        "parentId": pId?pId:0
      }
    }).then(res=>{
      if(res){
        message.success("菜单添加成功");
        this.getMenuList();
        this.setState({addFirstMenuState:false})
      }
    });
  }
  editeMenuInfo(pId,id,menuName,menuRouter=""){
    React.fetch({
      url: UPDATE_MENU,
      method: "post",
      data:{
        "menuIcon": "",
        "menuName": menuName,
        "menuRouter": menuRouter,
        "menuSort": 0,
        "menuType": 0,
        "parentId": pId,
        "id":id,
      }
    }).then(res=>{
      if(res){
        message.success("菜单修改成功");
        this.getMenuList();
        this.setState({addFirstMenuState:false})
      }
    });
  }
  editeMenu(data){
    if(data.parentId===0){
      this.setState({
        editeMenuName:data.title,
        addFirstMenuState:true,
        editeMenuId:data.key,
      })
    }else{
      this.setState({
        addSecondMenuState:true,
        editeSecondParentName:data.parantName,
        editeSecondParentId:data.parentId,
        editeSecondMenuName:data.title,
        editeSecondMenuId:data.key
      },()=>
      {
        this.formRef.current.setFieldsValue({
          menuName: data.title,
          menuRouter:data.menuRouter
        })
      })
      
    }
  }
  authManage=(data)=>{
    this.props.history.push('/pageAuthList?menuId='+data.key);
  }
  addSecondMenu(data){
    this.setState({
      addSecondMenuState:true,
      editeSecondParentName:data.title,
      editeSecondParentId:data.key,
      editeSecondMenuName:'',
      editeSecondMenuId:''
    },()=>{
      this.formRef.current.setFieldsValue({
        menuName: "",
        menuRouter:""
      })
    })
    
  }

  deleteMenu(data){
    if(data.children){
      message.warning("请先删除子菜单");
      return
    }
    React.fetch({
      url: DELETE_MENU,
      method: "post",
      data:{
        id:data.key
      }
    }).then(res=>{
      if(res){
        message.success("菜单删除成功");
        this.getMenuList();
      }
    });
  }
  
  render() {
    return (
      <div className="menu-list-page">
        <Button type="primary" onClick={() => this.openAddMenuModal()}>
          新增一级菜单
        </Button>
        <Tree
          selectable={false}
          defaultExpandAll={true}
          treeData={this.state.treeData}
          titleRender={(nodeData) => {
            return (
              <span className="menu-node">
                {nodeData.title}
                <FormOutlined onClick={() => this.editeMenu(nodeData)} />
                {nodeData.index === 2 ? (
                  ""
                ) : (
                  <PlusOutlined onClick={() => this.addSecondMenu(nodeData)} />
                )}
                <Popconfirm
                  title="你确定要删除该数据吗?"
                  onConfirm={() => this.deleteMenu(nodeData)}
                  okText="确认"
                  cancelText="取消"
                >
                  <DeleteOutlined />
                </Popconfirm>

                {nodeData.index===2 ? (
                  <i onClick={() => this.authManage(nodeData)}>权限管理</i>
                ) : (
                  ""
                )}{" "}
              </span>
            );
          }}
        />
        <Modal
          title={this.state.editeMenuId ? "编辑一级菜单" : "添加一级菜单"}
          visible={this.state.addFirstMenuState}
          onOk={() =>
            this.state.editeMenuId ? this.editeMenuInfo(0,this.state.editeMenuId,this.state.editeMenuName) : this.addMenu(0,this.state.editeMenuName)
          }
          onCancel={() => {
            this.setState({ addFirstMenuState: false });
          }}
        >
          <Input
            onChange={(e) => this.setState({ editeMenuName: e.target.value })}
            value={this.state.editeMenuName}
          />
        </Modal>
        <Modal
          title={this.state.editeSecondMenuId?"编辑菜单":"添加菜单"}
          footer={null}
          visible={this.state.addSecondMenuState}
          onCancel={() => {
            this.setState({ addSecondMenuState: false });
          }}
        >
          <p>父级菜单：{this.state.editeSecondParentName}</p>
          <ProForm
            formRef={this.formRef}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            onFinish={(values) => {
              this.state.editeSecondMenuId?this.editeMenuInfo(this.state.editeSecondParentId,this.state.editeSecondMenuId,values.menuName,values.menuRouter):this.addMenu(this.state.editeSecondParentId,values.menuName,values.menuRouter);
              this.setState({addSecondMenuState:false})
            }}
          >
            <ProFormText
              name="menuName"
              label="菜单名称"
              placeholder="菜单名称"
              rules={[{ required: true, message: "请填写菜单名称" }]}
            />
            <Form.Item noStyle shouldUpdate>
                  <ProFormText
                    rules={[{ required: true, message: "请输入页面地址" }]}
                    name="menuRouter"
                    label="页面地址"
                    placeholder="页面地址"
                  />
            </Form.Item>
          </ProForm>
        </Modal>
      </div>
    );
  }
}

export default MenuList;