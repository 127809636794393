import React, {useState} from "react";
import {Input} from "antd";

function ConfirmTitle(props) {
  const [value, setValue] = useState("")

  function onChange(e) {
    setValue(e.target.value);
    props.getReasonText(e.target.value);
  }

  return (
    <>
      <div className="title">{props.title||"请填写说明"}</div>
      <div className="reason-text" style={{"marginTop": 10}}>
        <Input value={value} onChange={onChange}/>
      </div>
    </>
  )
}

export default ConfirmTitle