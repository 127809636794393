import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import ProTable from '@ant-design/pro-table';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Tree, Space, Modal, Form, Input, message, Divider} from 'antd';
import {
  GET_ROLE_LIST,
  GET_SYSMENU_LIST,
  GET_ROLEMENU_LIST,
  UPDATE_ROLE_INFO,
  ADD_ROLE_MENU,
  ADD_ROLE,
  DELETE_ROLE_MENU
} from "../../../service";
import './index.less'

const RoleAuth = () => {
  const tableColum = [
    {
      dataIndex: 'id',
      title: "ID",
      width: 48,
      hideInSearch: true
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
    },
    {
      title: '角色备注',
      dataIndex: 'remark',
    },
    {
      title: '操作',
      valueType: 'option',
      width: 200,
      align: 'center',
      render: (text, record, _, action) => (
        <Space className="role-edite">
          <Button type="link" onClick={() => handleEditor(record)}>修改</Button>
          <Button type="link" onClick={() => handleAuth(record)}>权限编辑</Button>
          <Button type="text" danger onClick={() => disableRole(record)}
                  className={record.isEnable === 1 ? 'disable-role' : ''}>{record.isEnable === 1 ? '禁用' : '启用'}</Button></Space>
      ),
    },
  ]
  const [roleEditePop, setRoleEditePop] = useState(-1);
  const [modalVisible,setModalVisible] = useState(false);
  const [editRoleMenuRecordId, setEditRoleMenuRecordId] = useState(-1);
  const [systemMenuList, setSystemMenuList] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [halfSelectedKeys, setHalfSelectedKeys] = useState([]);
  const [selectRoleName, setSelectRoleName] = useState('');
  const [selectRoleRemark, setSelectRoleRemark] = useState('');
  const [selectRoleIsEnable, setSelectRoleIsEnable] = useState('');
  const [allNodeList, setallNodeList] = useState('');
  const [page, setPage] = useState(1)

  //更新角色信息开关
  const [updateRole, setUpdateRole] = useState(false);
  const tableRef = useRef()
  const handleEditor = (record) => {
    setRoleEditePop(1);
    setEditRoleMenuRecordId(record.id);
    setSelectRoleName(record.roleName);
    setSelectRoleRemark(record.remark);
    setSelectRoleIsEnable(record.isEnable)
  }
  const handleAuth = record => {
    setModalVisible(true)
    setEditRoleMenuRecordId(record.id);
  }
  const addRole = () => {
    setRoleEditePop(0);
    setEditRoleMenuRecordId(-1);
    setSelectRoleName('');
    setSelectRoleRemark('')
    setSelectedKeys([]);
  }
  //更改角色名称
  const updateRoleInfo = async () => {
    let data = await React.fetch({
      url: UPDATE_ROLE_INFO,
      method: "post",
      data: {
        id: editRoleMenuRecordId,
        roleName: selectRoleName,
        remark: selectRoleRemark,
        isEnable: selectRoleIsEnable,
      }
    })
    if (data) {
      message.success("更新成功！");
      tableRef.current.reload();
    }
  }
  // 添加角色菜单
  const addRoleMenu = (ids) => {
    React.fetch({
      url: ADD_ROLE_MENU,
      method: "post",
      data: {
        roleId: editRoleMenuRecordId,
        menuIdList: ids
      }
    })
  }
  // 删除角色菜单
  const deleteRoleMenu = (ids) => {
    React.fetch({
      url: DELETE_ROLE_MENU,
      method: "post",
      data: {
        roleId: editRoleMenuRecordId,
        menuIdList: ids
      }
    })
  }
  // 取消弹出层
  const menuCancel = () => {
    setRoleEditePop(-1);
    setModalVisible(false)
  }

  //弹出层状态
  const popState = useMemo(() => {
    return [0, 1].includes(roleEditePop)
  }, [roleEditePop]);

  // 递归菜单数据  并拼凑出tree组件需要的数据结构
  const recursionMenu = (data) => {
    let menuList = [];
    data.forEach(item => {
      let menu = {
        title: item.menuName,
        key: item.id.toString(),
      };
      if (item.children) {
        menu.children = recursionMenu(item.children);
      }
      menuList.push(menu)
    })
    return menuList
  }

  // 获取所有菜单
  const geteAllMenuData = async () => {
    let data = await React.fetch({
      url: GET_SYSMENU_LIST,
      method: "get",
    })
    if (data) {

      setSystemMenuList(translateDataToTree(data.data))
    }

  }

  // 获取角色名下菜单
  const geteRoleMenuData = async (id) => {
    let data = await React.fetch({
      url: GET_ROLEMENU_LIST,
      method: "post",
      data: {
        id: id
      }
    })
    if (data) {
      let arr = [];
      data.data.forEach(item => {
        arr.push(item.id.toString())
      })
      setallNodeList(arr)
      setSelectedKeys(findNoChild(translateDataToTree(data.data)))
    }

  }

  //由于ant design原因 找出所有没有子节点的节点id
  const findNoChild = (data) => {
    let selectMenus = [];
    loopData(data)

    function loopData(data) {
      data.forEach(item => {
        if (item.children) {
          loopData(item.children);
        } else {
          selectMenus.push(item.id.toString());
        }
      })
    }

    return selectMenus;
  }
  // 分出子节点  父节点
  const translateDataToTree = (data) => {
    let parents = data.filter(value => value.parentId === 0)
    let children = data.filter(value => value.parentId !== 0)
    let translator = (parents, children) => {
      parents.forEach((parent) => {
          children.forEach((current, index) => {
              if (current.parentId === parent.id) {
                let temp = JSON.parse(JSON.stringify(children))
                temp.splice(index, 1)
                translator([current], temp)
                typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
              }
            }
          )
        }
      )
    }

    translator(parents, children)
    return parents
  }

  // 根据数据变化  渲染出所有的tree树
  const treeData = useMemo(() => {
    let data = recursionMenu(systemMenuList);
    data.forEach(item => {
      let hasGrandSon = false;
      if (item.children) {
        item.children.forEach(child => {
          if (!child.children) {
            child.disabled = true;
            hasGrandSon = true;
          }
        })
      } else {
        hasGrandSon = true;
      }
      item.disabled = hasGrandSon;
    })
    return data;
  }, [systemMenuList])
  const changeName = useCallback((e) => {
    setSelectRoleName(e.target.value)
  }, [])
  const changeRemark = useCallback((e) => {
    setSelectRoleRemark(e.target.value)
  }, [])
  //修改角色id变化  默认选中数值变化
  useEffect(() => {
    if (editRoleMenuRecordId !== -1) {
      geteRoleMenuData(editRoleMenuRecordId)
    }
  }, [editRoleMenuRecordId])

  // mounted  获取所有菜单列表
  useEffect(() => {
    geteAllMenuData();
  }, []);

  const onCheck = (checkedKeysValue, data) => {
    const {halfCheckedKeys, checked, node} = data
    getNodeIds([node]);
    if (checked) {
      addRoleMenu(Array.from(new Set(getArrDifference(allNodeList, checkedKeysValue.concat(halfCheckedKeys)))))
    } else {
      deleteRoleMenu(Array.from(new Set(getArrDifference(allNodeList, checkedKeysValue.concat(halfCheckedKeys)))))
    }
    setallNodeList(checkedKeysValue.concat(halfCheckedKeys));
    setSelectedKeys(checkedKeysValue);
    setHalfSelectedKeys(halfCheckedKeys);
  };
  //比较两个数组之间的不同
  const getArrDifference = (arr1, arr2) => {
    return arr1.concat(arr2).filter(function (v, i, arr) {
      return arr.indexOf(v) === arr.lastIndexOf(v);
    });
  }
  // 获取节点所有id
  const getNodeIds = (node) => {
    let ids = [];
    loopNodes(node);

    function loopNodes(node) {
      node.forEach(item => {
        ids.push(item.key);
        if (item.children) {
          loopNodes(item.children)
        }
      })
    }

    return ids;

  }
  const disableRole = (record) => {
    record.isEnable === 1 ? setSelectRoleIsEnable(0) : setSelectRoleIsEnable(1);
    setEditRoleMenuRecordId(record.id);
    setSelectRoleName(record.roleName);
    setSelectRoleRemark(record.remark);
    setUpdateRole(true);
  }
  useEffect(() => {
    if (updateRole) {
      updateRoleInfo();
      setUpdateRole(false);
    }
  }, [updateRole])
  const handleAddRole = () => {
    React.fetch({
      url: ADD_ROLE,
      method: "post",
      data: {
        "isEnable": 1,
        "remark": selectRoleRemark,
        "roleName": selectRoleName
      }
    }).then(res => {
      if (res) {
        message.success("添加成功！");
        menuCancel();
        tableRef.current.reload();
      }
    })

  }

  function handlePrev() {
    let current = page
    setPage(current < 0 ? 0 : --current)
  }

  function handleNext() {
    let current = page
    setPage(++current)
  }

  return (
    <div className="role-auth">
      <ProTable
        actionRef={tableRef}
        columns={tableColum}
        request={async (params = {}, sort, filter) => {
          const res = await React.fetch({
            url: GET_ROLE_LIST,
            method: "get",
            data: {
              page: params.page,
              size: 20,
            },
          });
          return {
            data: res.data,
            success: true,
          };
        }}
        rowKey="id"
        search={{
          labelWidth: "auto",
        }}
        onSubmit={() => {
          setPage(1)
        }}
        params={{
          page: page
        }}
        pagination={false}
        toolBarRender={(action) => {
          return [
            <Button
              key="button"
              icon={<PlusOutlined/>}
              type="primary"
              onClick={() => {
                addRole()
              }}
            >
              添加角色
            </Button>,
          ];
        }}
      />
      <div className="page">
        <Button type="link" className="prev" disabled={page === 1} onClick={handlePrev}>上一页</Button>
        <Button type="link" className="next" onClick={handleNext}>下一页</Button>
      </div>
      <Modal title={roleEditePop === 0 ? '添加角色' : '编辑角色'} footer={null} visible={popState} onCancel={menuCancel}>
        {
          roleEditePop !== 0 ? <>
            <Form.Item label="角色名称">
              <Input onChange={changeName} value={selectRoleName}/>
            </Form.Item>
            <Form.Item label="角色备注">
              <Input onChange={changeRemark} value={selectRoleRemark}/>
            </Form.Item>
            <Form.Item style={{textAlign:'center'}}>
              <Button type="primary" onClick={() => updateRoleInfo()}>确定</Button>
            </Form.Item>
          </> : <Form.Item label="角色名称">
            <Input onChange={changeName} style={{"width": "200px", "marginRight": "20px"}} value={selectRoleName}/><Button
            type="primary" onClick={() => handleAddRole()}>确定</Button>
          </Form.Item>
        }
      </Modal>
      <Modal title="权限编辑" footer={null} visible={modalVisible} onCancel={menuCancel}>
          <Tree checkable multiple defaultExpandAll={true} treeData={treeData} checkedKeys={selectedKeys}
          onCheck={onCheck}/>
      </Modal>
    </div>
  );
}
export default RoleAuth;