import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Space, Radio, Input, message} from 'antd';
import ProTable from '@ant-design/pro-table';
import {getQueryVariable} from "../../../../utils";
import {GET_JOB_CATRGORY_LIST, ADD_JOB_CATRGORY, UPDATE_JOB_CATRGORY} from "../../../../service";
import "../index.less";

class ChildJobCategoryManage extends Component {
  _index = Number(getQueryVariable(this.props.history.location.search, "index"));
  _jobType = Number(+getQueryVariable(this.props.history.location.search, "jobType"));
  _postCode = getQueryVariable(this.props.history.location.search, "postCode")
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      jobType: this._jobType,
      postCode: this._postCode,
      index: this._index,
      sort: "1",
      categoryId: '',
      categoryName: '',
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: this._index === 2 ? '工种名称' : '职位名称',
          dataIndex: 'postName',
          hideInSearch: false,

        },
        {
          title: '下一级分类总数',
          dataIndex: 'parentPostCodeNum',
          hideInSearch: true,
          align: 'center',
          hideInTable: +this._index !== 2
        },

        {
          title: '创建人',
          dataIndex: 'createdBy',
          hideInSearch: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '更新时间',
          dataIndex: 'updatedTime',
          hideInSearch: true,
        },

        {
          title: '创建时间范围',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          fieldProps: {
            placeholder: ["开始时间", "结束时间"]
          }
        },
        {
          title: '',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          renderFormItem: (_, {type, defaultRender, ...rest}, form) => {
            return (
              <Radio.Group value={this.state.sort} onChange={e => this.handleSizeChange(e)}>
                <Radio.Button value="0">时间倒叙</Radio.Button>
                <Radio.Button value="1">时间正序</Radio.Button>
              </Radio.Group>
            );
          }
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="dashed" onClick={() => {
                this.handleChange(record)
              }}>修改名称</Button>
              {this.state.index === 2 ?
                <Button type="primary" onClick={() => this.goChildCategory(record)}>查看子类</Button> : ''}
            </Space>
          ),
        },
      ],
      btnName: '添加工种',
      modalTitle: '工种'
    };
  }

  componentWillReceiveProps(newProps) {
    let columns = this.state.columns.concat([]);
    this._index = Number(getQueryVariable(this.props.history.location.search, "index"));
    this._jobType = Number(+getQueryVariable(this.props.history.location.search, "jobType"));
    this._postCode = getQueryVariable(this.props.history.location.search, "postCode")
    columns[1].title = this._index === 2 ? '工种名称' : '职位名称';
    columns[2].hideInTable = this._index !== 2;
    this.setState({
      jobType: this._jobType,
      postCode: this._postCode,
      index: this._index,
      columns: columns,
      btnName: '添加职位',
      modalTitle: '职位'
    }, () => {
      this.ref.current.reload();
    })
    //一些操作
  }

  goChildCategory = (data) => {
    this.props.history.replace("/childJobCategoryManage?postCode=" + data.postCode + "&index=3&jobType=" + this.state.jobType)
  }
  handleSizeChange = (e) => {
    this.setState({
      sort: e.target.value
    }, () => {
      this.ref.current.reload()
    })
  }
  ref = React.createRef();
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handleAdd = () => {
    this.setState({modalVisible: true, categoryId: "", categoryName: ""});
  }
  handleChange = (data) => {
    this.setState({modalVisible: true, categoryId: data.id, categoryName: data.postName});
  }
  editeCategory = () => {
    React.fetch({
        url: UPDATE_JOB_CATRGORY,
        method: "post",
        data: {
          id: this.state.categoryId,
          jobType: this.state.jobType,
          postName: this.state.categoryName,
          parentPostCode: this.state.postCode
        }
      }
    ).then(res => {
      console.log(res);
      if (res.code === 200) {
        message.success("账号编辑成功！");
        this.ref.current.reload();
        this.setState({modalVisible: false})
      }
    })
  }
  addCategory = () => {
    React.fetch({
        url: ADD_JOB_CATRGORY,
        method: "post",
        data: {
          jobType: this.state.jobType,
          postName: this.state.categoryName,
          parentPostCode: this.state.postCode
        }
      }
    ).then(res => {
      console.log(res);
      if (res.code === 200) {
        message.success("岗位种类添加成功！");
        this.ref.current.reload();
        this.setState({modalVisible: false})
      }
    })
  }

  render() {
    return (
      <div className="job-category-manage">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            console.log(params, sort, filter);
            const res = await React.fetch({
              url: GET_JOB_CATRGORY_LIST,
              method: "post",
              data: {
                page: params.page ? params.page : 1,
                size: 20,
                jobType: this.state.jobType,
                sort: this.state.sort,
                postName: params.postName,
                beginDate: params.created_at && params.created_at[0],
                endDate: params.created_at && params.created_at[1],
                parentPostCode: this.state.postCode
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: "auto",
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<PlusOutlined/>}
                type="primary"
                onClick={this.handleAdd}
              >
                {this.state.btnName}
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          title={(this.state.categoryId ? "编辑" : "添加") + this.state.modalTitle}
          visible={this.state.modalVisible}
          onCancel={() => this.setState({modalVisible: false})}
          onOk={() => this.state.categoryId ? this.editeCategory() : this.addCategory()}
        >
          <Input value={this.state.categoryName} onChange={(e) => this.setState({categoryName: e.target.value})}/>
        </Modal>
      </div>
    );
  }
}

export default ChildJobCategoryManage;