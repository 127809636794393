import React, {Component} from "react";
import {withRouter} from "react-router-dom"
import {Button, Space, Popconfirm, message, Tag, Cascader} from 'antd';
import ConfirmTitle from "../../../../components/ConfirmTitle/ConfirmTitle";
import ProTable from '@ant-design/pro-table';
import {
  JOB_LIST,
  AGREEMENT_JOB,
  REJECTION_JOB,
  GET_JOB_CATEGORY_ALL,
  CITY_OPTION,
  PROVINCE_OPTiON
} from "../../../../service";
import {connect} from "react-redux";
import {getQueryVariable} from "../../../../utils";


class UncheckJobTable extends Component {
  companyAccountId = getQueryVariable(this.props.history.location.search, "companyAccountId")|| "";
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 60,
          hideInSearch: true,
        },
        {
          title: ' 职位名称',
          width: 240,
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '招聘方式',
          width: 120,
          dataIndex: 'recruitingType',
          ellipsis: true,
          valueType: "select",
          fieldProps: {
            options: [
              {label: '直招',value:0},
              {label: '代招',value:1},
            ]
          },
          render: (text, record) => {
            return record.recruitingType?.desc
          }
        },
        {
          title: '职位来源',
          width: 120,
          dataIndex: 'spiderPlatform',
          render: ((text, record, _) => {
            return record.spiderPlatform?.desc || "-"
          }),
          valueType: 'select',
          fieldProps: {
            placeholder: "选择职位来源",
            options: [
              {label: '采集', value: 0},
              {label: '真实', value: 1}
            ]
          }
        },
        {
          title: '企业名称',
          width: 120,
          dataIndex: 'companyName',
          hideInSearch: true
        },
        {
          title: '学历要求',
          width: 120,
          dataIndex: 'requireEducation',
          valueType: 'select',
          fieldProps: {
            options: this.props.educationOptions
          },
          render: (text, record, _, action) => {
            return record.requireEducation && record.requireEducation.desc
          }
        },
        {
          title: '薪资',
          width: 100,
          dataIndex: 'salary',
          hideInSearch: true
        },
        {
          title: '职位类型',
          width: 120,
          dataIndex: 'jobType',
          valueType: 'select',
          fieldProps: {
            options: this.props.jobTypeOptions
          },
          render: (text, record, _, action) => {
            return record.jobType && record.jobType.desc
          }
        },
        {
          title: '发薪方式',
          width: 120,
          dataIndex: 'salaryType',
          valueType: 'select',
          fieldProps: {
            options: this.props.salaryOptions
          }
        },
        {
          title: '发布时间',
          width: 200,
          dataIndex: 'publishTime',
          hideInSearch: true,
        },
        {
          title: '下线时间',
          width: 200,
          dataIndex: 'refreshTime',
          hideInSearch: true,
        },
        {
          title: '操作',
          width: 240,
          fixed: "right",
          key: 'option',
          align: "center",
          hideInSearch: true,
          render: (text, record) => (
            <Space>
              <Button type="default" onClick={() =>this.handleView(record)}>查看</Button>
              <Button type="primary" onClick={() =>this.handlePass(record)} loading={this.state.btnLoading[record.id]}>通过</Button>
              <Popconfirm title={<ConfirmTitle title="请填写拒绝原因" getReasonText={this.getReasonText}/>} okText="确定" cancelText="取消" onConfirm={() =>this.handleFail(record)}>
                <Button type="default" danger loading={this.state.btnLoading[record.id]}>不通过</Button>
              </Popconfirm>
            </Space>
          ),
        },
        {
          title: '岗位分类',
          dataIndex: 'jobCategory',
          hideInTable: true,
          renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
            return <Cascader
              options={this.state.CategoryOptions}
              placeholder="请选择分类"
              fieldNames={{label: 'postName', value: 'postCode', children: 'childDtoList'}}
              showSearch={this.filterCategory}
            />
          },
        },
        {
          title: '城市选择',
          dataIndex: 'city',
          hideInTable: true,
          renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
            if(form.getFieldValue("jobType") === 2) {
              return null
            }
            return <Cascader
              options={this.state.cityOptions}
              placeholder="请选择城市"
              loadData={this.loadData}
            />
          },
        },
      ],
      btnLoading:{},
      CategoryOptions: [],
      cityOptions: []
    }
  }

  ref = React.createRef();
  reason = "";

  componentDidMount() {
    this.getCategoryOptions(1);
    this.loadProvinceData();
  }

  getCategoryOptions = type => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: GET_JOB_CATEGORY_ALL,
        method: "post",
        data: {
          jobType: type
        }
      }).then(res => {
        this.setState({
          CategoryOptions: res.data
        })
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  loadData = async selectedOptions => {
    const {cityOptions} = this.state;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let result = await this.loadCityData(targetOption.value)

    targetOption.loading = false;
    targetOption.children = result;
    this.setState({
      cityOptions: [...cityOptions]
    })
  }

  loadCityData = (id) => {
    return new Promise((resolve, reject) => {
      React.fetch({
        url: CITY_OPTION,
        method: "post",
        data:{
          provinceId: id
        }
      }).then(res => {
        if(res.code === 200) {
          resolve(res.data?.slice(1).map(item => {
            return {
              label: item.name + item.suffix,
              value: item.id,
              id: item.id,
              code: item.code,
              isLeaf: true,
            }
          }))
        }else {
          reject()
        }
      }).catch(err => {
        reject()
      })
    })
  }

  loadProvinceData = () => {
    React.fetch({
      url: PROVINCE_OPTiON,
    }).then(res => {
      const result = res.data && res.data.map(item => {
        return {
          isLeaf: [1, 2, 3, 4].indexOf(item.id) > -1,
          label: item.name + item.suffix,
          value: item.id,
          code: item.code,
          level: 1
        }
      })
      this.setState({
        cityOptions: result
      })
    })
  }

  filterCategory = (inputValue, path) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  getReasonText = (text) => {
    this.reason=text;
  }

  handlePass = record => {
    this.setState({
      btnLoading:{
        [record.id]: true
      }
    })
    React.fetch({
      url: AGREEMENT_JOB,
      method: "post",
      data: {
        jobId: record.id,
        remark: ""
      }
    }).then(res=> {
      if(res.code === 200) {
        message.success("该岗位已通过审核！");
        this.ref.current.reload();
      }

      this.setState({
        btnLoading:{
          [record.id]: false
        }
      },() =>{
        this.setState({
          btnLoading: {}
        })
      })
    })
  }
  handleFail = record => {
    this.setState({
      btnLoading:{
        [record.id]: true
      }
    })
    React.fetch({
      url: REJECTION_JOB,
      method: "post",
      data: {
        jobId: record.id,
        remark: this.reason
      }
    }).then(res => {
      if(res.code === 200) {
        message.success("该岗位已被取消！");
        this.ref.current.reload();
      }
    })
    this.setState({
      btnLoading:{
        [record.id]: false
      }
    },() =>{
      this.setState({
        btnLoading: {}
      })
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  handleView = record => {
    this.props.history.push("/jobView?id=" + record.id + '&jobType=' + record.jobType.code);
    console.log("---")
    // this.props.history.push({
    //   path: "/jobView",
    //   search: {
    //     id: record.id,
    //     jobType: record.jobType.code
    //   }
    // })
  }

  onFormValuesChange = changedValues => {
    if ('jobType' in changedValues) {
      this.getCategoryOptions(changedValues.jobType);
    }
  }

  render() {
    return (
      <div className="pro-table">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          form={{
            onValuesChange: (changedValues) => this.onFormValuesChange(changedValues)
          }}
          request={async (params, sorter, filter) => {
            const res = await React.fetch({
              url: JOB_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                postSource: params.spiderPlatform,
                recruitingType: params.recruitingType,
                auditState: 0,
                jobState: 0,
                requireEducation: params.requireEducation,
                salaryType: params.salaryType,
                title: params.title,
                jobType: params.jobType,
                companyAccountId: this.companyAccountId,
                zhimaJobType: params.zhimaJobType,
                jobCategory: params.jobCategory?.slice(-1)[0],
                provinceId: params.city&&params.city[0],
                cityId: params.city&&params.city[1]
              }
            })

            return Promise.resolve({
              data: res.data,
              success: true,
              total: 500
            });
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page
          }}
          dateFormatter="string"
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    educationOptions: state.global.educationOptions,
    salaryOptions: state.global.salaryOptions,
    jobTypeOptions: state.global.jobTypeOptions
  }
}
export default connect(mapStateToProps, null)(withRouter(UncheckJobTable))