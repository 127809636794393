import React, {Component} from "react";
import {PageContainer} from '@ant-design/pro-layout';
import {Button, Row, Col, Upload, Form, message} from "antd";
import ProCard from '@ant-design/pro-card';
import {PlusOutlined} from '@ant-design/icons';
import ProForm, {
  ProFormList,
  ProFormText,
  ProFormDatePicker,
  ProFormTextArea,
  ProFormSelect,
  ProFormRadio
} from '@ant-design/pro-form';
import {getQueryVariable} from "../../../utils";
import {withRouter} from "react-router-dom";
import {RESUME_INFO} from "../../../service";
import "./index.less"
import {connect} from "react-redux";

class ResumeDetail extends Component {
  resumeId = "0";
  applyId = "0";

  constructor(props) {
    super(props);
    this.state = {
      current: '1',
      resumeInfo: {}
    }
  }

  basisFormRef = React.createRef();

  componentDidMount() {
    this.resumeId = getQueryVariable(this.props.location.search, "resume");
    this.applyId = getQueryVariable(this.props.location.search, "apply");
  }


  TabChange = key => {
    this.setState({
      current: key
    })
  }
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  render() {
    const {resume} = this.state.resumeInfo;
    return (
      <div className="resume-detail-page">
        <PageContainer
          fixedHeader
          header={{
            title: '简历详情',
          }}
          tabList={[
            {
              tab: '基本信息',
              key: '1',
            },
            {
              tab: '个人简介',
              key: '2',
            },
            {
              tab: '工作经历',
              key: '3',
            },
          ]}
          onTabChange={(key) => {
            this.TabChange(key)
          }}
        >
          {
            this.state.current === '1' ?
              <ProCard title="基本信息">
                <ProForm
                  formRef={this.basisFormRef}
                  labelCol={{span: 2}}
                  wrapperCol={{span: 22}}
                  layout='horizontal'
                  request={async () => {
                    const res = await React.fetch({
                      url: RESUME_INFO,
                      method: "post",
                      data: {
                        "applyId": "",
                        "id": getQueryVariable(this.props.history.location.search, "resume")
                      }
                    })
                    const {name, birthday, sex, identity, mobile, avatar, collegeName, educationYear, specialty, education} = res.data.resume;
                    this.setState({
                      resumeInfo: res.data
                    },)

                    return {
                      avatar: avatar,
                      name: name,
                      birth: birthday?birthday : (new Date().getFullYear() + '-01-01'),
                      sex: sex && sex.code,
                      identity: identity && identity.code,
                      tel: mobile,
                      education: [
                        {
                          date: educationYear ? educationYear + '' : new Date().getFullYear() + '',
                          major: specialty,
                          school: collegeName,
                          level: education && education.code
                        }
                      ]
                    }
                  }}
                  submitter={{
                    render: (props, dom) => {
                      return <div className="form-actions">
                        {
                          [
                            <Button type="primary" key="submit" onClick={() => props.form.submit()}>提交</Button>,
                            <Button type="default" key="rest" onClick={() => props.form.resetFields()}>重置</Button>,
                          ]
                        }
                      </div>
                    }
                  }}
                  onFinish={async (values) => {
                    console.log(values);
                  }}>
                  <ProCard title="">
                    <Form.Item
                      name="avatar"
                      extra="头像格式为jpg、png,"
                      label="头像">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={React.baseUrl + "/web/boss/upload/img"}
                        headers={{
                          "x-zp-ats-auth-token": this.props.token
                        }}
                        beforeUpload={this.beforeUpload}

                      >
                        {(resume && resume.avatar) ? <img src={resume.avatar} alt="avatar" style={{width: '100%'}}/> : (
                          <div>
                            <PlusOutlined/>
                            <div style={{marginTop: 8}}>上传</div>
                          </div>)}
                      </Upload>
                    </Form.Item>
                    <ProFormText name='name' label="姓名"/>
                    <ProFormDatePicker width="lg" name="birth" label="生日"/>
                    <ProFormRadio.Group
                      label="性别"
                      name="sex"
                      options={[
                        {label: '男', value: 1},
                        {label: '女', value: 0},
                      ]}
                    />
                    <ProFormSelect
                      name="identity"
                      label="身份"
                      options={[
                        {label: '学生', value: 1},
                        {label: '上班族', value: 2},
                        {label: '自由职业', value: 3},
                      ]}
                    />
                    <ProFormText name='tel' label="手机"/>
                  </ProCard>
                  <ProCard title="">
                    <ProFormList
                      layout='horizontal'
                      name="education"
                      label=""
                      creatorButtonProps={{
                        creatorButtonText: '添加教育经历',
                      }}
                      itemRender={({listDom, action}, {record}) => {
                        return (
                          <ProCard
                            bordered
                            extra={action}
                            title="教育经历"
                            style={{
                              marginBottom: 8,
                            }}
                          >
                            {listDom}
                          </ProCard>
                        );
                      }}
                    >
                      <ProFormText
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                        name='school' label="学校"/>
                      <ProFormDatePicker
                        name="date"
                        width="lg"
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                        fieldProps={{
                          format: 'YYYY',
                          picker: "year"
                        }}
                        label="入学时间"
                      />
                      <ProFormText
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                        name='major'
                        label="专业"/>
                      <ProFormSelect
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                        name="level"
                        label="学历"
                        options={this.props.educationOptions}
                      />
                    </ProFormList>
                  </ProCard>
                </ProForm>
              </ProCard> : null
          }
          {
            this.state.current === '2' ? <ProCard title="个人简介">
              <ProForm
                labelCol={{span: 2}}
                wrapperCol={{span: 22}}
                layout='horizontal'
                request={async () => {
                  const res = await React.fetch({
                    url: RESUME_INFO,
                    method: "post",
                    data: {
                      "applyId": "",
                      "id": getQueryVariable(this.props.location.search, "resume")
                    }
                  })
                  const {info, email} = res.data.resume;
                  const {certList} = res.data;
                  this.setState({
                    resumeInfo: res.data
                  },)

                  return {
                    describe: info,
                    email: email,
                    certificates: certList
                  }
                }}
                submitter={{
                  render: (props, dom) => {
                    return <div className="form-actions">
                      {
                        [
                          <Button type="primary" key="submit" onClick={() => props.form.submit()}>提交</Button>,
                          <Button type="default" key="rest" onClick={() => props.form.resetFields()}>重置</Button>,
                        ]
                      }
                    </div>
                  }
                }}
                onFinish={async (values) => {
                  console.log(values);
                }}>
                <ProFormTextArea name='describe' label="个人简介"/>
                <ProFormText name='email' label="邮箱"/>
                <ProFormList
                  name="certificates"
                  label="技能证书"
                  creatorButtonProps={{
                    creatorButtonText: '添加技能证书',
                  }}>
                  <ProFormText name='certificate'/>
                </ProFormList>
              </ProForm>
            </ProCard> : null
          }
          {
            this.state.current === '3' ? <ProCard>
                <ProForm
                  layout='horizontal'
                  request={async () => {
                    const res = await React.fetch({
                      url: RESUME_INFO,
                      method: "post",
                      data: {
                        "applyId": "",
                        "id": getQueryVariable(this.props.location.search, "resume")
                      }
                    })
                    const {workList} = res.data;
                    this.setState({
                      resumeInfo: res.data
                    },)

                    return {
                      experience: workList && workList.map(item => {
                        return {
                          name: item.companyName,
                          job: item.job,
                          start: item.start,
                          end: item.end,
                          content: item.work
                        }
                      })
                    }
                  }}
                  submitter={{
                    render: (props, dom) => {
                      return <div className="form-actions">
                        {
                          [
                            <Button type="primary" key="submit" onClick={() => props.form.submit()}>提交</Button>,
                            <Button type="default" key="rest" onClick={() => props.form.resetFields()}>重置</Button>,
                          ]
                        }
                      </div>
                    }
                  }}
                  onFinish={async (values) => {
                    console.log(values);
                  }}>
                  <ProFormList
                    name="experience"
                    itemRender={({listDom, action}, {record}) => {
                      return (
                        <ProCard
                          bordered
                          extra={action}
                          title="工作经验"
                          style={{
                            marginBottom: 8,
                          }}
                        >
                          {listDom}
                        </ProCard>
                      );
                    }}
                    creatorButtonProps={{
                      creatorButtonText: '添加工作经历',
                    }}>
                    <ProCard title="工作经历">
                      <Row gutter={[16, 24]}>
                        <Col className="gutter-row" span={12}>
                          <ProFormText name='name' label="公司名称"/>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <ProFormText name='job' label="职位名称"/>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <ProFormDatePicker width="lg" name="start" label="开始日期"/>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <ProFormDatePicker width="lg" name="end" label="结束日期"/>
                        </Col>
                      </Row>
                    </ProCard>
                    <ProCard title="工作内容">
                      <ProFormTextArea name="content" label=""/>
                    </ProCard>
                  </ProFormList>
                </ProForm>
              </ProCard>
              : null
          }
        </PageContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  educationOptions: state.global.educationOptions
})
export default connect(mapStateToProps, null)(withRouter(ResumeDetail))