import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Popconfirm, Modal, Image, message, Form} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText, ProFormSelect, ProFormUploadDragger} from '@ant-design/pro-form';
import {GET_AD_LIST, GET_AD_DETAIL, DELETE_AD, EDITE_AD, ADD_AD} from "../../../service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./style.less";

const bannerTypeOptions = {'0': '不跳转', '1': '支付宝小程序', '2': '直播', '3': '外部链接'}
const bannerSateOptions = {'0': '不显示', '1': '显示'}

class HomeImageConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1, //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: "id",
          title: "ID",
          width: 48,
        },
        {
          title: "图片",
          width: 160,
          dataIndex: "src",
          render: (text, record) => {
            return <Image width={144} src={record.url}/>;
          },
        },
        {
          title: "图片名称",
          ellipsis: true,
          width: 150,
          dataIndex: "name",
        },
        {
          title: "显示状态",
          width: 80,
          dataIndex: "state",
          render: (text, record) => {
            return bannerSateOptions[record.state];
          },
        },
        {
          title: "跳转类型",
          width: 100,
          dataIndex: "bannerType",
          render: (text, record, _, action) => {
            return bannerTypeOptions[record.type];
          },
        },
        {
          title: "跳转地址",
          width: 180,
          ellipsis: true,
          dataIndex: "targetUrl",
        },
        {
          title: "平台",
          width: 100,
          ellipsis: true,
          dataIndex: "platform",
        },
        {
          title: "创建人",
          width: 80,
          dataIndex: "createdBy",
        },
        {
          title: "操作人",
          width: 80,
          dataIndex: "updatedBy",
        },
        {
          title: "创建时间",
          width: 180,
          dataIndex: "createdTime",
        },
        {
          title: "操作时间",
          width: 180,
          dataIndex: "updatedTime",
        },
        {
          title: "备注",
          width: 60,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 200,
          valueType: "option",
          fixed: 'right',
          align: "center",
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() => this.handleEditor(record)}>
                修改
              </Button>
              <Popconfirm
                title="确定杀出该项吗？"
                okText="删除"
                cancelText="取消"
                onConfirm={() => this.handleDelete(record)}
              >
                <Button type="primary" danger>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ],
    };
  }

  formRef = React.createRef()
  tableRef = React.createRef()

  componentDidMount() {
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    }, () => {
      this.formRef.current.resetFields();
    });

  }

  handleEditor = (data) => {
    React.fetch({
      url: GET_AD_DETAIL,
      method: "post",
      data: {
        id: data.id
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          eType: 2,
          eId: data.id,
          modalVisible: true
        }, () => {
          this.formRef.current.setFieldsValue({
            bannerName: res.data.name,
            bannerUrl: [{url: res.data.url, uid: '-1', name: "image", status: 'done'}],
            bannerType: res.data.type.toString(),
            platform: res.data.platform,
            targetUrl: res.data.targetUrl,
            state: res.data.state.toString(),
            remark: res.data.remark,
          })
        })
      }
    })
  }
  handleDelete = (data) => {
    React.fetch({
      url: DELETE_AD,
      method: "post",
      data: {
        id: data.id
      },
    }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功！");
        this.tableRef.current.reload();
      }
    });
  }

  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  addBanner(data) {
    if (!(data.bannerUrl[0]?.response?.data?.fileName)) {
      message.error("图片上传错误");
      return;
    }

    React.fetch({
      url: ADD_AD,
      method: "post",
      data: {
        name: data.bannerName,
        url: data.bannerUrl[0]?.response?.data?.fileName,
        type: data.bannerType,
        platform: data.platform,
        targetUrl: data.targetUrl,
        state: data.state,
        remark: data.remark,
      },
    }).then((res) => {
      if (res.code === 200) {
        message.success("添加成功！");
        this.modalHide();
        this.formRef.current.resetFields();
        this.tableRef.current.reload();
      }
    });
  }

  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }

  editeBanner(data) {
    if (!(data.bannerUrl[0] && data.bannerUrl[0].xhr && JSON.parse(data.bannerUrl[0].xhr.response).data.fileName)) {
      data.bannerUrl = null;
    } else {
      data.bannerUrl = JSON.parse(data.bannerUrl[0].xhr.response).data.fileName;
    }
    React.fetch({
      url: EDITE_AD,
      method: "post",
      data: {
        id: this.state.eId,
        name: data.bannerName,
        url: data.bannerUrl,
        type: data.bannerType,
        platform: data.platform,
        targetUrl: data.targetUrl,
        state: data.state,
        remark: data.remark,
      },
    }).then((res) => {
      if (res.code === 200) {
        message.success("修改成功！");
        this.modalHide();
        this.formRef.current.resetFields();
        this.tableRef.current.reload();
      }
    });
  }

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    let that = this;
    return (
      <div className="banner-list-page">
        <ProTable
          actionRef={this.tableRef}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_AD_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={false}
          pagination={false}
          params={{
            page: this.state.page
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<PlusOutlined/>}
                type="primary"
                onClick={this.handleAdd}
              >新增图片位</Button>,
            ];
          }}
        />
        <div className="page">
          <Button type="link" className="prev" disabled={this.state.page === 1} onClick={this.handlePrev}>上一页</Button>
          <Button type="link" className="next" onClick={this.handleNext}>下一页</Button>
        </div>
        <Modal
          title={this.state.eType === 1 ? "添加" : "编辑"}
          width={800}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout="horizontal"
            onFinish={async (values) => {
              this.state.eType === 1 ? that.addBanner(values) : that.editeBanner(values);
            }}
            submitter={{
              render: (props, doms) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    {[...doms]}
                  </div>
                );
              },
            }}
          >
            <ProFormText
              name="bannerName"
              label="图片名称"
              placeholder="图片名称"
              rules={[{required: true, message: "请填写图片名称"}]}
            />
            <ProFormSelect
              name="platform"
              label="图片展示平台"
              valueEnum={{
                ALIPAY_MINI: "ALIPAY_MINI",
                WEB_PC: "WEB_PC",
                WEB_H5: "WEB_H5",
                WECHAT_MINI: 'WECHAT_MINI'
              }}
              placeholder="请选择"
              rules={[{required: true, message: "请选择图片展示平台"}]}
            />
            <ProFormUploadDragger
              require
              label="图片"
              max={1}
              action={React.baseUrl + "/web/boss/upload/img"}
              fieldProps={{
                name: "file",
                listType: "picture",
                headers: {
                  "x-zp-ats-auth-token": this.props.token,
                },
              }}
              rules={[{ required: true, message: "请上传图片" }]}
              name="bannerUrl"
              description="支持jpg,png格式，小于1M的图片,建议图片尺寸320*128"
            />

            <ProFormText
              name="remark"
              label="备注"
              placeholder="备注"
            />
            <ProFormSelect
              name="state"
              label="显示状态"
              valueEnum={bannerSateOptions}
              placeholder="请选择"
              rules={[{required: true, message: "请选择图片显示状态"}]}
            />
            <ProFormSelect
              name="bannerType"
              label="跳转类型"
              valueEnum={bannerTypeOptions}
              placeholder="请选择"
              rules={[{required: true, message: "请选择图片跳转类型"}]}
            />

            <Form.Item noStyle shouldUpdate>
              {(form) => {
                return form.getFieldValue("bannerType") !== '0' ? (
                  <ProFormText
                    rules={[{required: true, message: "请输入轮播跳转地址"}, {
                      validator: async (_, targetUrl) => {
                        let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/;
                        if (targetUrl !== '' && (!reg.test(targetUrl)) && form.getFieldValue("bannerType") === '2') {
                          return Promise.reject(new Error('请输入合法的外部链接地址'));
                        }
                      }
                    }]}
                    name="targetUrl"
                    label="跳转地址"
                    placeholder="跳转地址"
                  />
                ) : null;
              }}
            </Form.Item>
          </ProForm>
        </Modal>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  token: state.login.token
})

export default connect(mapStateToProps, null)(withRouter(HomeImageConfig))