import React, {useState, useEffect, useRef} from "react";
import {Cascader} from "antd";
import {AREA_OPTION, CITY_OPTION, PROVINCE_OPTiON} from "../../service";

const optionsList = [];
function AddressCascader(props) {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState(optionsList);
  let refResult = useRef([])

  useEffect(async () => {
    const result = await getProvinceOption();
    setOptions([...result])
    refResult.current = result
  },[])

  useEffect(() => {
    (async () => {
      const dProvince = [1, 2, 3, 4]; //直辖市id
      const values = props.value?.map(item => item.value ? item.value : item);
      setValue(props.value?.map(item => item.value ? item.value : item));
      let province = values && refResult.current?.filter(item => item.value === values[0]); //拿到省的id

      if (values?.length > 0 && refResult.current.length) {
        if (dProvince.includes(values[0])) {
          province && province[0] && (province[0].children = await getAreaOption(values[0]));
          setOptions(refResult.current)
        } else {
          let cityList = await getCityOptions(values[0]);
          let areaList = await getAreaOption(values[1]);
          let city = cityList?.filter(item => item.value === values[1]);

          province && province[0] && (province[0].children = cityList);
          city && city[0] && (city[0].children = areaList);
          setOptions(refResult.current)
        }
      }
    })();
  }, [props.value, JSON.stringify(options)])


  function onChange(values, selectedOptions) {
    setValue(values);
    props?.onChange?.(selectedOptions);
  }

  async function loadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let result = [];
    const dProvince = [1, 2, 3, 4]; //直辖市id
    if (targetOption.level === 1 && dProvince.indexOf(targetOption.value) < 0) {
      result = await getCityOptions(targetOption.value);
    } else {
      result = await getAreaOption(targetOption.value);
    }

    targetOption.loading = false;
    targetOption.children = result;

    setOptions([...options]);
  }

  function getProvinceOption() {
    //获取省份
    return new Promise((resolve, reject) => {
      React.fetch({
        url: PROVINCE_OPTiON,
      }).then(res => {
        const result = res.data && res.data.map(item => {
          return {
            isLeaf: false,
            label: item.name + item.suffix,
            value: item.id,
            code: item.code,
            level: 1
          }
        })
        resolve(result)
      })
    })
  }

  function getCityOptions(province) {
    //获取城市
    return new Promise((resolve, reject) => {
      React.fetch({
        url: CITY_OPTION,
        method: "post",
        data: {
          provinceId: province
        }
      }).then(res => {
        resolve(res.data && res.data.slice(1).map(item => {
          return {
            label: item.name + item.suffix,
            value: item.id,
            code: item.code,
            isLeaf: false,
            level: 2
          }
        }))
      }).catch(err => {
        reject(err)
      })
    })
  }

  function getAreaOption(city) {
    //获取区县
    return new Promise((resolve, reject) => {
      React.fetch({
        url: AREA_OPTION,
        method: "post",
        data: {
          cityId: city
        }
      }).then(res => {
        resolve(res.data && res.data.map(item => {
          return {
            label: item.name + item.suffix,
            value: item.id,
            code: item.code,
            isLeaf: true,
            level: 3
          }
        }))
      }).catch(err => {
        reject(err)
      })
    })
  }

  return <Cascader
    value={value}
    options={options}
    onChange={onChange}
    loadData={loadData}
    placeholder="选择地区"/>
}

export default AddressCascader