import React,{Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Popconfirm, Modal} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText, ProFormRadio} from '@ant-design/pro-form';
import "./index.less";

class AdPositionList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
        },
        {
          title: '位置',
          dataIndex: 'position',
          ellipsis: true,
        },
        {
          title: '宽度',
          dataIndex: 'width',
        },
        {
          title: '高度',
          dataIndex: 'height',
        },
        {
          title: '适用范围',
          dataIndex: 'fit',
        },
        {
          title: '创建时间',
          dataIndex: 'create_at',
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
        },
        {
          title: '操作',
          valueType: 'option',
          width: 200,
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() =>this.handleEditor(record)}>修改</Button>
              <Popconfirm title="确定杀出该项吗？" okText="删除" cancelText="取消" onConfirm={() => this.handleDelete(record)}>
                <Button type="primary" danger>删除</Button>
              </Popconfirm>
            </Space>
          ),
        },
      ]
    }
  }
  formRef = React.createRef()

  componentDidMount() {
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    })
  }

  handleEditor = (data) => {
    this.setState({
      eType: 2,
      eId: data.id,
      modalVisible: true
    })
  }
  handleDelete = (data) => {
    console.log(data);
  }

  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div className="position-list-page">
        <ProTable
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            console.log(params)
            return {
              data: [
                {id: 1, account: "hello",name: "小张", mobile: "17621478502"}],
              success: true,
              total: 100
            }
          }}
          rowKey="id"
          search={false}
          pagination={false}
          toolBarRender={(action) => {
            return [
              <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>添加广告位置</Button>
            ]
          }}
        />

        <Modal
          title="添加/编辑"
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout='horizontal'
            onFinish={async (values) => {
              console.log(values);
            }}
            submitter={{
              render: (props, doms) => {
                return <div style={{"display": "flex", "justifyContent": "center", "gap": "8px"}}>
                  {[...doms]}
                </div>;
              },
            }}
          >
            <ProFormText name="position" label="广告位置" placeholder="" help="以网站_或App_开头命名" rules={[{required: true, message: '请填写广告位置'}]}/>
            <ProFormText name="width" label="图片宽度" placeholder="" help="以像素px为单位"/>
            <ProFormText name="height" label="图片宽度" placeholder="" help="以像素px为单位"/>
            <ProFormRadio.Group
              name="fit"
              label="适用范围"
              options={[
                {
                  label: '支付包小程序',
                  value: 1,
                },
                {
                  label: '微信小程序',
                  value: 2,
                },
              ]}
            />
          </ProForm>
        </Modal>
      </div>
    )
  }
}

export default AdPositionList