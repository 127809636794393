import {createStore, combineReducers, applyMiddleware} from 'redux';
import createSagaMiddleware from "redux-saga"
import {loginReducer} from "./reducer/loginReducer";
import {userReducer} from "./reducer/userReducer";
import {globalReducer} from "./reducer/globalReducer";
import rootSaga from "../saga";

const rootReducer = combineReducers({
    'login': loginReducer,
    'user': userReducer,
    'global': globalReducer
})
const {composeWithDevTools} = require('redux-devtools-extension');
const sagaMiddleware = createSagaMiddleware();

const enhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(sagaMiddleware)) : applyMiddleware(sagaMiddleware)
const store = createStore(rootReducer, enhancers);
sagaMiddleware.run(rootSaga);
export default store