import React, {Component} from "react";
import {Tabs} from 'antd';
import {withRouter} from "react-router-dom";

class BIJobContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {

  }
  handleTabChange=(activeKeys)=>{
    this.props.history.push(activeKeys+'')
  }

  render() {
    return (<div className="BI-job-container">
        <Tabs type="card" activeKey={this.props.location.pathname} onChange={this.handleTabChange}>
              <Tabs.TabPane tab="注册用户数" key="/BI-job/authorizationAccount" />
              <Tabs.TabPane tab="简历完成数" key="/BI-job/resumeComplete" />
              <Tabs.TabPane tab="简历投递数量" key="/BI-job/resumeDeliveryMount" />
              <Tabs.TabPane tab="投递城市" key="/BI-job/deliveryCity" />
              <Tabs.TabPane tab="投递职位分类" key="/BI-job/deliveryJobCategory" />
              <Tabs.TabPane tab="职位投递数排序" key="/BI-job/rankingJob" />
        </Tabs>
        {this.props.children}
    </div>);
  }
}


export default withRouter(BIJobContainer)