import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Space, Tabs, Radio, message} from 'antd';
import ProTable from '@ant-design/pro-table';
import {GET_JOB_CATRGORY_LIST, ADD_JOB_CATRGORY, UPDATE_JOB_CATRGORY, REFRESH_JOB_CATEGORY} from "../../../service";
import "./index.less";
import ProForm, {ProFormText} from "@ant-design/pro-form";

const {TabPane} = Tabs;

class JobCategoryManage extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      jobType: "1",
      sort: "1",
      categoryId: '',
      categoryName: '',
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 60,
          hideInSearch: true
        },
        {
          title: '行业名称',
          dataIndex: 'postName',
          hideInSearch: false,
        },
        {
          title: "别名",
          dataIndex: 'aliasName',
          hideInSearch: true,
        },
        {
          title: '下级分类总数',
          dataIndex: 'parentPostCodeNum',
          hideInSearch: true,
          align: 'center',
        },

        {
          title: '创建人',
          dataIndex: 'createdBy',
          hideInSearch: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '更新时间',
          dataIndex: 'updatedTime',
          hideInSearch: true,
        },

        {
          title: '创建时间范围',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          fieldProps: {
            placeholder: ["开始时间", "结束时间"]
          }
        },
        {
          title: '',
          dataIndex: 'created_at',
          valueType: 'dateRange',
          hideInTable: true,
          renderFormItem: (_, {type, defaultRender, ...rest}, form) => {
            return (
              <Radio.Group value={this.state.sort} onChange={e => this.handleSizeChange(e)}>
                <Radio.Button value="0">时间倒叙</Radio.Button>
                <Radio.Button value="1">时间正序</Radio.Button>
              </Radio.Group>
            );
          }
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="dashed" onClick={() => {
                this.handleChange(record)
              }}>修改名称</Button>
              <Button type="primary" onClick={() => this.goChildCategory(record)}>查看子类</Button>
            </Space>
          ),
        },
      ]
    };
  }

  goChildCategory = (data) => {
    this.props.history.push("/childJobCategoryManage?postCode=" + data.postCode + "&index=2&jobType=" + this.state.jobType)
  }
  handleSizeChange = (e) => {
    this.setState({
      sort: e.target.value
    }, () => {
      this.ref.current.reload()
    })
  }
  handleTabChange = (activeKeys) => {
    this.setState({
      jobType: activeKeys,
      page: 1
    }, () => {
      this.ref.current.reset();
    })
  }
  ref = React.createRef();
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }
  handleAdd = () => {
    this.setState({modalVisible: true, categoryId: "", categoryName: ""},() => {
      this.formRef.current.setFieldsValue({
        name: "",
        aliasName: ""
      })
    });
  }
  handleChange = (data) => {
    this.setState({modalVisible: true, categoryId: data.id, categoryName: data.postName},() => {
      this.formRef.current.setFieldsValue({
        name: data.postName,
        aliasName: data.aliasName
      })
    });
  }
  synchronization = () => {
    React.fetch({
      url: REFRESH_JOB_CATEGORY,
      method: "get",
      data: {}
    }).then(res => {
      if (res) {
        message.success("同步成功！");
      }
    })
  }
  editeCategory = values => {
    React.fetch({
        url: UPDATE_JOB_CATRGORY,
        method: "post",
        data: {
          id: this.state.categoryId,
          jobType: this.state.jobType,
          postName: values.name,
          aliasName: values.aliasName
        }
      }
    ).then(res => {
      if (res.code === 200) {
        message.success("账号编辑成功！");
        this.ref.current.reload();
        this.setState({modalVisible: false})
      }
    })
  }
  addCategory = values => {
    React.fetch({
        url: ADD_JOB_CATRGORY,
        method: "post",
        data: {
          jobType: this.state.jobType,
          postName: values.name,
          aliasName: values.aliasName
        }
      }
    ).then(res => {
      if (res.code === 200) {
        message.success("岗位种类添加成功！");
        this.ref.current.reload();
        this.setState({modalVisible: false})
      }
    })
  }

  render() {
    return (
      <div className="job-category-manage">
        <Tabs type="card" onChange={this.handleTabChange}>
          <TabPane tab="全职" key="1"/>
          {/*<TabPane tab="线上兼职" key="2"/>*/}
          {/*<TabPane tab="线下兼职" key="3"/>*/}
        </Tabs>
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_JOB_CATRGORY_LIST,
              method: "post",
              data: {
                page: params.page ? params.page : 1,
                size: 20,
                jobType: this.state.jobType,
                sort: this.state.sort,
                postName: params.postName,
                beginDate: params.created_at && params.created_at[0],
                endDate: params.created_at && params.created_at[1],
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          search={{
            labelWidth: "auto",
          }}
          onSubmit={() => {
            this.setState({
              page: 1
            })
          }}
          pagination={false}
          params={{
            page: this.state.page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                onClick={() => {
                  this.synchronization()
                }}
              >
                同步
              </Button>,
              <Button
                key="button"
                icon={<PlusOutlined/>}
                type="primary"
                onClick={this.handleAdd}
              >
                添加行业
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          title={this.state.categoryId ? "编辑行业" : "添加行业"}
          visible={this.state.modalVisible}
          onCancel={() => this.setState({modalVisible: false})}
          footer={null}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            layout="horizontal"
            onFinish={values => {
              this.state.categoryId ? this.editeCategory(values) : this.addCategory(values)
            }}
            submitter={{
              render: (props, doms) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    {[...doms]}
                  </div>
                );
              },
            }}
          >
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入名称!",
                },
              ]}
              required
              name="name"
              label="名称"
              placeholder="请输入名称"
            />
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入别名!",
                },
              ]}
              required
              name="aliasName"
              label="别名"
              placeholder="请输入别名"
            />
          </ProForm>

          {/*<Input value={this.state.categoryName} onChange={(e)=>this.setState({categoryName:e.target.value})}/>*/}
        </Modal>
      </div>
    );
  }
}

export default JobCategoryManage;