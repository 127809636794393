import React, {Component} from "react";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Space, Modal,message} from 'antd';
import ProTable from '@ant-design/pro-table';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {ADD_AUTH,GET_AUTH_LIST,UPDATE_AUTH} from "../../../service";
import "./index.less"

class AuthList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      eType: 1,   //1添加2编辑
      eId: "",
      columns: [
        {
          dataIndex: 'id',
          title: "ID",
          width: 48,
          hideInSearch: true
        },
        {
          title: '权限地址',
          dataIndex: 'auth',
          hideInSearch: true,
        },
        {
          title: '权限名称',
          dataIndex: 'authName',
          hideInSearch: true,
        },
        {
          title: '创建者',
          dataIndex: 'createdBy',
          hideInSearch: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          hideInSearch: true,
        },
        {
          title: '更改者',
          dataIndex: 'updatedBy',
          hideInSearch: true,
        },
        
        {
          title: '更新时间',
          hideInSearch: true,
          dataIndex: 'updatedTime',
        },
        {
          title: '操作',
          valueType: 'option',
          align: 'center',
          render: (text, record, _, action) => (
            <Space>
              <Button type="primary" onClick={() =>this.handleEditor(record)}>修改</Button>
              {/* <Popconfirm title="确定杀出该项吗？" okText="删除" cancelText="取消" onConfirm={() => this.handleDelete(record)}>
                <Button type="primary" danger>删除</Button>
              </Popconfirm> */}
            </Space>
          ),
        },
      ]
    }
  }
  formRef = React.createRef()
  ref = React.createRef()
  componentDidMount() {

  }
  handlePrev = () => {
    let page = this.state.page;
    this.setState({
      page: page < 0 ? 0 : --page
    })
  }
  handleNext = () => {
    let page = this.state.page;
    this.setState({
      page: ++page
    })
  }

  handleAdd = () => {
    this.setState({
      eType: 1,
      modalVisible: true
    },() => {
      this.formRef.current.setFieldsValue({
        auth: "",
        authName: "",
      })
    })
  }

  handleEditor = (data) => {
    this.setState({
      eType: 2,
      eId: data.id,
      modalVisible: true
    }, () => {
      this.formRef.current.setFieldsValue({
        "auth": data.auth,
        "authName": data.authName
      })
    })
  }

  submit = (values) => {
    React.fetch({
        url: ADD_AUTH,
        method: "post",
        data: {
          "auth": values.auth,
          "authName": values.authName
        }}
    ).then(res => {
      if(res) {
        message.success("权限添加成功！");
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }
  edite = (values) => {
    React.fetch({
        url: UPDATE_AUTH,
        method: "post",
        data: {
          "id":this.state.eId,
          "auth": values.auth,
          "authName": values.authName
        }}
    ).then(res => {
      if(res.code) {
        message.success("权限编辑成功！");
        this.ref.current.reload();
        this.modalHide();
      }
    })
  }


  modalHide = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div className="user-auth-page">
        <ProTable
          actionRef={this.ref}
          columns={this.state.columns}
          request={async (params = {}, sort, filter) => {
            const res = await React.fetch({
              url: GET_AUTH_LIST,
              method: "post",
              data: {
                page: params.page,
                size: 20,
                accountName: params.accountName,
              },
            });
            return {
              data: res.data,
              success: true,
            };
          }}
          rowKey="id"
          pagination={false}
          search={false}
          onSubmit={()=>{
            this.setState({
              page: 1
            })
          }}
          params={{
            page: this.state.page,
          }}
          toolBarRender={(action) => {
            return [
              <Button
                key="button"
                icon={<PlusOutlined />}
                type="primary"
                onClick={this.handleAdd}
              >
                添加权限
              </Button>,
            ];
          }}
        />
        <div className="page">
          <Button
            type="link"
            className="prev"
            disabled={this.state.page === 1}
            onClick={this.handlePrev}
          >
            上一页
          </Button>
          <Button type="link" className="next" onClick={this.handleNext}>
            下一页
          </Button>
        </div>
        <Modal
          title={this.state.eType === 1 ? "添加" : "编辑"}
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalHide}
        >
          <ProForm
            formRef={this.formRef}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            onFinish={(values) => {
              this.state.eType === 1 ? this.submit(values) : this.edite(values);
            }}
            submitter={{
              render: (props, doms) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    {[...doms]}
                  </div>
                );
              },
            }}
          >
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入权限地址!",
                },
              ]}
              required
              name="auth"
              label="权限地址"
              placeholder="请输入权限地址"
            />
            <ProFormText
            rules={[
              {
                required: true,
                message: "请输入权限地址!",
              },
            ]}
              required
              name="authName"
              label="请输入权限名称"
              placeholder="请输入权限名称"
            />
          </ProForm>
        </Modal>
      </div>
    );
  }
}

export default AuthList;