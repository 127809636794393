import React, {Component} from "react";
import {connect} from 'react-redux'
import {Layout, Breadcrumb, Avatar, Dropdown, Menu, Modal} from 'antd';
import {withRouter, Link} from "react-router-dom";
import SideMenu from "./sideMenu";
import IHeader from "./header";
import {breadcrumb} from "../../config";
import {DownOutlined} from '@ant-design/icons';
import {GET_MENU_LIST} from "../../service";
import "./home.less";

const {Content} = Layout;
const {confirm} = Modal;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumb: [],
      // menuData: [
      //   {
      //     menuName: "系统管理",
      //     id: '1',
      //     subMenu: [
      //       {
      //         id: "1-1",
      //         menuName: "系统信息",
      //         menuRouter: "/systemInfo",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-2",
      //         menuName: "用户管理",
      //         menuRouter: "/userAuth",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-5",
      //         menuName: "角色管理",
      //         menuRouter: "/roleAuth",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-6",
      //         menuName: "权限管理",
      //         menuRouter: "/authList",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-7",
      //         menuName: "菜单管理",
      //         menuRouter: "/menuList",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-3",
      //         menuName: "标签库",
      //         menuRouter: "/tagsStore",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "1-4",
      //         menuName: "操作日志",
      //         menuRouter: "/logs",
      //         icon: "PieChartOutlined"
      //       }
      //     ]
      //   },
      //   {
      //     menuName: "企业端管理",
      //     id: '2',
      //     subMenu: [
      //       {
      //         id: "2-1",
      //         menuName: "企业管理",
      //         menuRouter: "/companyManage",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "2-2",
      //         menuName: "职位管理",
      //         menuRouter: "/jobManage",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "2-3",
      //         menuName: "岗位分类管理",
      //         menuRouter: "/jobCategoryManage",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },
      //   {
      //     menuName: "求职端管理",
      //     id: '3',
      //     subMenu: [
      //       {
      //         id: "3-1",
      //         menuName: "求职者管理",
      //         menuRouter: "/employeeManage",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "3-2",
      //         menuName: "简历管理",
      //         menuRouter: "/resumeManage",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "3-3",
      //         menuName: "投递记录",
      //         menuRouter: "/sendRecord",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },
      //   {
      //     menuName: "订单管理",
      //     id: '4',
      //     subMenu: [
      //       {
      //         id: "4-1",
      //         menuName: "红包明细",
      //         menuRouter: "/orderDetails",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },
      //   {
      //     menuName: "消息管理",
      //     id: '5',
      //     subMenu: [
      //       {
      //         id: "5-1",
      //         menuName: "系统消息",
      //         menuRouter: "/messageList",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },

      //   {
      //     menuName: "广告管理",
      //     id: '6',
      //     subMenu: [
      //       {
      //         id: "6-1",
      //         menuName: "轮播列表",
      //         menuRouter: "/bannerManage",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "6-2",
      //         menuName: "广告列表",
      //         menuRouter: "/adList",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },

      //   {
      //     menuName: "职位采集管理",
      //     id: '7',
      //     subMenu: [
      //       {
      //         id: "7-1",
      //         menuName: "采集职位列表",
      //         menuRouter: "/collectJobList",
      //         icon: "PieChartOutlined"
      //       },
      //       {
      //         id: "7-2",
      //         menuName: "采集职位审核",
      //         menuRouter: "/collectJobVerify",
      //         icon: "PieChartOutlined"
      //       },
      //     ]
      //   },

      // ]
      menuData:[]
    }
  }

  infoMenuClick = ({item, key}) => {
    const that = this;
    if (key === '0') {

    } else if (key === '2') {
      // 退出
      confirm({
        title: '提示',
        icon: null,
        content: '确定退出登录吗？',
        onOk() {
          that.props.history.push("/login")
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

  }

  componentDidMount() {
    this.setBreadCrumb();
    this.getMenuList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setBreadCrumb();
    }
  }

  setBreadCrumb = () => {
    this.setState({
      breadcrumb: breadcrumb[this.props.location.pathname] || []
    })
  }
  

  getMenuList = () => {
    React.fetch({
      url: GET_MENU_LIST,
      method: "get",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          menuData: this.translateDataToTree(res.data)
        })
        
        //const leftSideMenu = res.data.filter(item => item.menuType === 1);
        //const menuData = this.translateDataToTree(leftSideMenu);
      }
    });
  
  }

 // 分出子节点  父节点
translateDataToTree = (data) => {
  let parents = data.filter(value => value.parentId === 0)
  let children = data.filter(value => value.parentId !== 0)
  let translator = (parents, children) => {
    parents.forEach((parent) => {
        children.forEach((current, index) => {
            if (current.parentId === parent.id) {
              typeof parent.subMenu !== 'undefined' ? parent.subMenu.push(current) : parent.subMenu = [current]
            }
          }
        )
      }
    )
  }
  
  translator(parents, children)
  return parents
}

  render() {
    const {menuData, breadcrumb} = this.state;
    return (
      <Layout style={{height: '100vh'}}>
        {/*侧边栏*/}
        <SideMenu menuData={menuData}/>
        <Layout className="site-layout">
          {/*Header*/}
          <IHeader>
            <div className="main-menu"/>
            <Dropdown overlay={<Menu style={{width: "100px", textAlign: 'center'}} onClick={this.infoMenuClick}>
              <Menu.Item key="0">个人信息</Menu.Item>
              <Menu.Divider/>
              <Menu.Item key="2">退出</Menu.Item>
            </Menu>} trigger={['click']}>
              <div className='userInfo'>
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
                <span style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#1890ff'
                }}>{this.props.userName || "user"}<DownOutlined style={{color: '#ffffff'}}/></span>
              </div>
            </Dropdown>
          </IHeader>
          {/*content*/}
          <Content style={{
            padding: '0 16px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            background: "#ffffff"
          }}>
            <Breadcrumb style={{margin: '16px 0'}}>
              {
                breadcrumb.map(item => {
                  
                  if (item.path) {
                    return <Breadcrumb.Item key={item.id}><Link to={item.path}>{item.name}</Link></Breadcrumb.Item>
                  } else {
                    return <Breadcrumb.Item key={item.id}>{item.name}</Breadcrumb.Item>
                  }
                })
              }

            </Breadcrumb>
            <div className="site-layout-background" style={{flex: 1,overflow: 'auto'}}>
              {
                this.props.children
              }
            </div>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.login.token
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container))