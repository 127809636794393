import React, {Component} from "react";
import {GET_DELIVERY_CITY_DATA} from "../../../service";
import moment from 'moment';
import {Checkbox, DatePicker, Space} from 'antd';
// 引入 ECharts 主模块
import * as echarts from 'echarts/lib/echarts'
// 引入饼状图
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import "./index.less";
import ProTable from "@ant-design/pro-table";
const CheckboxGroup = Checkbox.Group;

class DeliveryCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      data: [],
      jobType: [],
      echartOptions: {
        title: {
          text: '投递城市分布',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              }
            }
          }
        ]
      },
      echartContainer: '',
      columns: [
        {
          title: "投递城市",
          dataIndex: "cityName"
        },
        {
          title: "支付宝投递数",
          dataIndex: "alipayCount"
        },
        {
          title: "微信投递数",
          dataIndex: "weixinCount"
        }
      ],
      tableData: []
    };
  }

  componentDidMount() {
    this.initPage();
  }

  initPage = async () =>{
    let result = await this.getData();

    this.initTable([...new Set(result.data.map(item => JSON.stringify(item)))].map(item => JSON.parse(item)));
    this.initEcharts([...new Set(result.data.map(item => JSON.stringify(item)))].map(item => JSON.parse(item)));
  }

  getData = () => {
     return  React.fetch({
      url: GET_DELIVERY_CITY_DATA,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
        jobTypeList: this.state.jobType
      },
    });
  }

  initTable = (data) => {
    this.setState({
      tableData: data.map(item => ({
        id: item.cityId,
        cityName: item.cityName,
        alipayCount: item.alipayCount,
        weixinCount: item.weixinCount
      }))
    })
  }

  initEcharts = (data) => {
    let echartOptions = JSON.parse(JSON.stringify(this.state.echartOptions));
    echartOptions.series[0].data = data.map(item => ({
      name: item.cityName || '',
      value: item.applyCount
    }))
    this.setState({
      echartOptions: echartOptions
    }, () => {
      if (this.state.echartContainer) {
        this.state.echartContainer.dispose()
      }
      let myChart = echarts.init(document.getElementById('echart-container'));
      myChart.setOption(this.state.echartOptions)
      this.setState({echartContainer: myChart})
    })
  }


  setDate = async (val) => {
    this.setState({
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      this.initPage()
    })
  }

  render() {
    const {date,jobType,columns, tableData} = this.state;
    return (
      <div className="delivery-city">
        <div className="search">
          <Space>
            <DatePicker.RangePicker allowClear
                                    onChange={(val) => this.setDate(val)}
                                    value={date}/>

            <CheckboxGroup options={[
              {label: "全职", value: 1},
              // {label: "线上兼职", value: 2},
              // {label: "线下兼职", value: 3}
            ]} value={jobType} onChange={(val) => {
              this.setState({
                jobType: val
              }, () => {
                this.initPage();
              })
            }}/>
          </Space>
        </div>

        <div className="content">
          <div id="echart-container" />
          <div className="table">
            <ProTable
              columns={columns}
              dataSource={tableData}
              bordered
              rowKey="id"
              pagination={false}
              search={false}
              toolBarRender={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryCity;
