import React, {Component} from "react";
import {GET_RESUME_COMPLETE_MOUNT} from "../../../service";
import moment from 'moment';
import {DatePicker, Tooltip, Table} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import {FunnelChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';

import "./index.less";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  FunnelChart,
  CanvasRenderer
]);

class ResumeComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      end: "",
      start: "",
      state: [],
      tableData: [],
      column: [
        {
          title: '简历总数',
          align: "center",
          dataIndex: 'total',
          key: 'total',
        },
        {
          title: '无效简历数',
          align: "center",
          dataIndex: 'invalid',
          key: 'invalid',
        },
        {
          title:  () => <p>简历数<br />（可投递）</p>,
          align: "center",
          dataIndex: 'valid',
          key: 'valid',
        },
        {
          title: () => <p>简历数<br />（基本信息）</p>,
          align: "center",
          dataIndex: 'basic',
          key: 'basic',
        },
        {
          title: () => <p>简历数<br />（基本信息+教育经历）</p>,
          align: "center",
          dataIndex: 'education',
          key: 'education',
        },
        {
          title: () => <p>简历数<br />（基本信息+教育经历+个人简介）</p>,
          align: "center",
          dataIndex: 'describe',
          key: 'describe',
        },
        {
          title: '附件简历数',
          align: "center",
          dataIndex: 'attache',
          key: 'attache',
        },
      ],
      echartOptions: {
        title: {
          text: '',
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}：{c}'
        },
        toolbox: {
          show: false,
        },
        legend: {
          data: ['简历总数', '基本信息', '基本信息+教育经历', '基本信息+教育经历+个人简历']
        },
        series: [
          {
            name: 'resume',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 0,
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'left',
              formatter: params => `${params.name!=='简历总数' ? '完成':''}${params.name}：${(params.value / params.data.total * 100).toFixed(2)}% (${(params.value / params.data.prev * 100).toFixed(2)}%)`,
              lineHeight: 20,
              align: 'left'
            },
            labelLine: {
              length: 20,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 14
              }
            },
            data: []
          }
        ]
      },
      echartContainer: ''
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let res = await React.fetch({
      url: GET_RESUME_COMPLETE_MOUNT,
      method: "post",
      data: {
        start: this.state.start,
        end: this.state.end,
      },
    });

    if (res.code === 200) {
      const {data} = res;
      this.setState({
          tableData: [{
            id: 1,
            total: data.resumeCount,
            invalid: data.invalidCount,
            valid: data.validCount,
            basic: data.baseCount,
            education: data.baseEducationCount,
            describe: data.baseEducationPersonCount,
            attache: data.resumeAttachementCount
          }]
        }, () => {
          this.initChart(data);
        }
      )
    }
  }
  setDate = async (val) => {
    this.setState({
      date: val,
      start: val ? moment(val[0], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      end: val ? moment(val[1], 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
    }, () => {
      this.getData()
    })
  }
  initChart = (data) => {
    let echartOptions = this.state.echartOptions
    echartOptions.series[0].max = data.resumeCount * 2;  //页面效果  *2
    echartOptions.series[0].data = [
      {
        value: data.baseEducationPersonCount,
        name: '基本信息+教育经历+个人简历',
        total: data.resumeCount,
        prev: data.baseEducationCount
      },
      {value: data.baseEducationCount, name: '基本信息+教育经历', total: data.resumeCount, prev: data.baseCount},
      {value: data.baseCount, name: '基本信息', total: data.resumeCount, prev: data.resumeCount},
      {value: data.resumeCount, name: '简历总数', total: data.resumeCount, prev: data.resumeCount}
    ]

    this.setState({
      echartOptions: echartOptions
    }, () => {
      if (this.echartContainer) {
        this.echartContainer.dispose()
      }
      let myChart = echarts.init(document.getElementById('echart'));
      myChart.setOption(this.state.echartOptions);
      this.echartContainer = myChart;
    })
  }

  render() {
    const {date, tableData, column} = this.state;
    return (
      <div className="resume-complete-page">
        <DatePicker.RangePicker allowClear
                                onChange={(val) => this.setDate(val)}
                                value={date}
        />
        <div className="content">
          <h1>简历完成数据表</h1>
          <Table rowKey="id" columns={column} dataSource={tableData} pagination={false} bordered/>
          <Tooltip placement="topLeft" title="括号内百分比是本层和上一层数据的转化率；括号外是本层和总数（第一层）的转化率">
            <QuestionCircleOutlined className='tips' />
          </Tooltip>
          <div id="echart"/>
        </div>
      </div>
    );
  }
}

export default ResumeComplete;
